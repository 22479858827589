import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: "app-reset-password-dialog",
  templateUrl: "./reset-password-dialog.component.html",
  styleUrls: ["./reset-password-dialog.component.scss"],
})
export class ResetPasswordDialogComponent implements OnInit {
  passwordResetForm = this.fb.group({
    email: ["", [Validators.email]],
  });
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      email: string;
    },
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private snackbar: MatSnackBar,
    private dialogRef: MatDialogRef<ResetPasswordDialogComponent>
  ) {
    this.passwordResetForm.get("email")?.setValue(this.data.email);
  }

  get email() {
    return this.passwordResetForm.get("email")?.value;
  }

  get emailAddress() {
    return this.passwordResetForm.get("email");
  }

  ngOnInit(): void {}

  async onSubmit() {
    const error = await this.authService.sendPasswordResetEmail(this.email);
    if (error) {
      switch (error.code) {
        case "e404":
          this.snackbar.open("No account exists with this email.", "Ok", {
            duration: 3000,
          });
          return;
        default:
          this.snackbar.open("Unexpected error.", "Ok", {
            duration: 2000,
          });
          return;
      }
    }
    this.snackbar.open("Password reset email sent.", "Ok", {
      duration: 3000,
    });
    this.dialogRef.close();
  }
}
