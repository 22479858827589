export type ScrollMode = "continuous" | "paged";

export type ScrollSpeed =
  | "0.25"
  | "0.5"
  | "0.75"
  | "1"
  | "1.25"
  | "1.5"
  | "1.75"
  | "2";

export type HintMessageKey = "tapToScroll" | "timed" | "paged";

type HintMessageMap = Record<HintMessageKey, string>;

export const prompterHintMessageMap: HintMessageMap = {
  tapToScroll:
    "Tap anywhere on the prompter to start on stop playing the script.",
  timed: "Set an amount of time for your script to scroll.",
  paged:
    "Continuous: Scrolls through your script in one uninterrupted movement.\nPaged: Tap to scroll through your script one page at a time.",
};

export type ScrollIntervalMap = Record<ScrollSpeed, number>;

export type TextType = "plain" | "html";

export const baseScrollIntervalMap: ScrollIntervalMap = {
  0.25: 112,
  0.5: 96,
  0.75: 80,
  1: 64,
  1.25: 48,
  1.5: 32,
  1.75: 16,
  2: 1,
};

export interface IPrompterOptions {
  scrollSpeed: number;
  fontSize: number;
  prompterWidth: number;
  prompterHeight: number;
  fontColor: string;
  backgroundColor: string;
  borderColor: string;
  spacerHeight: number;
  keyboardControl: boolean;
  gamepadControl: boolean;
  cueIndicator: boolean;
  countdownTimer: boolean;
  countdownLength: number;
  showCountdown: boolean;
  mirrorHorizontal: boolean;
  mirrorVertical: boolean;
  tapToScroll: boolean;
  timedScrolling: boolean;
  timedMin: number;
  timedSec: number;
  scrollMode: ScrollMode;
}

export interface controlNode {
  keyboard: string;
  gamepad: string;
}
export interface IControlMaps {
  playStop: controlNode;
  next: controlNode;
  previous: controlNode;
  reset: controlNode;
  decreaseScrollSpeed: controlNode;
  increaseScrollSpeed: controlNode;
  previousCuePoint: controlNode;
  nextCuePoint: controlNode;
  increaseFontSize: controlNode;
  decreaseFontSize: controlNode;
  increaseScrollSpeedBy10: controlNode;
  decreaseScrollSpeedBy10: controlNode;
  scrollDown: controlNode;
  scrollUp: controlNode;
}

export interface IGlobalPrompterOptions {
  sideMargins: number;
  prompterWidth: number;
  prompterHeight: number;
  spacerHeight: number;
  gamepadControl: boolean;
  keyboardControl: boolean;
  cueIndicator: boolean;
  countdownTimer: boolean;
  countdownLength: number;
  showCountdown: boolean;
  mirrorHorizontal: boolean;
  mirrorVertical: boolean;
  scrollMode: ScrollMode;
  tapToScroll: boolean;
  timedScrolling: boolean;
  remainingTimer: boolean;
}

export interface IScriptCueIndicatorOptions {
  leftArrow: boolean;
  rightArrow: boolean;
  centerLine: boolean;
  arrowWidth: number;
  arrowLength: number;
  lineHeight: number;
  layoutOpacity: number;
  arrowPosition: number;
  layoutColor: string;
  linePosition: number;
}

export interface IScriptPrompterOptions {
  backgroundRed: number;
  backgroundBlue: number;
  backgroundGreen: number;
  borderColor: string;
  fontColor: string;
  fontSize: string;
  fontFace: string;
  timedScrollingSeconds: number;
  scrollSpeed: string;
}

export type ParsedScriptOptions = {
  backgroundColor: string;
  borderColor: string;
  fontColor: string;
  fontFace: string;
  fontSize: number;
  scrollSpeed: number;
  timedMin: number;
  timedSec: number;
};

export const defaultGlobalOptions: IGlobalPrompterOptions = {
  sideMargins: 0,
  prompterWidth: 250,
  prompterHeight: 250,
  spacerHeight: 0,
  keyboardControl: false,
  gamepadControl: false,
  cueIndicator: true,
  countdownTimer: true,
  countdownLength: 3,
  showCountdown: true,
  mirrorHorizontal: false,
  mirrorVertical: false,
  scrollMode: "continuous",
  tapToScroll: false,
  timedScrolling: false,
  remainingTimer: true,
};

export const defaultControlMapping: IControlMaps = {
  playStop: {
    keyboard: "Enter",
    gamepad: "aButton",
  },
  next: {
    keyboard: "ArrowRight",
    gamepad: "bButton",
  },
  previous: {
    keyboard: "ArrowLeft",
    gamepad: "xButton",
  },
  reset: {
    keyboard: "r",
    gamepad: "yButton",
  },
  scrollDown: {
    keyboard: "ArrowDown",
    gamepad: "",
  },
  scrollUp: {
    keyboard: "ArrowUp",
    gamepad: "",
  },
  decreaseScrollSpeed: {
    keyboard: "1",
    gamepad: "lbButton",
  },
  increaseScrollSpeed: {
    keyboard: "3",
    gamepad: "rbButton",
  },
  previousCuePoint: {
    keyboard: "5",
    gamepad: "ltButton",
  },
  nextCuePoint: {
    keyboard: "6",
    gamepad: "rtButton",
  },
  increaseFontSize: {
    keyboard: "=",
    gamepad: "dpadUpButton",
  },
  decreaseFontSize: {
    keyboard: "-",
    gamepad: "dpadDownButton",
  },
  increaseScrollSpeedBy10: {
    keyboard: "4",
    gamepad: "dpadRightButton",
  },
  decreaseScrollSpeedBy10: {
    keyboard: "2",
    gamepad: "dpadLeftButton",
  },
};

export const defaultScriptOptions: IScriptPrompterOptions = {
  backgroundRed: 0,
  backgroundBlue: 0,
  backgroundGreen: 0,
  fontSize: "48",
  fontFace: "Helvetica",
  timedScrollingSeconds: 60,
  fontColor: "#FFFFFF",
  borderColor: "#FFFFFF",
  scrollSpeed: "56",
};

export const defaultPrompterOptions: IPrompterOptions = {
  scrollSpeed: 40,
  fontSize: 36,
  prompterWidth: 0,
  prompterHeight: 0,
  fontColor: "#FFFFFF",
  backgroundColor: "#000000",
  borderColor: "#a1efff",
  spacerHeight: 50,
  keyboardControl: false,
  gamepadControl: false,
  cueIndicator: false,
  countdownTimer: true,
  countdownLength: 3,
  showCountdown: true,
  mirrorHorizontal: false,
  mirrorVertical: false,
  tapToScroll: false,
  timedScrolling: false,
  timedMin: 0,
  timedSec: 0,
  scrollMode: "continuous",
};

export const defaultScriptCueIndicatorOptions: IScriptCueIndicatorOptions = {
  leftArrow: true,
  rightArrow: false,
  centerLine: false,
  arrowWidth: 16, //divided by 2 for border-l and border-r
  arrowLength: 17, //divided by 2 for border-t and border-b
  lineHeight: 12,
  layoutOpacity: 0.5,
  arrowPosition: 45,
  layoutColor: "rgb(255,255,255)",
  linePosition: 47,
};
