<div role="dialog" aria-modal="true">
  <div class="flex justify-between items-center pb-3">
    <div class="font-bold text-lg">Settings</div>
    <div class="flex gap-2 items-center">
      <button
        type="button"
        (click)="onSubmit()"
        [disabled]="
          !globalOptionsForm.valid ||
          !scriptOptionsForm.valid ||
          mappingDuplicate ||
          !cueIndicatorForm.valid
        "
        class="rounded bg-TPBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-TPBlue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-TPBlue"
      >
        Save
      </button>

      <button
        type="button"
        mat-dialog-close
        class="rounded bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm border border-gray-300 hover:bg-gray-50"
      >
        Cancel
      </button>
    </div>
  </div>

  <mat-dialog-content>
    <legend class="text-lg font-semibold text-gray-900 px-4">Text</legend>
    <div class="bg-gray-100 rounded-lg mt-4 divide-y divide-gray-200 px-4">
      <form
        [formGroup]="scriptOptionsForm"
        (ngSubmit)="onSubmit()"
        class="divide-y divide-gray-200"
      >
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-1" class="select-none text-gray-900"
              >Text Colour</label
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <mat-icon
              class="cursor-pointer rounded-full border-black border-2"
              [style.color]="fontColor"
              [(colorPicker)]="fontColor"
              cpOutputFormat="hex"
              [cpPositionRelativeToArrow]="false"
              cpPosition="bottom"
              aria-hidden="false"
              [cpUseRootViewContainer]="true"
              cpAlphaChannel="disabled"
              aria-label=""
              >circle</mat-icon
            >
          </div>
        </div>
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-2" class="select-none text-gray-900"
              >Text Size</label
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <button
              type="button"
              (click)="fontSizeDecrease()"
              [disabled]="fontSize < 2"
              class="computer:hidden rounded-full p-1 w-5 text-TPBlue-600 font-bold text-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              -
            </button>
            <input
              type="number"
              formControlName="fontSize"
              min="1"
              class="text-sm invalid:border-red-500 block w-20 min-w-0 flex-grow rounded-md border-gray-300 focus:border-teleprompterBlue focus:ring-teleprompterBlue"
            />
          </div>
          <button
            type="button"
            (click)="fontSizeIncrease()"
            class="computer:hidden rounded-full p-1 w-5 text-TPBlue-600 font-bold text-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            +
          </button>
        </div>
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-3" class="select-none text-gray-900">Font</label>
          </div>
          <div class="ml-3 flex h-6 items-center">
            <select
              formControlName="fontFace"
              id="fontFace"
              name="fontFace"
              class="text-sm block rounded-md border-gray-300 py-2 pl-3 pr-10 focus:border-teleprompterBlue focus:outline-none focus:ring-teleprompterBlue"
            >
              <option
                *ngFor="let font of fonts"
                [style.fontFamily]="font"
                [value]="font"
              >
                {{ font }}
              </option>
            </select>
          </div>
        </div>
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-4" class="select-none text-gray-900"
              >Background Colour</label
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <mat-icon
              class="cursor-pointer rounded-full border-black border-2"
              [style.color]="backgroundColor"
              [(colorPicker)]="backgroundColor"
              [cpPositionRelativeToArrow]="true"
              cpPosition="bottom"
              [cpOutputFormat]="'rgba'"
              [cpUseRootViewContainer]="true"
              cpAlphaChannel="disabled"
              aria-hidden="false"
              aria-label=""
              >circle</mat-icon
            >
          </div>
        </div>
      </form>
      <form [formGroup]="liveUpdatingForm" (ngSubmit)="onSubmit()">
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-1" class="select-none text-gray-900"
              >Live Updates</label
            >
            <span class="block text-gray-400 text-xs"
              >Update text in real time when scripts are edited</span
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <button
              (click)="liveUpdatingChange()"
              type="button"
              class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
              [ngClass]="{
                'bg-gray-200 hover:bg-gray-300': !liveUpdating,
                'bg-teleprompterBlue hover:bg-TPBlue-600': liveUpdating
              }"
              role="switch"
            >
              <span class="sr-only">Use setting</span>
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                [ngClass]="{
                  'translate-x-0': !liveUpdating,
                  'translate-x-5': liveUpdating
                }"
              ></span>
            </button>
          </div>
        </div>
      </form>

      <div class="flex items-center py-1 text-sm">
        <div class="min-w-0 flex-1 text-sm">
          <label for="person-4" class="select-none text-gray-900"
            >Side Margins</label
          >
        </div>
        <img class="w-auto h-4" src="assets/icons/nomargins.svg" />
        <mat-slider
          thumbLabel="true"
          min="0"
          max="50"
          [value]="sideMargins"
          (change)="onChangeSideMargins($event)"
          color="primary"
          class="grow p-0"
        ></mat-slider>
        <img class="w-auto h-4" src="assets/icons/margins.svg" />
      </div>
    </div>
    <legend class="mt-8 text-lg font-semibold text-gray-900 px-4">
      Scrolling
    </legend>
    <div class="bg-gray-100 rounded-lg mt-4 divide-y divide-gray-200 px-4">
      <form [formGroup]="globalOptionsForm" (ngSubmit)="onSubmit()">
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-1" class="select-none text-gray-900"
              >Show Clock</label
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <button
              (click)="remainingTimerChange()"
              type="button"
              class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
              [ngClass]="{
                'bg-gray-200 hover:bg-gray-300': !remainingTimer,
                'bg-teleprompterBlue hover:bg-TPBlue-600': remainingTimer
              }"
              role="switch"
            >
              <span class="sr-only">Use setting</span>
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                [ngClass]="{
                  'translate-x-0': !remainingTimer,
                  'translate-x-5': remainingTimer
                }"
              ></span>
            </button>
          </div>
        </div>

        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-1" class="select-none text-gray-900"
              >Tap to Scroll</label
            >
            <span class="block text-gray-400 text-xs"
              >Tap or click anywhere in the teleprompter to start/stop
              scrolling</span
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <button
              (click)="tapToScrollChange()"
              type="button"
              class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
              [ngClass]="{
                'bg-gray-200 hover:bg-gray-300': !tapToScroll,
                'bg-teleprompterBlue hover:bg-TPBlue-600': tapToScroll
              }"
              role="switch"
            >
              <span class="sr-only">Use setting</span>
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                [ngClass]="{
                  'translate-x-0': !tapToScroll,
                  'translate-x-5': tapToScroll
                }"
              ></span>
            </button>
          </div>
        </div>
      </form>
      <form [formGroup]="scriptOptionsForm" (ngSubmit)="onSubmit()">
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-1" class="select-none text-gray-900"
              >Timed Scrolling</label
            >
            <span class="block text-gray-400 text-xs"
              >Set an amount of time for your script to scroll from start to
              finish</span
            >

            <div
              *ngIf="timedScrolling"
              class="flex gap-2 items-center phone:flex-col"
            >
              <div class="mt-2 flex rounded-md shadow-sm">
                <button
                  type="button"
                  (click)="timedMinDecrease()"
                  [disabled]="timedMin < 1"
                  class="computer:hidden rounded-full p-1 w-5 text-TPBlue-600 font-bold text-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  -
                </button>
                <span
                  class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
                  >Minutes:</span
                >
                <input
                  type="number"
                  formControlName="timedMin"
                  name="minutes"
                  id="minutes"
                  min="0"
                  class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
                  placeholder="0"
                />
                <button
                  type="button"
                  (click)="timedMinIncrease()"
                  class="computer:hidden rounded-full p-1 w-5 text-TPBlue-600 font-bold text-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  +
                </button>
              </div>

              <div class="mt-2 flex rounded-md shadow-sm">
                <button
                  type="button"
                  (click)="timedSecDecrease()"
                  [disabled]="timedSec < 1"
                  class="computer:hidden rounded-full p-1 w-5 text-TPBlue-600 font-bold text-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  -
                </button>
                <span
                  class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
                  >Seconds:</span
                >
                <input
                  type="number"
                  formControlName="timedSec"
                  name="seconds"
                  id="seconds"
                  min="0"
                  class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
                  placeholder="0"
                />
                <button
                  type="button"
                  (click)="timedSecIncrease()"
                  class="computer:hidden rounded-full p-1 w-5 text-TPBlue-600 font-bold text-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  +
                </button>
              </div>
            </div>
          </div>

          <div class="ml-3 flex h-6 items-center">
            <button
              (click)="timedScrollingChange()"
              type="button"
              class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
              [ngClass]="{
                'bg-gray-200 hover:bg-gray-300': !timedScrolling,
                'bg-teleprompterBlue hover:bg-TPBlue-600': timedScrolling
              }"
              role="switch"
            >
              <span class="sr-only">Use setting</span>
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                [ngClass]="{
                  'translate-x-0': !timedScrolling,
                  'translate-x-5': timedScrolling
                }"
                class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
              ></span>
            </button>
          </div>
        </div>
      </form>
      <form [formGroup]="globalOptionsForm" (ngSubmit)="onSubmit()">
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-3" class="select-none text-gray-900"
              >Countdown Timer</label
            >
            <div *ngIf="countdownTimer" class="flex gap-2 items-center">
              <div class="mt-2 flex rounded-md shadow-sm">
                <button
                  type="button"
                  (click)="countdownLengthDecrease()"
                  [disabled]="countdown < 2"
                  class="computer:hidden rounded-full p-1 w-5 text-TPBlue-600 font-bold text-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  -
                </button>
                <span
                  class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
                  >Seconds:</span
                >
                <input
                  type="number"
                  formControlName="countdownLength"
                  name="countdownLength"
                  id="countdownLength"
                  min="1"
                  max="30"
                  class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
                  placeholder="0"
                />
                <button
                  type="button"
                  (click)="countdownLengthIncrease()"
                  [disabled]="countdown === 30"
                  class="computer:hidden rounded-full p-1 w-5 text-TPBlue-600 font-bold text-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                  +
                </button>
              </div>
              <div
                *ngIf="
                  countdownLength?.invalid &&
                  (countdownLength?.dirty || countdownLength?.touched)
                "
                class="rounded-md bg-red-50 p-3 mt-2"
              >
                <div class="text-xs text-red-800 text-center">
                  &uarr; Please enter a number below or equal to 30
                </div>
              </div>
            </div>
          </div>
          <div class="ml-3 flex h-6 items-center">
            <button
              (click)="countdownTimerChange()"
              type="button"
              class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
              [ngClass]="{
                'bg-gray-200 hover:bg-gray-300': !countdownTimer,
                'bg-teleprompterBlue hover:bg-TPBlue-600': countdownTimer
              }"
              role="switch"
            >
              <span class="sr-only">Use setting</span>
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                [ngClass]="{
                  'translate-x-0': !countdownTimer,
                  'translate-x-5': countdownTimer
                }"
                class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
              ></span>
            </button>
          </div>
        </div>
      </form>

      <form [formGroup]="globalOptionsForm" (ngSubmit)="onSubmit()">
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-3" class="select-none text-gray-900"
              >Cue Indicator</label
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <button
              (click)="cueIndicatorChange()"
              type="button"
              class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
              [ngClass]="{
                'bg-gray-200 hover:bg-gray-300': !cueIndicator,
                'bg-teleprompterBlue hover:bg-TPBlue-600': cueIndicator
              }"
              role="switch"
            >
              <span class="sr-only">Use setting</span>
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                [ngClass]="{
                  'translate-x-0': !cueIndicator,
                  'translate-x-5': cueIndicator
                }"
                class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
              ></span>
            </button>
          </div>
        </div>
      </form>

      <form
        [formGroup]="cueIndicatorForm"
        *ngIf="cueIndicator"
        (ngSubmit)="onSubmit()"
      >
        <div class="pl-4 divide-y divide-gray-200">
          <div class="relative flex items-center py-3 text-sm">
            <div class="min-w-0 flex-1 text-sm">
              <label for="person-4" class="select-none text-gray-900"
                >Colour</label
              >
            </div>
            <div class="ml-3 flex h-6 items-center">
              <mat-icon
                class="cursor-pointer rounded-full border-black border-2"
                [style.color]="layoutColor"
                [(colorPicker)]="layoutColor"
                [cpPositionRelativeToArrow]="true"
                (colorPickerSelect)="onChangeLayoutColor($event)"
                cpOutputFormat="rgba"
                cpPosition="bottom"
                aria-hidden="false"
                [cpUseRootViewContainer]="true"
                cpAlphaChannel="disabled"
                aria-label=""
                >circle</mat-icon
              >
            </div>
          </div>

          <div class="relative flex items-center py-3 text-sm">
            <div class="min-w-0 flex-1 text-sm flex gap-2">
              <span class="select-none text-gray-600">►</span>
              <span class="select-none text-gray-900">Left Arrow</span>
            </div>
            <div class="ml-3 flex h-6 items-center">
              <button
                (click)="leftArrowChange()"
                type="button"
                class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
                [ngClass]="{
                  'bg-gray-200 hover:bg-gray-300': !leftArrow,
                  'bg-teleprompterBlue hover:bg-TPBlue-600': leftArrow
                }"
                role="switch"
              >
                <span class="sr-only">Use setting</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span
                  aria-hidden="true"
                  [ngClass]="{
                    'translate-x-0': !leftArrow,
                    'translate-x-5': leftArrow
                  }"
                  class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                ></span>
              </button>
            </div>
          </div>

          <div class="relative flex items-center py-3 text-sm">
            <div class="min-w-0 flex-1 text-sm flex gap-2">
              <span class="select-none text-gray-600">◄</span>
              <span class="select-none text-gray-900">Right Arrow</span>
            </div>
            <div class="ml-3 flex h-6 items-center">
              <button
                (click)="rightArrowChange()"
                type="button"
                class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
                [ngClass]="{
                  'bg-gray-200 hover:bg-gray-300': !rightArrow,
                  'bg-teleprompterBlue hover:bg-TPBlue-600': rightArrow
                }"
                role="switch"
              >
                <span class="sr-only">Use setting</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span
                  aria-hidden="true"
                  [ngClass]="{
                    'translate-x-0': !rightArrow,
                    'translate-x-5': rightArrow
                  }"
                  class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                ></span>
              </button>
            </div>
          </div>

          <div class="relative flex items-center py-3 text-sm">
            <div class="min-w-0 flex-1 text-sm flex gap-2">
              <span class="select-none text-gray-600">─</span>
              <span class="select-none text-gray-900">Reading Line</span>
            </div>
            <div class="ml-3 flex h-6 items-center">
              <button
                (click)="centerLineChange()"
                type="button"
                class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
                [ngClass]="{
                  'bg-gray-200 hover:bg-gray-300': !centerLine,
                  'bg-teleprompterBlue hover:bg-TPBlue-600': centerLine
                }"
                role="switch"
              >
                <span class="sr-only">Use setting</span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span
                  aria-hidden="true"
                  [ngClass]="{
                    'translate-x-0': !centerLine,
                    'translate-x-5': centerLine
                  }"
                  class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                ></span>
              </button>
            </div>
          </div>

          <div class="flex items-center py-1 text-sm">
            <div class="min-w-0 flex-1 text-sm">
              <label for="person-4" class="select-none text-gray-900"
                >Arrow Width</label
              >
            </div>
            <img class="w-4 h-4" src="assets/icons/cueIndicator/width2.svg" />
            <mat-slider
              thumbLabel="false"
              min="1"
              max="50"
              [value]="arrowWidth"
              (change)="onChangeArrowWidth($event)"
              color="primary"
              class="grow p-0"
            ></mat-slider>
            <img class="w-4 h-4" src="assets/icons/cueIndicator/width1.svg" />
          </div>

          <div class="flex items-center py-1 text-sm">
            <div class="min-w-0 flex-1 text-sm">
              <label for="person-4" class="select-none text-gray-900"
                >Arrow Height</label
              >
            </div>
            <img class="w-4 h-4" src="assets/icons/cueIndicator/length1.svg" />
            <mat-slider
              thumbLabel="false"
              min="1"
              max="50"
              [value]="arrowLength"
              (change)="onChangeArrowLength($event)"
              color="primary"
              class="grow p-0"
            ></mat-slider>
            <img class="w-4 h-4" src="assets/icons/cueIndicator/length2.svg" />
          </div>

          <div class="flex items-center py-1 text-sm">
            <div class="min-w-0 flex-1 text-sm">
              <label for="person-4" class="select-none text-gray-900"
                >Line Height</label
              >
            </div>
            <img class="w-4 h-4" src="assets/icons/cueIndicator/line1.svg" />
            <mat-slider
              thumbLabel="false"
              min="1"
              max="50"
              [value]="lineHeight"
              (change)="onChangeLineHeight($event)"
              color="primary"
              class="grow p-0"
            ></mat-slider>
            <img class="w-4 h-4" src="assets/icons/cueIndicator/line2.svg" />
          </div>

          <div class="flex items-center py-1 text-sm">
            <div class="min-w-0 flex-1 text-sm">
              <label for="person-4" class="select-none text-gray-900"
                >Opacity</label
              >
            </div>
            <img class="w-4 h-4" src="assets/icons/cueIndicator/opacity1.svg" />
            <mat-slider
              thumbLabel="false"
              min="0.1"
              max="1.0"
              step="0.1"
              [value]="layoutOpacity"
              (change)="onChangeLayoutOpacity($event)"
              color="primary"
              class="grow p-0"
            ></mat-slider>
            <img class="w-4 h-4" src="assets/icons/cueIndicator/opacity2.svg" />
          </div>

          <div class="flex items-center py-1 text-sm">
            <div class="min-w-0 flex-1 text-sm">
              <label for="person-4" class="select-none text-gray-900"
                >Vertical Position</label
              >
            </div>
            <img class="w-4 h-4" src="assets/icons/cueIndicator/vertical.svg" />
            <mat-slider
              thumbLabel="false"
              min="1"
              max="90"
              [value]="arrowPosition"
              (change)="onChangeArrowPosition($event)"
              color="primary"
              class="grow p-0 mr-4"
            ></mat-slider>
          </div>
        </div>
        <button
          type="button"
          (click)="onResetCueIndicatorDefaults()"
          class="ml-4 mb-2 basis-full flex gap-1 text-xs text-TPBlue hover:text-TPBlue-700"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
            />
          </svg>
          <span>Reset to default</span>
        </button>
      </form>

      <form [formGroup]="globalOptionsForm" (ngSubmit)="onSubmit()">
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-3" class="select-none text-gray-900"
              >Scroll Mode</label
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <span
              *ngIf="scrollMode === 'continuous'"
              class="isolate inline-flex rounded-md shadow-sm"
            >
              <button
                type="button"
                class="relative inline-flex items-center rounded-l-md bg-TPBlue-100 px-3 py-2 text-sm font-semibold text-TPBlue ring-1 ring-inset ring-gray-300 focus:z-10"
              >
                Continuous
              </button>
              <button
                type="button"
                (click)="scrollModeChange('paged')"
                class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              >
                Paging
              </button>
            </span>

            <span
              *ngIf="scrollMode === 'paged'"
              class="isolate inline-flex rounded-md shadow-sm"
            >
              <button
                type="button"
                (click)="scrollModeChange('continuous')"
                class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              >
                Continuous
              </button>
              <button
                type="button"
                class="relative -ml-px inline-flex items-center rounded-r-md bg-TPBlue-100 px-3 py-2 text-sm font-semibold text-TPBlue ring-1 ring-inset ring-gray-300 focus:z-10"
              >
                Paging
              </button>
            </span>
          </div>
        </div>
      </form>
    </div>

    <legend class="mt-8 text-lg font-semibold text-gray-900 px-4">
      Mirroring
    </legend>
    <div class="bg-gray-100 rounded-lg mt-4 divide-y divide-gray-200 px-4">
      <form [formGroup]="globalOptionsForm" (ngSubmit)="onSubmit()">
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-1" class="select-none text-gray-900"
              >Flip Text</label
            >
            <span class="block text-gray-400 text-xs"
              >Mirror the text to be readable through a glass teleprompter</span
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <span class="isolate inline-flex rounded-md shadow-sm">
              <button
                type="button"
                (click)="mirrorVerticalChange()"
                [disabled]="mirrorHorizontal"
                class="relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10 disabled:text-gray-300"
                [ngClass]="{
                  'text-gray-900 bg-white hover:bg-gray-50': !mirrorVertical,
                  'text-TPBlue bg-TPBlue-100': mirrorVertical
                }"
              >
                <svg
                  viewBox="0 0 18 19"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-7 w-8"
                >
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="mirror-copy"
                      transform="translate(-62.000000, -74.000000)"
                      fill="currentColor"
                      fill-rule="nonzero"
                    >
                      <g
                        id="mirror-h"
                        transform="translate(62.887019, 74.500000)"
                      >
                        <path
                          d="M14.7255552,0 C15.5539823,0 16.2255552,0.671572875 16.2255552,1.5 C16.2255552,1.66122314 16.1995634,1.82139195 16.1485801,1.97434165 L14.5873227,6.65811388 C14.5,6.92008554 14.2168388,7.06166554 13.9548672,6.97434165 C13.6928955,6.88701776 13.5513155,6.60385778 13.6386394,6.34188612 L15.1998968,1.65811388 C15.2168912,1.60713065 15.2255552,1.55374105 15.2255552,1.5 C15.2255552,1.22385763 15.0016976,1 14.7255552,1 L1.50040695,1 C1.44666591,1 1.39327631,1.00866394 1.34229307,1.02565835 C1.08032141,1.11298224 0.938741418,1.39614222 1.0260653,1.65811388 L2.58732272,6.34188612 C2.6746466,6.60385778 2.53306661,6.88701776 2.27109495,6.97434165 C2.00912329,7.06166554 1.7259633,6.92008554 1.63863942,6.65811388 L0.0773820066,1.97434165 C-0.184589652,1.18842667 0.240150328,0.338946712 1.0260653,0.0769750529 C1.17901501,0.0259918185 1.33918381,0 1.50040695,0 L14.7255552,0 Z"
                          id="Path"
                        ></path>
                        <path
                          d="M0.612981067,9.5 L3.61298107,9.5 C3.88912344,9.5 4.11298107,9.27614237 4.11298107,9 C4.11298107,8.72385763 3.88912344,8.5 3.61298107,8.5 L0.612981067,8.5 C0.336838692,8.5 0.112981067,8.72385763 0.112981067,9 C0.112981067,9.27614237 0.336838692,9.5 0.612981067,9.5 Z"
                          id="Path"
                        ></path>
                        <path
                          d="M6.61298107,9.5 L9.61298107,9.5 C9.88912344,9.5 10.1129811,9.27614237 10.1129811,9 C10.1129811,8.72385763 9.88912344,8.5 9.61298107,8.5 L6.61298107,8.5 C6.33683869,8.5 6.11298107,8.72385763 6.11298107,9 C6.11298107,9.27614237 6.33683869,9.5 6.61298107,9.5 Z"
                          id="Path"
                        ></path>
                        <path
                          d="M12.6129811,9.5 L15.6129811,9.5 C15.8891234,9.5 16.1129811,9.27614237 16.1129811,9 C16.1129811,8.72385763 15.8891234,8.5 15.6129811,8.5 L12.6129811,8.5 C12.3368387,8.5 12.1129811,8.72385763 12.1129811,9 C12.1129811,9.27614237 12.3368387,9.5 12.6129811,9.5 Z"
                          id="Path"
                        ></path>
                        <path
                          d="M1.63863942,11.3418861 L0.705767493,14.1405019 C0.618443607,14.4024736 0.7600236,14.6856335 1.02199526,14.7729574 C1.28396692,14.8602813 1.5671269,14.7187013 1.65445079,14.4567297 L2.58732272,11.6581139 C2.6746466,11.3961422 2.53306661,11.1129822 2.27109495,11.0256584 C2.00912329,10.9383345 1.7259633,11.0799145 1.63863942,11.3418861 Z"
                          id="Path"
                          opacity="0.5"
                        ></path>
                        <path
                          d="M0.336258257,17.4461161 C0.47984696,17.6230224 0.662256985,17.7655182 0.871755936,17.8620063 C1.15035625,17.965358 1.31689517,18 1.50040695,18 L3.67869961,18 C3.95484199,18 4.17869961,17.7761424 4.17869961,17.5 C4.17869961,17.2238576 3.95484199,17 3.67869961,17 L1.50040695,17 C1.44547783,17 1.39073753,16.9886134 1.25216878,16.938099 C1.23110508,16.9271636 1.19733471,16.9026225 1.16302869,16.8700647 L1.11268929,16.8159139 C0.938663754,16.6015084 0.623778173,16.568774 0.409372662,16.7427995 C0.194967152,16.916825 0.162232717,17.2317106 0.336258257,17.4461161 Z"
                          id="Path"
                          opacity="0.5"
                        ></path>
                        <path
                          d="M6.62869966,18 L9.57869971,18 C9.85484209,18 10.0786997,17.7761424 10.0786997,17.5 C10.0786997,17.2238576 9.85484209,17 9.57869971,17 L6.62869966,17 C6.35255729,17 6.12869966,17.2238576 6.12869966,17.5 C6.12869966,17.7761424 6.35255729,18 6.62869966,18 Z"
                          id="Path"
                          opacity="0.5"
                        ></path>
                        <path
                          d="M12.5286998,18 L14.7255552,18 C15.1214555,18 15.4942373,17.845275 15.7721869,17.5745063 C15.9699874,17.3818159 15.97413,17.0652606 15.7814397,16.86746 C15.6101594,16.6916373 15.3410104,16.6688283 15.1443925,16.8012629 L15.0743935,16.8582072 C14.9813401,16.9488565 14.8581192,17 14.7255552,17 L12.5286998,17 C12.2525574,17 12.0286998,17.2238576 12.0286998,17.5 C12.0286998,17.7761424 12.2525574,18 12.5286998,18 Z"
                          id="Path"
                          opacity="0.5"
                        ></path>
                        <path
                          d="M15.5260647,14.1581121 L14.5931928,11.3594963 C14.5058689,11.0975246 14.2227089,10.9559446 13.9607372,11.0432685 C13.6987656,11.1305924 13.5571856,11.4137524 13.6445095,11.6757241 L14.5773814,14.4743398 C14.6647053,14.7363115 14.9478653,14.8778915 15.2098369,14.7905676 C15.4718086,14.7032437 15.6133886,14.4200837 15.5260647,14.1581121 Z"
                          id="Path"
                          opacity="0.5"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
              <button
                type="button"
                (click)="mirrorHorizontalChange()"
                [disabled]="mirrorVertical"
                class="relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 disabled:text-gray-300"
                [ngClass]="{
                  'text-gray-900 bg-white hover:bg-gray-50': !mirrorHorizontal,
                  'text-TPBlue bg-TPBlue-100': mirrorHorizontal
                }"
              >
                <svg
                  viewBox="0 0 18 19"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-7 w-8"
                >
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="mirror-copy"
                      transform="translate(-85.000000, -75.000000)"
                      fill="currentColor"
                      fill-rule="nonzero"
                    >
                      <g
                        id="mirror-v"
                        transform="translate(85.000000, 75.387426)"
                      >
                        <path
                          d="M1.5,0 C1.66122314,0 1.82139195,0.0259918185 1.97434165,0.0769750529 L6.65811388,1.63823246 C6.92008554,1.72555635 7.06166554,2.00871634 6.97434165,2.270688 C6.88701776,2.53265966 6.60385778,2.67423965 6.34188612,2.58691576 L1.65811388,1.02565835 C1.60713065,1.00866394 1.55374105,1 1.5,1 C1.22385763,1 1,1.22385763 1,1.5 L1,14.7251482 C1,14.7788893 1.00866394,14.8322789 1.02565835,14.8832621 C1.11298224,15.1452338 1.39614222,15.2868138 1.65811388,15.1994899 L6.34188612,13.6382325 C6.60385778,13.5509086 6.88701776,13.6924886 6.97434165,13.9544602 C7.06166554,14.2164319 6.92008554,14.4995919 6.65811388,14.5869158 L1.97434165,16.1481732 C1.18842667,16.4101448 0.338946712,15.9854049 0.0769750529,15.1994899 C0.0259918185,15.0465402 0,14.8863714 0,14.7251482 L0,1.5 C0,0.671572875 0.671572875,0 1.5,0 Z"
                          id="Path"
                        ></path>
                        <path
                          d="M11.3418861,14.5869158 L14.1405019,15.5197877 C14.4024736,15.6071116 14.6856335,15.4655316 14.7729574,15.2035599 C14.8602813,14.9415883 14.7187013,14.6584283 14.4567297,14.5711044 L11.6581139,13.6382325 C11.3961422,13.5509086 11.1129822,13.6924886 11.0256584,13.9544602 C10.9383345,14.2164319 11.0799145,14.4995919 11.3418861,14.5869158 Z"
                          id="Path"
                          opacity="0.500372024"
                        ></path>
                        <path
                          d="M17.4461161,15.8892969 C17.6230224,15.7457082 17.7655182,15.5632982 17.8620063,15.3537992 C17.965358,15.0751989 18,14.90866 18,14.7251482 L18,12.5468556 C18,12.2707132 17.7761424,12.0468556 17.5,12.0468556 C17.2238576,12.0468556 17,12.2707132 17,12.5468556 L17,14.7251482 C17,14.7800773 16.9886134,14.8348176 16.938099,14.9733864 C16.9271636,14.9944501 16.9026225,15.0282205 16.8700647,15.0625265 L16.8159139,15.1128659 C16.6015084,15.2868914 16.568774,15.601777 16.7427995,15.8161825 C16.916825,16.030588 17.2317106,16.0633225 17.4461161,15.8892969 Z"
                          id="Path"
                          opacity="0.500372024"
                        ></path>
                        <path
                          d="M18,9.59685552 L18,6.64685547 C18,6.3707131 17.7761424,6.14685547 17.5,6.14685547 C17.2238576,6.14685547 17,6.3707131 17,6.64685547 L17,9.59685552 C17,9.87299789 17.2238576,10.0968555 17.5,10.0968555 C17.7761424,10.0968555 18,9.87299789 18,9.59685552 Z"
                          id="Path"
                          opacity="0.500372024"
                        ></path>
                        <path
                          d="M18,3.69685542 L18,1.5 C18,1.10409966 17.845275,0.731317841 17.5745063,0.453368312 C17.3818159,0.255567787 17.0652606,0.251425156 16.86746,0.444115487 C16.6916373,0.61539578 16.6688283,0.884544759 16.8012629,1.0811627 L16.8582072,1.15116173 C16.9488565,1.24421508 17,1.36743598 17,1.5 L17,3.69685542 C17,3.9729978 17.2238576,4.19685542 17.5,4.19685542 C17.7761424,4.19685542 18,3.9729978 18,3.69685542 Z"
                          id="Path"
                          opacity="0.500372024"
                        ></path>
                        <path
                          d="M14.1581121,0.699490479 L11.3594963,1.6323624 C11.0975246,1.71968629 10.9559446,2.00284628 11.0432685,2.26481794 C11.1305924,2.52678959 11.4137524,2.66836959 11.6757241,2.5810457 L14.4743398,1.64817378 C14.7363115,1.56084989 14.8778915,1.2776899 14.7905676,1.01571824 C14.7032437,0.753746586 14.4200837,0.612166593 14.1581121,0.699490479 Z"
                          id="Path"
                          opacity="0.500372024"
                        ></path>
                        <path
                          d="M9.5,15.6125741 L9.5,12.6125741 C9.5,12.3364317 9.27614237,12.1125741 9,12.1125741 C8.72385763,12.1125741 8.5,12.3364317 8.5,12.6125741 L8.5,15.6125741 C8.5,15.8887165 8.72385763,16.1125741 9,16.1125741 C9.27614237,16.1125741 9.5,15.8887165 9.5,15.6125741 Z"
                          id="Path"
                        ></path>
                        <path
                          d="M9.5,9.61257411 L9.5,6.61257411 C9.5,6.33643174 9.27614237,6.11257411 9,6.11257411 C8.72385763,6.11257411 8.5,6.33643174 8.5,6.61257411 L8.5,9.61257411 C8.5,9.88871649 8.72385763,10.1125741 9,10.1125741 C9.27614237,10.1125741 9.5,9.88871649 9.5,9.61257411 Z"
                          id="Path"
                        ></path>
                        <path
                          d="M9.5,3.61257411 L9.5,0.612574113 C9.5,0.336431738 9.27614237,0.112574113 9,0.112574113 C8.72385763,0.112574113 8.5,0.336431738 8.5,0.612574113 L8.5,3.61257411 C8.5,3.88871649 8.72385763,4.11257411 9,4.11257411 C9.27614237,4.11257411 9.5,3.88871649 9.5,3.61257411 Z"
                          id="Path"
                        ></path>
                      </g>
                    </g>
                  </g>
                </svg>
              </button>
            </span>
          </div>
        </div>
      </form>
    </div>

    <legend class="mt-8 text-lg font-semibold text-gray-900 px-4">
      Controls
    </legend>
    <div class="bg-gray-100 rounded-lg mt-4 divide-y divide-gray-200 px-4">
      <form [formGroup]="globalOptionsForm" (ngSubmit)="onSubmit()">
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-1" class="select-none text-gray-900"
              >Keyboard</label
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <button
              (click)="keyboardControlChange()"
              type="button"
              class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
              [ngClass]="{
                'bg-gray-200 hover:bg-gray-300': !keyboardControl,
                'bg-teleprompterBlue hover:bg-TPBlue-600': keyboardControl
              }"
              role="switch"
            >
              <span class="sr-only">Use setting</span>
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                [ngClass]="{
                  'translate-x-0': !keyboardControl,
                  'translate-x-5': keyboardControl
                }"
              ></span>
            </button>
          </div>
        </div>
      </form>

      <form
        *ngIf="keyboardControl"
        [formGroup]="keyboardForm"
        (ngSubmit)="onSubmit()"
      >
        <div
          class="relative flex items-center py-3 pl-4 flex-wrap gap-2 text-sm"
        >
          <div
            id="keyboardError"
            *ngIf="kbMappingDuplicate"
            class="basis-full rounded-md bg-red-50 border border-red-200 py-3 px-2"
          >
            <div class="text-xs text-red-800 text-center">
              &darr; Buttons cannot be assigned to the same function.
            </div>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Start/Stop:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 0)"
              name="playStop"
              id="playStop"
              #playStop
              formControlName="playStop"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Next Page:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 1)"
              name="next"
              id="next"
              #next
              formControlName="next"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Previous Page:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 2)"
              name="previous"
              id="previous"
              #previous
              formControlName="previous"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Scroll Up:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 3)"
              name="scrollUp"
              id="scrollUp"
              #scrollUp
              formControlName="scrollUp"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Scroll Down:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 4)"
              name="scrollDown"
              id="scrollDown"
              #scrollDown
              formControlName="scrollDown"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Reset:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 5)"
              name="reset"
              id="reset"
              #reset
              formControlName="reset"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Speed -1:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 6)"
              name="decreaseScrollSpeed"
              id="decreaseScrollSpeed"
              #decreaseScrollSpeed
              formControlName="decreaseScrollSpeed"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Speed -10:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 7)"
              name="decreaseScrollSpeedBy10"
              id="decreaseScrollSpeedBy10"
              #decreaseScrollSpeedBy10
              formControlName="decreaseScrollSpeedBy10"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Speed +1:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 8)"
              name="increaseScrollSpeed"
              id="increaseScrollSpeed"
              #increaseScrollSpeed
              formControlName="increaseScrollSpeed"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Speed +10:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 9)"
              name="increaseScrollSpeedBy10"
              id="increaseScrollSpeedBy10"
              #increaseScrollSpeedBy10
              formControlName="increaseScrollSpeedBy10"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Previous Cue Point:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 10)"
              name="previousCuePoint"
              id="previousCuePoint"
              #previousCuePoint
              formControlName="previousCuePoint"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Next Cue Point:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 11)"
              name="nextCuePoint"
              id="nextCuePoint"
              #nextCuePoint
              formControlName="nextCuePoint"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Font Size Up:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 12)"
              name="increaseFontSize"
              id="increaseFontSize"
              #increaseFontSize
              formControlName="increaseFontSize"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Font Size Down:</span
            >
            <input
              readonly
              type="text"
              (keydown)="setKeyboardButton($event, 13)"
              name="decreaseFontSize"
              id="decreaseFontSize"
              #decreaseFontSize
              formControlName="decreaseFontSize"
              class="block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            />
          </div>

          <button
            type="button"
            (click)="onResetKeyboardDefaults()"
            class="mt-2 basis-full flex gap-1 text-xs text-TPBlue hover:text-TPBlue-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            <span class="">Reset to default</span>
          </button>
        </div>
      </form>

      <form [formGroup]="globalOptionsForm" (ngSubmit)="onSubmit()">
        <div class="relative flex items-center py-3 text-sm">
          <div class="min-w-0 flex-1 text-sm">
            <label for="person-1" class="select-none text-gray-900"
              >Game Controller</label
            >
          </div>
          <div class="ml-3 flex h-6 items-center">
            <button
              (click)="gamepadControlChange()"
              type="button"
              class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
              [ngClass]="{
                'bg-gray-200 hover:bg-gray-300': !gamepadControl,
                'bg-teleprompterBlue hover:bg-TPBlue-600': gamepadControl
              }"
              role="switch"
            >
              <span class="sr-only">Use setting</span>
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span
                aria-hidden="true"
                class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
                [ngClass]="{
                  'translate-x-0': !gamepadControl,
                  'translate-x-5': gamepadControl
                }"
              ></span>
            </button>
          </div>
        </div>
      </form>

      <form
        [formGroup]="gamepadForm"
        *ngIf="gamepadControl"
        (ngSubmit)="onSubmit()"
      >
        <div
          class="relative flex items-center py-3 pl-4 flex-wrap gap-2 text-sm"
        >
          <div
            id="gamepadError"
            *ngIf="mappingDuplicate"
            class="basis-full rounded-md bg-red-50 border border-red-200 py-3 px-2"
          >
            <div class="text-xs text-red-800 text-center">
              &darr; Buttons cannot be assigned to the same function.
            </div>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Start/Stop:</span
            >
            <select
              name="playStop"
              id="playStop"
              #playStop
              formControlName="playStop"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="controlMapping.playStop.gamepad === options"
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Next Page:</span
            >
            <select
              name="next"
              id="next"
              #next
              formControlName="next"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="controlMapping.next.gamepad === options"
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Previous Page:</span
            >
            <select
              name="previous"
              id="previous"
              #previous
              formControlName="previous"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="controlMapping.previous.gamepad === options"
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Reset:</span
            >
            <select
              name="reset"
              id="reset"
              #reset
              formControlName="reset"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="controlMapping.reset.gamepad === options"
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Speed -1:</span
            >
            <select
              name="decreaseScrollSpeed"
              id="decreaseScrollSpeed"
              #decreaseScrollSpeed
              formControlName="decreaseScrollSpeed"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="
                  controlMapping.decreaseScrollSpeed.gamepad === options
                "
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Speed +1:</span
            >
            <select
              name="increaseScrollSpeed"
              id="increaseScrollSpeed"
              #increaseScrollSpeed
              formControlName="increaseScrollSpeed"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="
                  controlMapping.increaseScrollSpeed.gamepad === options
                "
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Previous Cue Point:</span
            >
            <select
              name="previousCuePoint"
              id="previousCuePoint"
              #previousCuePoint
              formControlName="previousCuePoint"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="controlMapping.previousCuePoint.gamepad === options"
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Next Cue Point:</span
            >
            <select
              name="nextCuePoint"
              id="nextCuePoint"
              #nextCuePoint
              formControlName="nextCuePoint"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="controlMapping.nextCuePoint.gamepad === options"
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Font Size Up:</span
            >
            <select
              name="increaseFontSize"
              id="increaseFontSize"
              #increaseFontSize
              formControlName="increaseFontSize"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="controlMapping.increaseFontSize.gamepad === options"
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Font Size Down:</span
            >
            <select
              name="decreaseFontSize"
              id="decreaseFontSize"
              #decreaseFontSize
              formControlName="decreaseFontSize"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="controlMapping.decreaseFontSize.gamepad === options"
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Speed +10:</span
            >
            <select
              name="increaseScrollSpeedBy10"
              id="increaseScrollSpeedBy10"
              #increaseScrollSpeedBy10
              formControlName="increaseScrollSpeedBy10"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="
                  controlMapping.increaseScrollSpeedBy10.gamepad === options
                "
              >
                {{ options }}
              </option>
            </select>
          </div>

          <div class="flex rounded-md shadow-sm">
            <span
              class="bg-white inline-flex items-center rounded-l-md border-l border-t border-b border-gray-300 px-2 text-gray-500"
              >Speed -10:</span
            >
            <select
              name="decreaseScrollSpeedBy10"
              id="decreaseScrollSpeedBy10"
              #decreaseScrollSpeedBy10
              formControlName="decreaseScrollSpeedBy10"
              (change)="checkDuplicateMapping()"
              class="invalid:ring-red-500 invalid:ring-2 block w-full min-w-0 w-24 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-TPBlue-600 text-sm"
            >
              <option
                *ngFor="let options of gamepadOptions"
                [value]="options"
                [selected]="
                  controlMapping.decreaseScrollSpeedBy10.gamepad === options
                "
              >
                {{ options }}
              </option>
            </select>
          </div>

          <button
            type="button"
            (click)="onResetDefaults()"
            class="mt-2 basis-full flex gap-1 text-xs text-TPBlue hover:text-TPBlue-700"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3"
              />
            </svg>
            <span class="">Reset to default</span>
          </button>
        </div>
      </form>
    </div>
  </mat-dialog-content>
</div>
