<h1 mat-dialog-title class="text-center font-bold">Jump To</h1>
<form class="jump-form" [formGroup]="jumpForm" (ngSubmit)="onSubmit()">
  <div class="mb-3">
    <fieldset class="mt-4">
      <legend class="sr-only">Notification method</legend>
      <div class="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
        <div class="flex items-center">
          <input
            id="page"
            type="radio"
            formControlName="jumpType"
            value="page"
            checked
            class="h-4 w-4 border-gray-300 text-teleprompterBlue focus:ring-teleprompterBlue"
          />
          <label for="page" class="ml-3 block text-sm font-medium text-gray-700"
            >Page</label
          >
        </div>

        <div class="flex items-center">
          <input
            id="cuemarker"
            type="radio"
            formControlName="jumpType"
            value="cue"
            class="h-4 w-4 border-gray-300 text-teleprompterBlue focus:ring-teleprompterBlue"
          />
          <label
            for="cuemarker"
            class="ml-3 block text-sm font-medium text-gray-700"
            >Cue Marker</label
          >
        </div>
      </div>
    </fieldset>
  </div>

  <ng-container [ngSwitch]="jumpType">
    <ng-container *ngSwitchCase="'page'">
      <label class="mt-3 block text-sm font-medium text-gray-700">Page</label>

      <div class="mt-1">
        <input
          type="number"
          formControlName="jumpTo"
          id="jumpTo"
          min="1"
          [max]="totalPages"
          class="invalid:border-red-500 block w-full min-w-0 flex-grow rounded-md border-gray-300 focus:border-teleprompterBlue focus:ring-teleprompterBlue sm:text-sm"
        />
      </div>

      <p class="text-black">/{{ totalPages }}</p>
    </ng-container>

    <ng-container *ngSwitchCase="'cue'">
      <ng-container *ngIf="totalMarkers > 0">
        <label class="mt-3 block text-sm font-medium text-gray-700"
          >Cue Marker</label
        >

        <div class="mt-1">
          <input
            formControlName="jumpTo"
            type="number"
            min="1"
            [max]="totalMarkers"
            class="invalid:border-red-500 block w-full min-w-0 flex-grow rounded-md border-gray-300 focus:border-teleprompterBlue focus:ring-teleprompterBlue sm:text-sm"
          />
        </div>
        <p class="text-black">/{{ totalMarkers }}</p>
      </ng-container>

      <p class="text-black font-bold" *ngIf="totalMarkers <= 0">
        No cue markers are present in this script.
      </p>
    </ng-container>
  </ng-container>
  <mat-dialog-actions>
    <button
      type="submit"
      class="mt-3 mr-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-TPBlue hover:bg-TPBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teleprompterBlue disabled:bg-gray-100 disabled:text-gray-200"
    >
      Jump
    </button>

    <button
      type="button"
      mat-dialog-close
      class="mt-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-TPBlue bg-TPBlue-50 hover:bg-TPBlue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teleprompterBlue disabled:bg-gray-100 disabled:text-gray-200"
    >
      Cancel
    </button>
  </mat-dialog-actions>
</form>
