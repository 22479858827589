import { Injectable, OnDestroy } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { BehaviorSubject, Subscription } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { AuthUser } from "../auth/authITC";
import { BroadcastService } from "../broadcast/broadcast.service";
import { AppError } from "../error/appError";
import { ErrorService } from "../error/error.service";
import { ScriptService } from "../scripts/script.service";
import { IUser } from "./userITC";

/**
 * singleton service class that provides methods dealing with user profile CRUD operations
 */
@Injectable({
  providedIn: "root",
})
export class UserService implements OnDestroy {
  /** stores value received fom subscription to authUser$ Observable on the {@link AuthService} */
  private _authUser!: AuthUser | null;
  /** subscription to authUser$ Observable on the {@link AuthService} */
  private _userProfileSub = new Subscription();
  /** BehaviorSubject used to emit values to subscribers of the {@link userProfile$} Observable */
  private _userProfile$ = new BehaviorSubject<IUser | null>(null);
  /** Observable that emits either an {@link IUser} or null value */
  userProfile$ = this._userProfile$.asObservable();
  constructor(
    private db: AngularFireDatabase,
    private errorService: ErrorService,
    private authService: AuthService,
    private scriptsService: ScriptService,
    private broadcastService: BroadcastService
  ) {
    //subscribe to and store the AuthUser provided by the AuthService and use these credentials for service operations
    this._userProfileSub.add(
      this.authService.authUser$.subscribe((authUser: AuthUser | null) => {
        this._authUser = authUser;
        if (authUser) {
          if (!authUser.displayName) {
            this.updateDisplayName();
          }

          this.db
            .object<IUser>("users/" + authUser.uid)
            .valueChanges()
            .subscribe((profile) => {
              if (!profile?.uid && profile) {
                profile.uid = authUser.uid;
              }

              if (!profile?.emailAddress && profile) {
                profile.emailAddress = authUser.email!;
              }

              this._userProfile$.next(profile);
              this.updateUser(profile);
            });
        }
      })
    );
  }

  clearUserProfile() {
    this._userProfile$.next(null);
  }

  updateDisplayName() {
    this._userProfileSub.add(
      this.userProfile$.subscribe((user: IUser | null) => {
        if (this._authUser) {
          this._authUser.updateProfile({
            displayName: user?.firstName,
          });
        }
      })
    );
  }

  /**
   * async method - creates a new user record on the DB under the ref 'users/' with the supplied
   * {@link IUser} details and the {@link _authUser}.uid as the key.
   * @param userProfile {@link IUser}
   * @return Promise<{@link AppError} | void>
   */
  async createUser(userProfile: IUser): Promise<AppError | void> {
    try {
      //if user is not authenticated return an AppError
      if (!this._authUser) {
        return new AppError(
          "e404",
          "no current user",
          "UserService.createUser",
          "check/authUser"
        );
      }
      if (this._authUser.uid) {
        const ref = this.db.database.ref("users/" + this._authUser.uid);
        await ref.set(JSON.parse(JSON.stringify(userProfile)));
      }
      return;
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "UserService.createUser",
        "crud/createUser"
      );
      return appError;
    }
  }

  /**
   * Updates user profile with supplied data.
   * @param data - any
   * @return Promise<void | {@link AppError}>
   */
  async updateUser(data: any): Promise<void | AppError> {
    try {
      //if user is not authenticated return an AppError
      if (!this._authUser) {
        return new AppError(
          "e404",
          "no current user",
          "UserService.createUser",
          "check/authUser"
        );
      }
      const ref = this.db.database.ref("users/" + this._authUser.uid);
      await ref.update(data);
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "UserService.updateUser",
        "crud/updateUser"
      );
      return appError;
    }
  }

  /**
   * Deletes user profile with currently stored auth token
   * @return Promise<{@link AppError} | void>
   */
  async deleteUser(): Promise<AppError | void> {
    try {
      //if user is not authenticated return an AppError
      if (!this._authUser) {
        return new AppError(
          "e404",
          "no current user",
          "UserService.deleteUser",
          "check/authUser"
        );
      }
      let user = this._userProfile$.getValue();
      if (user && user.stripeCustomerId) {
      }
      //delete user profile and auth profile - performed sequentially to avoid auth token race condition
      await Promise.all([
        this.db.database.ref("users/" + this._authUser.uid).remove(),
        this.scriptsService.deleteScriptList(),
        this.broadcastService.deleteBroadcastList(),
      ]);
      await this._authUser.delete();
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "UserService.deleteUser",
        "crud/deleteUser"
      );
      return appError;
    }
  }

  /**
   * Gets the {@link IUser})profile associated with the supplied id from the db.
   * returns null if no user found.
   * @param id string - id of user to seasch for.
   * @return Promise<[{@link IUser} | null, {@link AppError | null}]>
   */
  getUser(id: String): Promise<[IUser | null, AppError | null]> {
    return new Promise((resolve) => {
      try {
        this._userProfileSub.add(
          this.db
            .object<IUser>("users/" + id)
            .valueChanges()
            .subscribe((user) => {
              resolve([user, null]);
            })
        );
      } catch (err) {
        const appError: AppError = this.errorService.parseError(
          err,
          "UserService.getUser",
          "crud/getUser"
        );
        resolve([null, appError]);
      }
    });
  }

  ngOnDestroy(): void {
    this._userProfileSub.unsubscribe();
  }
}
