import { Injectable, OnDestroy } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/compat/database";
import { BehaviorSubject, Subscription } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { AuthUser } from "../auth/authITC";
import { AppError } from "../error/appError";
import { ErrorService } from "../error/error.service";
import {
  BroadcastContent,
  BroadcastOperation,
  HostNode,
  IBroadcast,
  SourceOptions,
} from "./broadcastITC";
import { IScriptCueIndicatorOptions } from "../prompter/prompterITC";
import { map, take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BroadcastService implements OnDestroy {
  /** stores value received fom subscription to authUser$ Observable on the {@link AuthService} */
  private _authUser!: AuthUser | null;
  /** path to user's scripts DB node */
  private _broadcastsNodePath = "";

  private _broadcastsHostsPath = "";
  /** reference to user's scripts node */
  private _dbBroadcastListRef!: AngularFireList<IBroadcast>;
  private _dbBroadcastHostsRef!: AngularFireList<HostNode>;

  /** subscription to user's scripts realtime db list */
  private _userBroadcastsSub = new Subscription();
  /** BehaviorSubject used to emit values to subscribers of the {@link userScripts$} Observable */
  private _userBroadcasts$ = new BehaviorSubject<IBroadcast[] | null>(null);
  /** Observable that emits either an {@link Script[]} or null value */
  userBroadcasts$ = this._userBroadcasts$.asObservable();

  constructor(
    private errorService: ErrorService,
    private authService: AuthService,
    private db: AngularFireDatabase
  ) {
    this._userBroadcastsSub.add(
      this.authService.authUser$.subscribe((authUser) => {
        this._authUser = authUser;
        if (authUser) {
          this._broadcastsNodePath = "broadcasts/" + authUser.uid;

          this._dbBroadcastListRef = this.db.list<IBroadcast>(
            this._broadcastsNodePath
          );

          this._dbBroadcastListRef
            .valueChanges()
            .subscribe((broadcasts: IBroadcast[]) => {
              let userBroadcasts = broadcasts.filter(
                (broadcast) =>
                  broadcast.identification &&
                  broadcast.content &&
                  broadcast.cueIndicatorOptions &&
                  broadcast.sourceOptions
              );
              this._userBroadcasts$.next(userBroadcasts);
            });
        }
      })
    );
  }

  getBroadcastHostRef(
    broadcastId: string,
    hostId: string | null
  ): AngularFireObject<HostNode> | AppError {
    try {
      if (this._authUser) {
        const path = `broadcasts/${this._authUser.uid}/${broadcastId}/hosts/${hostId}`;
        const ref: AngularFireObject<HostNode> = this.db.object(path);
        return ref;
      } else {
        throw new Error("no authUser");
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.getBroadcastHostRef",
        "get/getBroadcastHostRef"
      );
      return appError;
    }
  }

  /**
   * Pushes supplied broadcast to the <@link _dbBroadcastListRef> returns broadcast.ref.key.
   * @param broadcast {@link IBroadcast} - broadcast to init
   * @return Promise<[string | {@link AppError}]>
   */
  async initBroadcast(
    broadcast: IBroadcast
  ): Promise<[string | null, AppError | null]> {
    try {
      //check for duplicate broadcast
      if (await this.isBroadcasting(broadcast.identification.scriptId)) {
        this.updateBroadcast(broadcast);
        return [
          null,
          new AppError(
            "e409",
            "This script is already broadcasting",
            "broadcastService.initBroadcast",
            "crud/initBroadcast"
          ),
        ];
      }
      //check user is authenticated
      if (this._authUser) {
        //push broadcast and update identification
        broadcast.identification.userId = this._authUser?.uid;
        const ref = this._dbBroadcastListRef.push(broadcast);
        let id = broadcast.identification;
        id.broadcastId = ref.key as string;
        await ref.update({ identification: id });
        return [ref.key as string, null];
      } else {
        return [
          null,
          new AppError(
            "e403",
            "no current auth user",
            "BroadcastService.initBroadcast",
            "crud/createBroadcast"
          ),
        ];
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "broadcastService.initBroadcast",
        "crud/createBroadcast"
      );
      return [null, appError];
    }
  }

  async initHost(
    host: HostNode,
    broadcastId: string | null
  ): Promise<[string | null, AppError | null]> {
    try {
      //check user is authenticated
      if (this._authUser) {
        this._broadcastsHostsPath = `broadcasts/${
          this._authUser!.uid
        }/${broadcastId}/hosts`;
        this._dbBroadcastHostsRef = this.db.list<HostNode>(
          this._broadcastsHostsPath
        );
        const ref = this._dbBroadcastHostsRef.push(host);
        return [ref.key as string, null];
      } else {
        return [
          null,
          new AppError(
            "e403",
            "no current auth user",
            "BroadcastService.initHost",
            "crud/initHost"
          ),
        ];
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "broadcastService.initHost",
        "crud/initHost"
      );
      return [null, appError];
    }
  }

  async deleteHost(hostId: string | null, broadcastId: string | null) {
    this._broadcastsHostsPath = `broadcasts/${
      this._authUser!.uid
    }/${broadcastId}/hosts`;
    this._dbBroadcastHostsRef = this.db.list<HostNode>(
      this._broadcastsHostsPath
    );

    await this._dbBroadcastHostsRef.remove(hostId!);
  }

  async deleteHostList(broadcastId: string) {
    try {
      const path = `broadcasts/${this._authUser!.uid}/${broadcastId}/hosts`;
      await this.db.object(path).remove();
    } catch (error) {
      console.error("Error deleting the hosts node:", error);
    }
  }
  async deleteAllOtherHosts(hostIdToKeep: string | null) {
    try {
      const hosts = await this._dbBroadcastHostsRef
        .snapshotChanges()
        .pipe(
          take(1),
          map((actions) =>
            actions.map((a) => ({ key: a.payload.key, ...a.payload.val() }))
          )
        )
        .toPromise();

      await Promise.all(
        hosts
          .filter((hostId) => hostId.key !== hostIdToKeep)
          .map(async (hostId) => {
            await this._dbBroadcastHostsRef.update(`${hostId.key}`, {
              kill: true,
            });
          })
      );
    } catch (error) {
      console.error("Error updating 'kill' property:", error);
    }
  }

  getHostsCount(broadcastId: string | null): Promise<number> {
    const path = `broadcasts/${this._authUser!.uid}/${broadcastId}/hosts`;
    const dbRef = this.db.list<HostNode>(path);

    return new Promise((resolve, reject) => {
      const subscription = dbRef.valueChanges().subscribe(
        (hosts) => {
          const count = hosts.length;
          resolve(count);
          subscription.unsubscribe();
        },
        (error) => {
          reject(error);
          subscription.unsubscribe();
        }
      );
    });
  }

  /**
   * Overwrites supplied broadcast.
   * @param broadcast {@link IBroadcast} - broadcast to update
   * @return Promise<void | {@link AppError}>
   */
  async updateBroadcast(broadcast: IBroadcast): Promise<void | AppError> {
    try {
      if (!(await this.isBroadcasting(broadcast.identification.scriptId))) {
        return new AppError(
          "e404",
          "This script is not broadcasting",
          "broadcastService.updateBroadcast",
          "crud/updateBroadcast"
        );
      }
      if (this._authUser) {
        console.log("updateBroadcast");
        await this._dbBroadcastListRef.set(
          broadcast.identification.broadcastId,
          broadcast
        );
        return;
      } else {
        return new AppError(
          "e403",
          "no current auth user",
          "BroadcastService.updateBroadcast",
          "crud/updateBroadcast"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "broadcastService.updateBroadcast",
        "crud/updateBroadcast"
      );
      return appError;
    }
  }

  /**
   * updates this <@link BroadcastOperation> of a broadcast.
   * @param scriptId string - scriptId of broadcast.
   * @param broadcastId string - of broadcast to update.
   * @param operation <@link BroadcastOperation> - operation to sent
   */
  async updateOperation(
    scriptId: string,
    broadcastId: string,
    operation: BroadcastOperation
  ): Promise<void | AppError> {
    try {
      if (!(await this.isBroadcasting(scriptId))) {
        return new AppError(
          "e404",
          "This script is not broadcasting",
          "broadcastService.updateOperation",
          "crud/updateOperation"
        );
      }
      if (this._authUser) {
        await this._dbBroadcastListRef.update(broadcastId, {
          operation: operation,
        });
        return;
      } else {
        return new AppError(
          "e403",
          "no current auth user",
          "BroadcastService.updateOperation",
          "crud/updateOperation"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "broadcastService.playBroadcast",
        "crud/playBroadcast"
      );
      return appError;
    }
  }

  /**
   * async method - deletes the broadcast list from the db
   * @return Promise<void | {@link AppError}>
   */
  async deleteBroadcastList(): Promise<void | AppError> {
    try {
      if (this._dbBroadcastListRef) {
        await this._dbBroadcastListRef.remove();
      } else {
        return new AppError(
          "e404",
          "delete failed as no _dbBroadcastListRef: AngularFireList<Broadcast>",
          "broadcastService.deleteBroadcastList",
          "crud/deleteBroadcastList"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "broadcastService.deleteBroadcastList",
        "crud/deleteBroadcastList"
      );
      return appError;
    }
  }

  /**
   * updates the scrollTop of a broadcast.
   * @param broadcastId string - of broadcast to update.
   * @param scrollTop number - value to set scrollTop
   */
  async setSourceScrollTop(
    broadcastId: string,
    scrollTop: number
  ): Promise<void | AppError> {
    try {
      if (this._authUser) {
        await this._dbBroadcastListRef.update(broadcastId, {
          sourceScrollTop: scrollTop,
        });
        return;
      } else {
        return new AppError(
          "e403",
          "no current auth user",
          "BroadcastService.setSourceScrollTop",
          "crud/setSourceScrollTop"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "broadcastService.setSourceScrollTop",
        "crud/setSourceScrollTop"
      );
      return appError;
    }
  }

  /**
   * updates the isPublic property of a broadcast.
   * @param broadcastId string - of broadcast to update.
   * @param isPublic boolean - value to set isPublic.
   */
  async setIsPublic(
    broadcastId: string,
    isPublic: boolean
  ): Promise<void | AppError> {
    try {
      if (this._authUser) {
        // console.log(isPublic);
        await this._dbBroadcastListRef.update(broadcastId, {
          isPublic: isPublic,
        });
        return;
      } else {
        return new AppError(
          "e403",
          "no current auth user",
          "BroadcastService.setIsPublic",
          "crud/setIsPublic"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "broadcastService.setIsPublic",
        "crud/setIsPublic"
      );
      return appError;
    }
  }

  /**
   * updates the isPublic property of a broadcast.
   * @param broadcastId string - of broadcast to update.
   * @param isPublic boolean - value to set isPublic.
   */
  async updateSourceOptions(
    broadcastId: string,
    sourceOptions: SourceOptions
  ): Promise<void | AppError> {
    try {
      if (this._authUser) {
        await this._dbBroadcastListRef.update(broadcastId, {
          sourceOptions: sourceOptions,
        });
        return;
      } else {
        return new AppError(
          "e403",
          "no current auth user",
          "BroadcastService.update sourceOptions",
          "crud/setIsPublic"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "broadcastService.updateSourceOptions",
        "crud/updateSourceOptions"
      );
      return appError;
    }
  }

  async updateCueIndicatorOptions(
    broadcastId: string,
    cueIndicatorOptions: IScriptCueIndicatorOptions
  ): Promise<void | AppError> {
    try {
      if (this._authUser) {
        await this._dbBroadcastListRef.update(broadcastId, {
          cueIndicatorOptions: cueIndicatorOptions,
        });
        return;
      } else {
        return new AppError(
          "e403",
          "no current auth user",
          "BroadcastService.update cue options",
          "crud/updateCueOptions"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "broadcastService.updateCueOptions",
        "crud/updateCueOptions"
      );
      return appError;
    }
  }

  /**
   * Returns an array of references to different sections on a broadcast, allowing for
   * more efficient subscriptions.
   * @return [broadcastContent, sourceOptions, scrollTop, operation, isPublic, AppError]
   */
  getBroadcastReferences(
    userId: string,
    broadcastId: string
  ): [
    AngularFireObject<BroadcastContent> | null,
    AngularFireObject<SourceOptions> | null,
    AngularFireObject<number> | null,
    AngularFireObject<BroadcastOperation> | null,
    AngularFireObject<boolean> | null,
    AngularFireObject<IScriptCueIndicatorOptions> | null,
    AppError | null
  ] {
    try {
      let nodePath = `broadcasts/${userId}/${broadcastId}`;
      let broadcastContent: AngularFireObject<BroadcastContent> =
        this.db.object(`${nodePath}/content`);
      let sourceOptions: AngularFireObject<SourceOptions> = this.db.object(
        `${nodePath}/sourceOptions`
      );
      let sourceScrollTop: AngularFireObject<number> = this.db.object(
        `${nodePath}/sourceScrollTop`
      );
      let operation: AngularFireObject<BroadcastOperation> = this.db.object(
        `${nodePath}/operation`
      );

      let isPublic: AngularFireObject<boolean> = this.db.object(
        `${nodePath}/isPublic`
      );

      let cueIndicatorOptions: AngularFireObject<IScriptCueIndicatorOptions> =
        this.db.object(`${nodePath}/cueIndicatorOptions`);

      return [
        broadcastContent,
        sourceOptions,
        sourceScrollTop,
        operation,
        isPublic,
        cueIndicatorOptions,
        null,
      ];
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "BroadcastService.getBroadcastRef",
        "get/broadcastRef"
      );
      return [null, null, null, null, null, null, appError];
    }
  }

  /**
   * Checks if a script is broadcasting.  Returns broadcast data if found, null if none is found
   * @param scriptId string
   * @returns Promise<IBroadcast | null>
   */
  async isBroadcasting(scriptId: string): Promise<IBroadcast | null> {
    let broadcasts = this._userBroadcasts$.getValue();

    if (broadcasts === null) {
      return null;
    }
    for (let broadcast of broadcasts) {
      if (
        broadcast.identification &&
        broadcast.identification.scriptId === scriptId
      ) {
        return broadcast;
      }
    }
    return null;
  }

  /**
   * removes a broadcast from <@link _dbBroadcastListRef>
   * @param broadcastId string
   */
  async stopBroadcast(broadcastId: string) {
    await this._dbBroadcastListRef.remove(broadcastId);
  }

  ngOnDestroy(): void {
    this._userBroadcastsSub.unsubscribe();
  }
}
