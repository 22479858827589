export interface Tiers {
  name?: string;
  desc?: string;
  sevenDayPassPrice?: number;
  weeklyPrice?: number;
  monthlyPrice?: number;
  annualPrice?: number;
  lifetimePrice?: number;
  sevenDayPassPriceId?: string;
  weeklyPriceId?: string;
  monthlyPriceId?: string;
  annualPriceId?: string;
  lifetimePriceId?: string;
  included?: string[];
  moreInfo?: string[];
  icons?: string[];
  rc_offering?: string;
  isShowing?: boolean;
}

export class Tier implements Tiers {
  constructor(
    public name?: "",
    public desc?: "",
    public price?: 0,
    public included?: [],
    public moreInfo?: [],
    public icons?: [],
    public rc_offering?: "",
    public sevenDayPassPrice?: 0,
    public weeklyPrice?: 0,
    public monthlyPrice?: 0,
    public annualPrice?: 0,
    public lifetimePrice?: 0,
    public sevenDayPassPriceId?: "",
    public weeklyPriceId?: "",
    public monthlyPriceId?: "",
    public annualPriceId?: "",
    public lifetimePriceId?: "",
    public isShowing?: false
  ) {}
}

/**
 * We can edit this Dictionary as a main central file
 * which defines all the tiers.
 */
export const tiersDictionary: Tiers[] = [
  {
    name: "CloudPrompter",
    desc: "Teleprompter Studios and Broadcasters",
    sevenDayPassPrice: 29.0,
    weeklyPrice: 0,
    monthlyPrice: 39.0,
    annualPrice: 0,
    lifetimePrice: 0,
    sevenDayPassPriceId: "pri_01h9v3rzad8ph3803f2202vaje",
    weeklyPriceId: "",
    monthlyPriceId: "pri_01h9txmr8y53t0f2jzk6bhxqqw",
    annualPriceId: "",
    lifetimePriceId: "",
    included: [
      "All features in Premium",
      "Screen Share & Background Feature",
      "Video Tools",
      "Create Public/Private Broadcasts",
      "Keyboard Control",
      "Gamepad Control",
    ],
    moreInfo: [
      "The Premium upgrade includes full access to all apps on iOS and Mac on all of your devices. Simply sign in and begin!",
      "Underlay your video meetings, browser window or webcam/phone front camera underneath your scripts.",
      "Record your webcam/phone front camera whilst reading your scripts, available to download for your social media, videos or personal use.",
      "Broadcast your scripts publicly or privately providing an external slave link of your teleprompter. Broadcast users can change background colour, font colour, border colour and flip/reverse text without affecting your teleprompter.",
      "Enable keyboard controls which can be customised to your liking to control the Teleprompter.",
      "Enable gamepad controls, with a huge selection of compatible controlers, including gaming controllers, handeheld clickers and more. These can be customised to your liking to control the Teleprompter.",
    ],
    icons: [
      "assets/icons/goPro/app.svg",
      "assets/icons/goPro/video.svg",
      "assets/icons/goPro/video.svg",
      "assets/icons/goPro/broadcast.svg",
      "assets/icons/goPro/keyboard.svg",
      "assets/icons/goPro/controller.svg",
    ],
    rc_offering: "cloudPrompter_launch",
    isShowing: false,
  },
];

export const defaultTier: Tiers[] = [
  {
    name: "cloudPrompter",
    desc: "Teleprompter Studios and Broadcasters",
    included: [
      "All features in Premium",
      "Screen Share & Background Feature",
      "Video Tools",
      "Create Public/Private Broadcasts",
      "Keyboard Control",
      "Gamepad Control",
    ],
    moreInfo: [
      "The Premium upgrade includes full access to all apps on iOS and Mac on all of your devices. Simply sign in and begin!",
      "Underlay your video meetings, browser window or webcam/phone front camera underneath your scripts.",
      "Record your webcam/phone front camera whilst reading your scripts, available to download for your social media, videos or personal use.",
      "Broadcast your scripts publicly or privately providing an external slave link of your teleprompter. Broadcast users can change background colour, font colour, border colour and flip/reverse text without affecting your teleprompter.",
      "Enable keyboard controls which can be customised to your liking to control the Teleprompter.",
      "Enable gamepad controls, with a huge selection of compatible controlers, including gaming controllers, handeheld clickers and more. These can be customised to your liking to control the Teleprompter.",
    ],
    icons: [
      "assets/icons/goPro/app.svg",
      "assets/icons/goPro/video.svg",
      "assets/icons/goPro/video.svg",
      "assets/icons/goPro/broadcast.svg",
      "assets/icons/goPro/keyboard.svg",
      "assets/icons/goPro/controller.svg",
    ],
    rc_offering: "cloudPrompter_launch",
    isShowing: false,
  },
];
