import { AppError, AppErrorCode } from './appError';

/**
 * map type.  {@link AppErrorCode}s are mapped to code message objects
 */
type AppErrorMap = Record<
  AppErrorCode,
  { code: AppErrorCode; message: string }
>;
/**
 * {@link AppErrorCode}s are mapped to code message objects
 */
export const appErrorMap: AppErrorMap = {
  e400: {
    code: 'e400',
    message: 'invalid or malformed request',
  },
  e401: {
    code: 'e401',
    message: 'unauthorized request - request requires authentication',
  },
  e403: {
    code: 'e403',
    message: 'authorization refused',
  },
  e404: {
    code: 'e404',
    message: 'not found - nothing matching request',
  },
  e409: {
    code: 'e409',
    message:
      'conflict - operation failed due to conflict with pre-existing data',
  },
  e499: {
    code: 'e499',
    message: 'client closed request',
  },
  e500: {
    code: 'e500',
    message: 'unexpected error - detail field may contain more information',
  },
};

interface IErrorMap {
  [key: string]: AppErrorCode;
}

export const firebaseErrorCodes = {
  invalidEmail: 'auth/invalid-email',
  weakPassword: 'auth/weak-password',
  wrongPassword: 'auth/wrong-password',
  accountLocked: 'auth/too-many-requests',
  userNotFound: 'auth/uses-not-found',
  emailInUse: 'auth/email-already-in-use',
  popupClosed: 'auth/popup-closed-by-user',
};

/**
 * firebase error codes mapped to {@link AppErrorCode}s
 */
export const firebaseErrorMap: IErrorMap = {
  'auth/invalid-email': 'e400',
  'auth/weak-password': 'e403',
  'auth/wrong-password': 'e403',
  'auth/too-many-requests': 'e403',
  'auth/user-not-found': 'e404',
  'auth/email-already-in-use': 'e409',
  'auth/popup-closed-by-user': 'e499',
};

type errorHandler = (error: AppError, ...args: any[]) => any;

export type AppErrorHandlerMap = {
  [key in AppErrorCode]: errorHandler;
};
