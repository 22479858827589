import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { environment } from "src/environments/environment";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { NavModule } from "./modules/feature/nav/nav.module";
import { QuillModule } from "ngx-quill";
import { MaterialModule } from "./modules/shared/material/material.module";
import { DialogModule } from "./modules/shared/dialog/dialog.module";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AngularMarkdownEditorModule } from "angular-markdown-editor";
import { MarkdownModule, MarkedOptions, MarkedRenderer } from "ngx-markdown";
import { SubscribedUsersGuard } from "./navigation/SubscribedUsersGuard.guard";
import { BetaUsersGuard } from "./navigation/BetaUsersGuard.guard";
import { IntercomModule } from "ng-intercom";
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from "@angular/fire/compat/analytics";
import { BroadcastGuard } from "./navigation/BroadcastGuard.guard";
@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    NavModule,
    MaterialModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    QuillModule.forRoot({
      modules: {
        // toolbar: [
        //   ["bold", "italic", "underline", "strike"],
        //   [{ header: [1, 2, 3, 4, 5, 6, false] }],
        //   [{ list: "ordered" }, { list: "bullet" }],
        //   [{ color: [] }, { background: [] }],
        // ],
        toolbar: false,
      },
    }),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          renderer: new MarkedRenderer(),
          gfm: true,
          tables: true,
          breaks: true,
          pedantic: false,
          smartLists: true,
          smartypants: false,
        },
      },
    }),
    AngularMarkdownEditorModule.forRoot({
      // add any Global Options/Config you might want
      // to avoid passing the same options over and over in each components of your App
      iconlibrary: "fa",
    }),
    IntercomModule.forRoot({
      appId: "il5enzb7", // from your Intercom config
      updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
    }),
  ],
  exports: [],
  providers: [
    SubscribedUsersGuard,
    BetaUsersGuard,
    BroadcastGuard,
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
