import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSliderChange } from "@angular/material/slider";
import { Subscription } from "rxjs";
import { PrompterService } from "src/app/prompter/prompter.service";
import {
  defaultGlobalOptions,
  defaultScriptCueIndicatorOptions,
  defaultScriptOptions,
  defaultControlMapping,
  HintMessageKey,
  IControlMaps,
  IGlobalPrompterOptions,
  IScriptCueIndicatorOptions,
  IScriptPrompterOptions,
  prompterHintMessageMap,
} from "src/app/prompter/prompterITC";
import {
  Entitlements,
  SubscriptionService,
} from "src/app/subscription/subscription.service";
import { GamepadDialogComponent } from "../gamepad-dialog/gamepad-dialog.component";
import { GoProDialogComponent } from "../go-pro-dialog/go-pro-dialog.component";
import { HintDialogComponent } from "../hint-dialog/hint-dialog.component";
import { KeyboardDialogComponent } from "../keyboard-dialog/keyboard-dialog.component";
import { take } from "rxjs/operators";

type Resize = "fit" | "phone" | "ipad" | "laptop";

@Component({
  selector: "app-script-options-dialog",
  templateUrl: "./script-options-dialog.component.html",
  styleUrls: ["./script-options-dialog.component.scss"],
})
export class ScriptOptionsDialogComponent implements OnInit, OnDestroy {
  fonts: string[] = [
    "Apple Gothic",
    "Arial",
    "Arial Black",
    "Arial Unicode MS",
    "Baskerville",
    "Comic Sans MS",
    "Courier",
    "Courier New",
    "Didot",
    "Futura",
    "Geneva",
    "Georgia",
    "Helvetica",
    "Helvetica Neue",
    "Impact",
    "Lucida Console",
    "Lucida Grande",
    "Lucida Sans Unicode",
    "Monaco",
    "MS Sans Serif",
    "MS Serif",
    "Optima",
    "Palatino Linotype",
    "Tahoma",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
  ];
  gamepadOptions: string[] = [
    "aButton",
    "bButton",
    "xButton",
    "yButton",
    "lbButton",
    "rbButton",
    "ltButton",
    "rtButton",
    "dpadUpButton",
    "dpadDownButton",
    "dpadRightButton",
    "dpadLeftButton",
  ];

  subscriptions: Subscription = new Subscription();
  globalOptions: IGlobalPrompterOptions = defaultGlobalOptions;
  scriptOptions: IScriptPrompterOptions = defaultScriptOptions;
  cueIndicatorOptions: IScriptCueIndicatorOptions =
    defaultScriptCueIndicatorOptions;
  controlMapping: IControlMaps = defaultControlMapping;
  mappingDuplicate: boolean = false;
  kbMappingDuplicate: boolean = false;
  hintMessageMap = prompterHintMessageMap;
  entitlements!: Entitlements | null;
  hasPremiumSub: boolean = false;
  hasCloudPrompterSub: boolean = false;
  disableAnimation: boolean = true;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      maxWidth: number;
      maxHeight: number;
      scriptOptions: IScriptPrompterOptions;
      enable_liveUpdating: boolean;
    },
    private fb: UntypedFormBuilder,
    private prompterService: PrompterService,
    private subscriptionService: SubscriptionService,
    private dialogService: MatDialog,
    public dialogRef: MatDialogRef<ScriptOptionsDialogComponent>
  ) {}

  liveUpdatingForm: UntypedFormGroup = this.fb.group({
    enableLiveUpdating: [this.data.enable_liveUpdating, Validators.required],
  });

  scriptOptionsForm: UntypedFormGroup = this.fb.group({
    backgroundColor: ["#000000"],
    borderColor: ["#FFFFFF"],
    fontColor: ["#FFFFFF"],
    fontSize: [48, [Validators.min(1), Validators.required]],
    fontFace: ["Helvetica"],
    scrollSpeed: [56],
    timedMin: [0, [Validators.min(0)]],
    timedSec: [0, [Validators.min(0)]],
  });

  gamepadForm: UntypedFormGroup = this.fb.group({
    playStop: ["aButton"],
    next: ["bButton"],
    previous: ["xButton"],
    reset: ["yButton"],
    decreaseScrollSpeed: ["lbButton"],
    increaseScrollSpeed: ["rbButton"],
    previousCuePoint: ["ltButton"],
    nextCuePoint: ["rtButton"],
    increaseFontSize: ["dpadUpButton"],
    decreaseFontSize: ["dpadDownButton"],
    increaseScrollSpeedBy10: ["dpadRightButton"],
    decreaseScrollSpeedBy10: ["dpadLeftButton"],
  });

  cueIndicatorForm: UntypedFormGroup = this.fb.group({
    leftArrow: [true],
    rightArrow: [false],
    centerLine: [false],
    arrowWidth: [16], //divided by 2 for border-l and border-r
    arrowLength: [17], //divided by 2 for border-t and border-b
    lineHeight: [12],
    layoutOpacity: [0.5],
    arrowPosition: [45],
    layoutColor: ["rgb(255,255,255)"],
    linePosition: [47],
  });

  keyboardForm: UntypedFormGroup = this.fb.group({
    playStop: ["Enter"],
    next: ["ArrowRight"],
    previous: ["ArrowLeft"],
    scrollUp: ["ArrowUp"],
    scrollDown: ["ArrowDown"],
    reset: ["r"],
    decreaseScrollSpeed: ["1"],
    increaseScrollSpeed: ["3"],
    previousCuePoint: ["5"],
    nextCuePoint: ["6"],
    increaseFontSize: ["="],
    decreaseFontSize: ["-"],
    increaseScrollSpeedBy10: ["4"],
    decreaseScrollSpeedBy10: ["2"],
  });

  get liveUpdating() {
    return this.liveUpdatingForm.get("enableLiveUpdating")?.value;
  }

  set liveUpdating(val) {
    this.liveUpdatingForm.get("enableLiveUpdating")?.setValue(val);
  }

  get fontColor() {
    return this.scriptOptionsForm.get("fontColor")?.value;
  }

  set fontColor(color) {
    this.scriptOptionsForm.get("fontColor")?.setValue(color);
  }

  get fontFace() {
    return this.scriptOptionsForm.get("fontFace")?.value;
  }

  set fontFace(val) {
    this.scriptOptionsForm.get("fontFace")?.setValue(val);
  }

  get backgroundColor() {
    return this.scriptOptionsForm.get("backgroundColor")?.value;
  }

  set backgroundColor(color) {
    this.scriptOptionsForm.get("backgroundColor")?.setValue(color);
  }

  get borderColor() {
    return this.scriptOptionsForm.get("borderColor")?.value;
  }

  set borderColor(color) {
    this.scriptOptionsForm.get("borderColor")?.setValue(color);
  }

  get fontSize() {
    return this.scriptOptionsForm.get("fontSize")?.value;
  }
  set fontSize(val: number) {
    this.scriptOptionsForm.get("fontSize")?.setValue(val);
  }

  get scrollSpeed() {
    return this.scriptOptionsForm.get("scrollSpeed")?.value;
  }

  set scrollSpeed(val) {
    this.scriptOptionsForm.get("scrollSpeed")?.setValue(val);
    // console.log(this.scrollSpeed);
  }

  get timedMin() {
    return this.scriptOptionsForm.get("timedMin")?.value;
  }

  set timedMin(val) {
    this.scriptOptionsForm.get("timedMin")?.setValue(val);
  }

  get timedSec() {
    return this.scriptOptionsForm.get("timedSec")?.value;
  }

  set timedSec(val) {
    this.scriptOptionsForm.get("timedSec")?.setValue(val);
  }

  get gpPlayStop() {
    return this.gamepadForm.get("playStop")?.value;
  }

  get gpNext() {
    return this.gamepadForm.get("next")?.value;
  }

  get gpPrevious() {
    return this.gamepadForm.get("previous")?.value;
  }

  get gpReset() {
    return this.gamepadForm.get("reset")?.value;
  }

  get gpDecreaseScrollSpeed() {
    return this.gamepadForm.get("decreaseScrollSpeed")?.value;
  }

  get gpIncreaseScrollSpeed() {
    return this.gamepadForm.get("increaseScrollSpeed")?.value;
  }

  get gpPreviousCuePoint() {
    return this.gamepadForm.get("previousCuePoint")?.value;
  }

  get gpNextCuePoint() {
    return this.gamepadForm.get("nextCuePoint")?.value;
  }

  get gpIncreaseFontSize() {
    return this.gamepadForm.get("increaseFontSize")?.value;
  }

  get gpDecreaseFontSize() {
    return this.gamepadForm.get("decreaseFontSize")?.value;
  }

  get gpIncreaseScrollSpeedBy10() {
    return this.gamepadForm.get("increaseScrollSpeedBy10")?.value;
  }

  get gpDecreaseScrollSpeedBy10() {
    return this.gamepadForm.get("decreaseScrollSpeedBy10")?.value;
  }

  set gpPlayStop(val: string) {
    this.gamepadForm.get("playStop")?.setValue(val);
  }

  set gpNext(val: string) {
    this.gamepadForm.get("next")?.setValue(val);
  }

  set gpPrevious(val: string) {
    this.gamepadForm.get("previous")?.setValue(val);
  }

  set gpReset(val: string) {
    this.gamepadForm.get("reset")?.setValue(val);
  }

  set gpDecreaseScrollSpeed(val: string) {
    this.gamepadForm.get("decreaseScrollSpeed")?.setValue(val);
  }

  set gpIncreaseScrollSpeed(val: string) {
    this.gamepadForm.get("increaseScrollSpeed")?.setValue(val);
  }

  set gpPreviousCuePoint(val: string) {
    this.gamepadForm.get("previousCuePoint")?.setValue(val);
  }

  set gpNextCuePoint(val: string) {
    this.gamepadForm.get("nextCuePoint")?.setValue(val);
  }

  set gpIncreaseFontSize(val: string) {
    this.gamepadForm.get("increaseFontSize")?.setValue(val);
  }

  set gpDecreaseFontSize(val: string) {
    this.gamepadForm.get("decreaseFontSize")?.setValue(val);
  }

  set gpIncreaseScrollSpeedBy10(val: string) {
    this.gamepadForm.get("increaseScrollSpeedBy10")?.setValue(val);
  }

  set gpDecreaseScrollSpeedBy10(val: string) {
    this.gamepadForm.get("decreaseScrollSpeedBy10")?.setValue(val);
  }

  get kbPlayStop() {
    return this.keyboardForm.get("playStop")?.value;
  }

  get kbNext() {
    return this.keyboardForm.get("next")?.value;
  }

  get kbPrevious() {
    return this.keyboardForm.get("previous")?.value;
  }

  get kbScrollUp() {
    return this.keyboardForm.get("scrollUp")?.value;
  }

  get kbScrollDown() {
    return this.keyboardForm.get("scrollDown")?.value;
  }

  get kbReset() {
    return this.keyboardForm.get("reset")?.value;
  }

  get kbDecreaseScrollSpeed() {
    return this.keyboardForm.get("decreaseScrollSpeed")?.value;
  }

  get kbIncreaseScrollSpeed() {
    return this.keyboardForm.get("increaseScrollSpeed")?.value;
  }

  get kbPreviousCuePoint() {
    return this.keyboardForm.get("previousCuePoint")?.value;
  }

  get kbNextCuePoint() {
    return this.keyboardForm.get("nextCuePoint")?.value;
  }

  get kbIncreaseFontSize() {
    return this.keyboardForm.get("increaseFontSize")?.value;
  }

  get kbDecreaseFontSize() {
    return this.keyboardForm.get("decreaseFontSize")?.value;
  }

  get kbIncreaseScrollSpeedBy10() {
    return this.keyboardForm.get("increaseScrollSpeedBy10")?.value;
  }

  get kbDecreaseScrollSpeedBy10() {
    return this.keyboardForm.get("decreaseScrollSpeedBy10")?.value;
  }

  set kbPlayStop(val: string) {
    this.keyboardForm.get("playStop")?.setValue(val);
  }

  set kbNext(val: string) {
    this.keyboardForm.get("next")?.setValue(val);
  }

  set kbScrollUp(val: string) {
    this.keyboardForm.get("scrollUp")?.setValue(val);
  }

  set kbScrollDown(val: string) {
    this.keyboardForm.get("scrollDown")?.setValue(val);
  }

  set kbPrevious(val: string) {
    this.keyboardForm.get("previous")?.setValue(val);
  }

  set kbReset(val: string) {
    this.keyboardForm.get("reset")?.setValue(val);
  }

  set kbDecreaseScrollSpeed(val: string) {
    this.keyboardForm.get("decreaseScrollSpeed")?.setValue(val);
  }

  set kbIncreaseScrollSpeed(val: string) {
    this.keyboardForm.get("increaseScrollSpeed")?.setValue(val);
  }

  set kbPreviousCuePoint(val: string) {
    this.keyboardForm.get("previousCuePoint")?.setValue(val);
  }

  set kbNextCuePoint(val: string) {
    this.keyboardForm.get("nextCuePoint")?.setValue(val);
  }

  set kbIncreaseFontSize(val: string) {
    this.keyboardForm.get("increaseFontSize")?.setValue(val);
  }

  set kbDecreaseFontSize(val: string) {
    this.keyboardForm.get("decreaseFontSize")?.setValue(val);
  }

  set kbIncreaseScrollSpeedBy10(val: string) {
    this.keyboardForm.get("increaseScrollSpeedBy10")?.setValue(val);
  }

  set kbDecreaseScrollSpeedBy10(val: string) {
    this.keyboardForm.get("decreaseScrollSpeedBy10")?.setValue(val);
  }

  get leftArrow() {
    return this.cueIndicatorForm.get("leftArrow")?.value;
  }

  set leftArrow(val: boolean) {
    this.cueIndicatorForm.get("leftArrow")?.setValue(val);
  }

  get rightArrow() {
    return this.cueIndicatorForm.get("rightArrow")?.value;
  }

  set rightArrow(val: boolean) {
    this.cueIndicatorForm.get("rightArrow")?.setValue(val);
  }

  get centerLine() {
    return this.cueIndicatorForm.get("centerLine")?.value;
  }

  set centerLine(val: boolean) {
    this.cueIndicatorForm.get("centerLine")?.setValue(val);
  }

  get arrowWidth() {
    return this.cueIndicatorForm.get("arrowWidth")?.value;
  }

  set arrowWidth(val: number) {
    this.cueIndicatorForm.get("arrowWidth")?.setValue(val);
  }

  get arrowLength() {
    return this.cueIndicatorForm.get("arrowLength")?.value;
  }

  set arrowLength(val: number) {
    this.cueIndicatorForm.get("arrowLength")?.setValue(val);
  }

  get lineHeight() {
    return this.cueIndicatorForm.get("lineHeight")?.value;
  }

  set lineHeight(val: number) {
    this.cueIndicatorForm.get("lineHeight")?.setValue(val);
  }

  get layoutOpacity() {
    return this.cueIndicatorForm.get("layoutOpacity")?.value;
  }

  set layoutOpacity(val: number) {
    this.cueIndicatorForm.get("layoutOpacity")?.setValue(val);
  }

  get arrowPosition() {
    return this.cueIndicatorForm.get("arrowPosition")?.value;
  }
  set arrowPosition(val: number) {
    this.cueIndicatorForm.get("arrowPosition")?.setValue(val);
  }

  get layoutColor() {
    return this.cueIndicatorForm.get("layoutColor")?.value;
  }

  set layoutColor(val) {
    this.cueIndicatorForm.get("layoutColor")?.setValue(val);
  }

  globalOptionsForm: UntypedFormGroup = this.fb.group({
    sideMargins: [0, [Validators.max(50)]],
    prompterHeight: [0],
    prompterWidth: [0],
    spacerHeight: [0],
    gamepadControl: [false],
    keyboardControl: [false],
    cueIndicator: [true],
    scrollMode: ["continuous"],
    tapToScroll: ["false"],
    countdownTimer: [true],
    countdownLength: [3, [Validators.max(30)]],
    showCountdown: [true],
    mirrorVertical: [false],
    mirrorHorizontal: [false],
    timedScrolling: [false],
    remainingTimer: [true],
  });

  get remainingTimer() {
    return this.globalOptionsForm.get("remainingTimer")?.value;
  }

  set remainingTimer(val) {
    this.globalOptionsForm.get("remainingTimer")?.setValue(val);
  }

  get sideMargins() {
    return this.globalOptionsForm.get("sideMargins")?.value;
  }

  set sideMargins(val) {
    this.globalOptionsForm.get("sideMargins")?.setValue(val);
  }

  get prompterWidth() {
    return this.globalOptionsForm.get("prompterWidth")?.value;
  }

  set prompterWidth(val) {
    this.globalOptionsForm.get("prompterWidth")?.setValue(val);
  }

  get prompterHeight() {
    return this.globalOptionsForm.get("prompterWidth")?.value;
  }

  set prompterHeight(val) {
    this.globalOptionsForm.get("prompterHeight")?.setValue(val);
  }

  get countdownTimer() {
    return this.globalOptionsForm.get("countdownTimer")?.value;
  }

  set countdownTimer(val) {
    this.globalOptionsForm.get("countdownTimer")?.setValue(val);
    this.globalOptionsForm.get("showCountdown")?.setValue(val);
  }

  get tapToScroll() {
    return this.globalOptionsForm.get("tapToScroll")?.value;
  }

  set tapToScroll(val) {
    this.globalOptionsForm.get("tapToScroll")?.setValue(val);
  }

  get timedScrolling() {
    return this.globalOptionsForm.get("timedScrolling")?.value;
  }

  set timedScrolling(val) {
    this.globalOptionsForm.get("timedScrolling")?.setValue(val);
  }
  get scrollMode() {
    return this.globalOptionsForm.get("scrollMode")?.value;
  }

  set scrollMode(val) {
    this.globalOptionsForm.get("scrollMode")?.setValue(val);
  }

  get mirrorHorizontal() {
    return this.globalOptionsForm.get("mirrorHorizontal")?.value;
  }

  set mirrorHorizontal(val) {
    this.globalOptionsForm.get("mirrorHorizontal")?.setValue(val);
  }

  get mirrorVertical() {
    return this.globalOptionsForm.get("mirrorVertical")?.value;
  }

  set mirrorVertical(val) {
    this.globalOptionsForm.get("mirrorVertical")?.setValue(val);
  }

  get keyboardControl() {
    return this.globalOptionsForm.get("keyboardControl")?.value;
  }

  set keyboardControl(val) {
    this.globalOptionsForm.get("keyboardControl")?.setValue(val);
  }

  get gamepadControl() {
    return this.globalOptionsForm.get("gamepadControl")?.value;
  }

  set gamepadControl(val) {
    this.globalOptionsForm.get("gamepadControl")?.setValue(val);
  }

  get cueIndicator() {
    return this.globalOptionsForm.get("cueIndicator")?.value;
  }

  set cueIndicator(val) {
    this.globalOptionsForm.get("cueIndicator")?.setValue(val);
  }

  set countdown(val: number) {
    this.globalOptionsForm.get("countdownLength")?.setValue(val);
  }

  get countdown() {
    return this.globalOptionsForm.get("countdownLength")?.value;
  }

  get countdownLength() {
    return this.globalOptionsForm.get("countdownLength");
  }

  ngOnInit(): void {
    this.parseScriptOptions();
    //subscribe to global scriptOptions
    this.subscriptions.add(
      this.prompterService.globalSettings$
        .pipe(take(1))
        .subscribe((options: IGlobalPrompterOptions) => {
          Object.assign(this.globalOptions, options);
          if (this.globalOptions.prompterHeight === 0) {
            this.globalOptions.prompterHeight = this.data.maxHeight;
          }
          if (this.globalOptions.prompterWidth === 0) {
            this.globalOptions.prompterWidth = this.data.maxWidth;
          }
          this.globalOptionsForm.setValue(this.globalOptions);
          // this.isTimedScrollingChecked();
        })
    );

    this.subscriptions.add(
      this.prompterService.controlMapping$
        .pipe(take(1))
        .subscribe((mapping) => {
          this.controlMapping = mapping;

          this.setControlMappingsForm(mapping);
        })
    );
    this.subscriptions.add(
      this.prompterService.cueIndicatorOptions$
        .pipe(take(1))
        .subscribe((options) => {
          this.cueIndicatorOptions = options;

          this.cueIndicatorForm.patchValue(this.cueIndicatorOptions);
        })
    );

    this.subscriptions.add(
      this.subscriptionService.entitlements$
        .pipe(take(1))
        .subscribe((entitlements) => {
          this.entitlements = entitlements;
          if (this.entitlements?.cloudPrompter === true) {
            this.hasPremiumSub = true;
            this.hasCloudPrompterSub = true;
          }

          if (entitlements?.premiumSubscription === true) {
            this.hasPremiumSub = true;
          } else if (
            entitlements?.premiumSubscription === false &&
            entitlements?.cloudPrompter === false
          ) {
            this.hasPremiumSub = false;
          }
        })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  setControlMappingsForm(mappings: IControlMaps) {
    this.gpPlayStop = mappings.playStop.gamepad;
    this.kbPlayStop = mappings.playStop.keyboard;

    this.gpNext = mappings.next.gamepad;
    this.kbNext = mappings.next.keyboard;

    this.gpPrevious = mappings.previous.gamepad;
    this.kbPrevious = mappings.previous.keyboard;

    this.kbScrollUp = mappings.scrollUp.keyboard;

    this.kbScrollDown = mappings.scrollDown.keyboard;

    this.gpReset = mappings.reset.gamepad;
    this.kbReset = mappings.reset.keyboard;

    this.gpDecreaseScrollSpeed = mappings.decreaseScrollSpeed.gamepad;
    this.kbDecreaseScrollSpeed = mappings.decreaseScrollSpeed.keyboard;

    this.gpIncreaseScrollSpeed = mappings.increaseScrollSpeed.gamepad;
    this.kbIncreaseScrollSpeed = mappings.increaseScrollSpeed.keyboard;

    this.gpPreviousCuePoint = mappings.previousCuePoint.gamepad;
    this.kbPreviousCuePoint = mappings.previousCuePoint.keyboard;

    this.gpNextCuePoint = mappings.nextCuePoint.gamepad;
    this.kbNextCuePoint = mappings.nextCuePoint.keyboard;

    this.gpIncreaseFontSize = mappings.increaseFontSize.gamepad;
    this.kbIncreaseFontSize = mappings.increaseFontSize.keyboard;

    this.gpDecreaseFontSize = mappings.decreaseFontSize.gamepad;
    this.kbDecreaseFontSize = mappings.decreaseFontSize.keyboard;

    this.gpIncreaseScrollSpeedBy10 = mappings.increaseScrollSpeedBy10.gamepad;
    this.kbIncreaseScrollSpeedBy10 = mappings.increaseScrollSpeedBy10.keyboard;

    this.gpDecreaseScrollSpeedBy10 = mappings.decreaseScrollSpeedBy10.gamepad;
    this.kbDecreaseScrollSpeedBy10 = mappings.decreaseScrollSpeedBy10.keyboard;
  }

  setControlMappings() {
    this.controlMapping.playStop.gamepad = this.gpPlayStop;
    this.controlMapping.playStop.keyboard = this.kbPlayStop;

    this.controlMapping.next.gamepad = this.gpNext;
    this.controlMapping.next.keyboard = this.kbNext;

    this.controlMapping.previous.gamepad = this.gpPrevious;
    this.controlMapping.previous.keyboard = this.kbPrevious;

    this.controlMapping.scrollUp.keyboard = this.kbScrollUp;

    this.controlMapping.scrollDown.keyboard = this.kbScrollDown;

    this.controlMapping.reset.gamepad = this.gpReset;
    this.controlMapping.reset.keyboard = this.kbReset;

    this.controlMapping.reset.gamepad = this.gpReset;
    this.controlMapping.reset.keyboard = this.kbReset;

    this.controlMapping.decreaseScrollSpeed.gamepad =
      this.gpDecreaseScrollSpeed;
    this.controlMapping.decreaseScrollSpeed.keyboard =
      this.kbDecreaseScrollSpeed;

    this.controlMapping.increaseScrollSpeed.gamepad =
      this.gpIncreaseScrollSpeed;
    this.controlMapping.increaseScrollSpeed.keyboard =
      this.kbIncreaseScrollSpeed;

    this.controlMapping.previousCuePoint.gamepad = this.gpPreviousCuePoint;
    this.controlMapping.previousCuePoint.keyboard = this.kbPreviousCuePoint;

    this.controlMapping.nextCuePoint.gamepad = this.gpNextCuePoint;
    this.controlMapping.nextCuePoint.keyboard = this.kbNextCuePoint;

    this.controlMapping.increaseFontSize.gamepad = this.gpIncreaseFontSize;
    this.controlMapping.increaseFontSize.keyboard = this.kbIncreaseFontSize;

    this.controlMapping.decreaseFontSize.gamepad = this.gpDecreaseFontSize;
    this.controlMapping.decreaseFontSize.keyboard = this.kbDecreaseFontSize;

    this.controlMapping.increaseScrollSpeedBy10.gamepad =
      this.gpIncreaseScrollSpeedBy10;
    this.controlMapping.increaseScrollSpeedBy10.keyboard =
      this.kbIncreaseScrollSpeedBy10;

    this.controlMapping.decreaseScrollSpeedBy10.gamepad =
      this.gpDecreaseScrollSpeedBy10;
    this.controlMapping.decreaseScrollSpeedBy10.keyboard =
      this.kbDecreaseScrollSpeedBy10;
  }

  checkDuplicateMapping() {
    if (this.gamepadControl) {
      const gamepadValues = Object.values(this.gamepadForm.value);
      if (this.hasDuplicates(gamepadValues)) {
        this.mappingDuplicate = true;
        // Handle the presence of duplicates here, e.g., show a warning message to the user
        const element = document.getElementById("gamepadError");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      } else {
        this.mappingDuplicate = false;
      }
    } else {
      this.mappingDuplicate = false;
    }
  }

  hasDuplicates(array: any[]): boolean {
    return new Set(array).size !== array.length;
  }

  setKeyboardButton(buttonValue: KeyboardEvent, index: number) {
    const defaultButtons = [
      "playStop",
      "next",
      "previous",
      "scrollUp",
      "scrollDown",
      "reset",
      "decreaseScrollSpeed",
      "decreaseScrollSpeedBy10",
      "increaseScrollSpeed",
      "increaseScrollSpeedBy10",
      "previousCuePoint",
      "nextCuePoint",
      "increaseFontSize",
      "decreaseFontSize",
    ];

    const formControl = this.keyboardForm.get(defaultButtons[index]);
    formControl?.setValue(buttonValue.key);
  }

  async checkKeyboardDuplicateMapping(buttonValue: Object): Promise<boolean> {
    if (this.keyboardControl) {
      const buttons = [
        this.kbPlayStop,
        this.kbNext,
        this.kbPrevious,
        this.kbScrollUp,
        this.kbScrollDown,
        this.kbReset,
        this.kbDecreaseScrollSpeed,
        this.kbIncreaseScrollSpeed,
        this.kbPreviousCuePoint,
        this.kbNextCuePoint,
        this.kbIncreaseFontSize,
        this.kbDecreaseFontSize,
        this.kbIncreaseScrollSpeedBy10,
        this.kbDecreaseScrollSpeedBy10,
      ];

      const defaultButtons = [
        "playStop",
        "next",
        "previous",
        "scrollUp",
        "scrollDown",
        "reset",
        "decreaseScrollSpeed",
        "increaseScrollSpeed",
        "previousCuePoint",
        "nextCuePoint",
        "increaseFontSize",
        "decreaseFontSize",
        "increaseScrollSpeedBy10",
        "decreaseScrollSpeedBy10",
      ];

      let bValues = Object.values(buttonValue);

      const promises = bValues.map(async (value, index) => {
        const val = buttons[index];
        buttons.splice(index, 1);

        if (buttons.some((button) => button === value)) {
          const element = document.getElementById("keyboardError");
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
          this.kbMappingDuplicate = true;
          buttons.splice(index, 0, val);
          return true;
        } else {
          buttons.splice(index, 0, val);
          return false;
        }
      });

      const results = await Promise.all(promises);
      return results.some((result) => result);
    } else {
      return false;
    }
  }

  async onSubmit() {
    let keyboardDupeControl = await this.checkKeyboardDuplicateMapping(
      this.keyboardForm.value
    );

    if (
      Number(this.arrowPosition) >
      Number(this.data.maxHeight) - Number(this.arrowLength)
    ) {
      this.arrowPosition =
        Number(this.data.maxHeight) - Number(this.arrowLength);
    }

    if (this.liveUpdating === null) {
      this.liveUpdating = false;
    }

    if (!keyboardDupeControl) {
      this.setControlMappings();
      this.dialogRef.close({
        globalOptions: this.globalOptionsForm.value,
        scriptOptions: this.scriptOptionsForm.value,
        controlMapping: this.controlMapping,
        keyboardMapping: this.keyboardForm.value,
        cueIndicatorOptions: this.cueIndicatorForm.value,
        enable_liveUpdating: this.liveUpdating,
      });
    }
  }

  onResetDefaults() {
    this.gpPlayStop = defaultControlMapping.playStop.gamepad;

    this.gpNext = defaultControlMapping.next.gamepad;

    this.gpPrevious = defaultControlMapping.previous.gamepad;

    this.gpReset = defaultControlMapping.reset.gamepad;

    this.gpDecreaseScrollSpeed =
      defaultControlMapping.decreaseScrollSpeed.gamepad;

    this.gpIncreaseScrollSpeed =
      defaultControlMapping.increaseScrollSpeed.gamepad;

    this.gpPreviousCuePoint = defaultControlMapping.previousCuePoint.gamepad;

    this.gpNextCuePoint = defaultControlMapping.nextCuePoint.gamepad;

    this.gpIncreaseFontSize = defaultControlMapping.increaseFontSize.gamepad;

    this.gpDecreaseFontSize = defaultControlMapping.decreaseFontSize.gamepad;

    this.gpIncreaseScrollSpeedBy10 =
      defaultControlMapping.increaseScrollSpeedBy10.gamepad;

    this.gpDecreaseScrollSpeedBy10 =
      defaultControlMapping.decreaseScrollSpeedBy10.gamepad;

    this.mappingDuplicate = false;
  }

  onResetKeyboardDefaults() {
    this.kbPlayStop = defaultControlMapping.playStop.keyboard;

    this.kbNext = defaultControlMapping.next.keyboard;

    this.kbPrevious = defaultControlMapping.previous.keyboard;

    this.kbScrollUp = defaultControlMapping.scrollUp.keyboard;

    this.kbScrollDown = defaultControlMapping.scrollDown.keyboard;

    this.kbReset = defaultControlMapping.reset.keyboard;

    this.kbDecreaseScrollSpeed =
      defaultControlMapping.decreaseScrollSpeed.keyboard;

    this.kbIncreaseScrollSpeed =
      defaultControlMapping.increaseScrollSpeed.keyboard;

    this.kbPreviousCuePoint = defaultControlMapping.previousCuePoint.keyboard;

    this.kbNextCuePoint = defaultControlMapping.nextCuePoint.keyboard;

    this.kbIncreaseFontSize = defaultControlMapping.increaseFontSize.keyboard;

    this.kbDecreaseFontSize = defaultControlMapping.decreaseFontSize.keyboard;

    this.kbIncreaseScrollSpeedBy10 =
      defaultControlMapping.increaseScrollSpeedBy10.keyboard;

    this.kbDecreaseScrollSpeedBy10 =
      defaultControlMapping.decreaseScrollSpeedBy10.keyboard;

    this.kbMappingDuplicate = false;
  }

  onResetCueIndicatorDefaults() {
    let cueIndicatorOptions: IScriptCueIndicatorOptions =
      defaultScriptCueIndicatorOptions;

    this.cueIndicatorForm.patchValue(cueIndicatorOptions);
    this.prompterService.setCueIndicatorOptions(cueIndicatorOptions);
  }

  parseScriptOptions() {
    Object.keys(this.data.scriptOptions)
      .filter((key: string) => key in this.data.scriptOptions)
      .forEach((key: string) => {
        this.scriptOptions[key] = this.data.scriptOptions[key];
      });

    this.fontSize = parseInt(this.scriptOptions.fontSize);
    this.scrollSpeed = parseInt(this.scriptOptions.scrollSpeed);
    this.fontColor = this.scriptOptions.fontColor;
    this.borderColor = this.scriptOptions.borderColor;
    this.scrollSpeed = parseInt(this.scriptOptions.scrollSpeed);
    this.fontFace = this.scriptOptions.fontFace;

    if (this.fontFace === "HelveticaNeue") {
      this.fontFace = "Helvetica Neue";
    }
    this.parseTimedSeconds();
    this.parseBackgroundColor();
    // console.log(this.scriptOptions);
  }

  onResize(resize: Resize) {
    let height: number = 0;
    let width: number = 0;
    switch (resize) {
      case "fit":
        width = this.data.maxWidth;
        height = this.data.maxHeight;
        break;
      case "phone":
        width = 660;
        height = 360;
        break;
      case "ipad":
        width = 1024;
        height = 768;
        break;
      case "laptop":
        width = 1680;
        height = 815;
        break;
    }
    this.prompterWidth = width;
    this.prompterHeight = height;
  }

  onOpenHintDialog(hintMessageKey: HintMessageKey) {
    let message = this.hintMessageMap[hintMessageKey];
    this.dialogService.open(HintDialogComponent, {
      data: {
        message: message,
      },
    });
  }

  onOpenGamepadDialog() {
    this.dialogService.open(GamepadDialogComponent);
  }

  onOpenKeyboardDialog() {
    this.dialogService.open(KeyboardDialogComponent);
  }

  onOpenGoProDialog() {
    if (!this.hasPremiumSub) {
      this.dialogService.open(GoProDialogComponent, {
        height: "80%",
        width: "80%",
        data: {
          tierName: "Premium",
        },
      });
    } else if (!this.hasCloudPrompterSub) {
      this.dialogService.open(GoProDialogComponent, {
        height: "80%",
        width: "80%",
        data: {
          tierName: "Studio",
        },
      });
    }
  }

  parseBackgroundColor() {
    if (this.scriptOptions) {
      let r = Math.round(this.scriptOptions.backgroundRed * 255);
      let g = Math.round(this.scriptOptions.backgroundGreen * 255);
      let b = Math.round(this.scriptOptions.backgroundBlue * 255);
      let color = `rgb(${r},${g},${b})`;
      this.backgroundColor = color;
    }
  }

  parseTimedSeconds() {
    if (this.scriptOptions) {
      let { timedScrollingSeconds } = this.scriptOptions;
      if (timedScrollingSeconds < 60) {
        this.timedMin = 0;
        this.timedSec = timedScrollingSeconds;
        return;
      }
      let m = Math.floor(timedScrollingSeconds / 60);
      this.timedSec = timedScrollingSeconds - m * 60;
      this.timedMin = m;
    }
  }

  countdownLengthIncrease() {
    this.globalOptionsForm.patchValue({
      countdownLength: this.globalOptionsForm.get("countdownLength")?.value + 1,
    });
  }

  countdownLengthDecrease() {
    this.globalOptionsForm.patchValue({
      countdownLength: this.globalOptionsForm.get("countdownLength")?.value - 1,
    });
  }

  timedSecIncrease() {
    this.scriptOptionsForm.patchValue({
      timedSec: this.scriptOptionsForm.get("timedSec")?.value + 1,
    });
  }

  timedSecDecrease() {
    this.scriptOptionsForm.patchValue({
      timedSec: this.scriptOptionsForm.get("timedSec")?.value - 1,
    });
  }

  timedMinIncrease() {
    this.scriptOptionsForm.patchValue({
      timedMin: this.scriptOptionsForm.get("timedMin")?.value + 1,
    });
  }

  timedMinDecrease() {
    this.scriptOptionsForm.patchValue({
      timedMin: this.scriptOptionsForm.get("timedMin")?.value - 1,
    });
  }

  fontSizeIncrease() {
    this.scriptOptionsForm.patchValue({
      fontSize: this.scriptOptionsForm.get("fontSize")?.value + 1,
    });
  }

  fontSizeDecrease() {
    this.scriptOptionsForm.patchValue({
      fontSize: this.scriptOptionsForm.get("fontSize")?.value - 1,
    });
  }
  onChangeSpeed(event: MatSliderChange) {
    if (event.value) {
      this.scrollSpeed = event.value;
    }
  }

  onChangeArrowWidth(event: MatSliderChange) {
    if (event.value) {
      this.arrowWidth = event.value;
    }
  }

  onChangeArrowLength(event: MatSliderChange) {
    if (event.value) {
      this.arrowLength = event.value;
    }
  }

  onChangeLineHeight(event: MatSliderChange) {
    if (event.value) {
      this.lineHeight = event.value;
    }
  }

  onChangeLayoutOpacity(event: MatSliderChange) {
    if (event.value) {
      this.layoutOpacity = event.value;
      var rgb = this.layoutColor.match(/\d+/g);
      this.layoutColor =
        "rgba(" +
        rgb?.[0] +
        "," +
        rgb?.[1] +
        "," +
        rgb?.[2] +
        "," +
        this.layoutOpacity;
    }
  }

  onChangeArrowPosition(event: MatSliderChange) {
    if (event.value) {
      this.arrowPosition = event.value;
    }
  }

  onChangeLayoutColor(value: string) {
    var rgb = value.match(/\d+/g);
    this.layoutColor =
      "rgba(" +
      rgb?.[0] +
      "," +
      rgb?.[1] +
      "," +
      rgb?.[2] +
      "," +
      this.layoutOpacity;
  }

  onChangeSideMargins(event: MatSliderChange) {
    this.sideMargins = event.value;
  }
  isTimedScrollingChecked() {
    // console.log(this.globalOptionsForm.get("timedScrolling")?.value);
    if (this.globalOptionsForm.get("timedScrolling")?.value == true) {
      this.scriptOptionsForm.get("timedMin")?.enable();
      this.scriptOptionsForm.get("timedSec")?.enable();
    } else {
      // this.scriptOptionsForm.controls["timedMin"].disable();
      // this.scriptOptionsForm.controls["timedSec"].disable();
    }
  }

  tapToScrollChange() {
    if (this.tapToScroll) {
      this.tapToScroll = false;
    } else {
      this.tapToScroll = true;
    }
  }

  timedScrollingChange() {
    if (this.timedScrolling) {
      this.timedScrolling = false;
    } else {
      this.timedScrolling = true;
    }
  }

  remainingTimerChange() {
    if (this.remainingTimer) {
      this.remainingTimer = false;
    } else {
      this.remainingTimer = true;
    }
  }

  scrollModeChange(state: string) {
    this.scrollMode = state;
  }

  mirrorHorizontalChange() {
    if (this.mirrorHorizontal) {
      this.mirrorHorizontal = false;
    } else {
      this.mirrorHorizontal = true;
    }
  }

  mirrorVerticalChange() {
    if (this.mirrorVertical) {
      this.mirrorVertical = false;
    } else {
      this.mirrorVertical = true;
    }
  }

  keyboardControlChange() {
    if (this.keyboardControl) {
      this.keyboardControl = false;
    } else {
      this.keyboardControl = true;
    }
  }

  cueIndicatorChange() {
    if (this.cueIndicator) {
      this.cueIndicator = false;
    } else {
      this.cueIndicator = true;
    }
  }

  gamepadControlChange() {
    if (this.gamepadControl) {
      this.gamepadControl = false;
      this.checkDuplicateMapping();
    } else {
      this.gamepadControl = true;
      this.checkDuplicateMapping();
    }
  }

  liveUpdatingChange() {
    if (this.liveUpdating) {
      this.liveUpdating = false;
    } else {
      this.liveUpdating = true;
    }
  }

  countdownTimerChange() {
    if (this.countdownTimer) {
      this.countdownTimer = false;
    } else {
      this.countdownTimer = true;
    }
  }

  leftArrowChange() {
    if (this.leftArrow) {
      this.leftArrow = false;
    } else {
      this.leftArrow = true;
    }
  }

  rightArrowChange() {
    if (this.rightArrow) {
      this.rightArrow = false;
    } else {
      this.rightArrow = true;
    }
  }

  centerLineChange() {
    if (this.centerLine) {
      this.centerLine = false;
    } else {
      this.centerLine = true;
    }
  }
}
