<h1 mat-dialog-title align="center">Rename {{ data.folderName }}</h1>
<mat-dialog-content>
  <form [formGroup]="renameFolderForm" (ngSubmit)="onSubmit()">
    <label for="folderName" class="block text-sm font-medium text-gray-700"
      >Folder Name</label
    >

    <div class="mt-1 flex rounded-md shadow-sm">
      <input
        type="text"
        formControlName="folderName"
        name="folderName"
        id="folderName"
        class="invalid:border-red-500 block w-full min-w-0 flex-grow rounded-md border-black focus:border-teleprompterBlue focus:ring-teleprompterBlue sm:text-sm"
      />
    </div>

    <div
      *ngIf="name?.invalid && (name?.dirty || name?.touched)"
      class="rounded-md bg-red-50 p-3 mt-2"
    >
      <div class="text-xs font-medium text-red-800 text-center">
        &uarr; Please enter a valid folder name.
      </div>
    </div>

    <button
      [disabled]="!renameFolderForm.valid"
      type="submit"
      class="mt-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue hover:bg-lightBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teleprompterBlue disabled:bg-gray-600 disabled:text-gray-400"
    >
      Rename Folder
    </button>
  </form>
</mat-dialog-content>
