import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireObject,
} from "@angular/fire/compat/database";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { AppError } from "../error/appError";
import {
  IControlMaps,
  defaultControlMapping,
  defaultGlobalOptions,
  defaultScriptCueIndicatorOptions,
  IGlobalPrompterOptions,
  IScriptCueIndicatorOptions,
} from "./prompterITC";

@Injectable({
  providedIn: "root",
})
export class PrompterService implements OnDestroy {
  /** string used as key to save globalSettings in local storage */
  private _key = "globalSettings";
  private _uid = "";
  private _globalSettings: string | null = "";

  private _dbCueInidcatorListRef!: AngularFireObject<IScriptCueIndicatorOptions>;
  private _cueIndicatorNodePath = "";

  private subscription: Subscription = new Subscription();
  private _globalOptions: IGlobalPrompterOptions = defaultGlobalOptions;
  /** behaviour subject used to emit values to subscribers of {@link this._globalOptions} */
  private _globalOptions$: BehaviorSubject<IGlobalPrompterOptions> =
    new BehaviorSubject(this._globalOptions);
  /** Observable emits {@link IGlobalPrompterOptions} */
  globalSettings$: Observable<IGlobalPrompterOptions> =
    this._globalOptions$.asObservable();

  private _controlMapping: IControlMaps = defaultControlMapping;
  /** behaviour subject used to emit values to subscribers of {@link this._controlMapping} */
  private _controlMapping$: BehaviorSubject<IControlMaps> = new BehaviorSubject(
    this._controlMapping
  );
  /** Observable emits {@link IControlMaps} */
  controlMapping$: Observable<IControlMaps> =
    this._controlMapping$.asObservable();

  private _controlMappingSub = new Subscription();

  private _cueIndicatorOptions: IScriptCueIndicatorOptions =
    defaultScriptCueIndicatorOptions;
  /** behaviour subject used to emit values to subscribers of {@link this._cueIndicatorOptions} */
  private _cueIndicatorOptions$: BehaviorSubject<IScriptCueIndicatorOptions> =
    new BehaviorSubject(this._cueIndicatorOptions);

  /** Observable emits {@link IScriptCueIndicatorOptions} */
  cueIndicatorOptions$: Observable<IScriptCueIndicatorOptions> =
    this._cueIndicatorOptions$.asObservable();

  private _cueIndicatorOptionsSub = new Subscription();

  constructor(
    private authService: AuthService,
    private db: AngularFireDatabase,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.getKey();
  }

  /**
   * Saves the supplied {@IGlobalPrompterOptions} param to local storage, and emits value to subscribers of {@link _globalOptions$}
   * @param options {@link IGlobalPrompterOptions}
   */
  setGlobalOptions(options: IGlobalPrompterOptions) {
    this._globalOptions = options;
    this._globalOptions$.next(this._globalOptions);
    localStorage.setItem(this._key, JSON.stringify(this._globalOptions));
  }

  async setControlMapping(options: IControlMaps): Promise<void | AppError> {
    this._controlMapping = options;
    this._controlMapping$.next(this._controlMapping);

    const ref = this.db.database.ref("controlMaps/" + this._uid);
    await ref.update(options);
  }

  async setCueIndicatorOptions(
    options: IScriptCueIndicatorOptions
  ): Promise<void | AppError> {
    this._cueIndicatorOptions = options;
    this._cueIndicatorOptions$.next(this._cueIndicatorOptions);

    const ref = this.db.database.ref("cueIndicators/" + this._uid);
    await ref.update(options);
  }

  getControlMappings() {
    this._controlMappingSub = this.db
      .object<IControlMaps>("controlMaps/" + this._uid)
      .valueChanges()
      .subscribe((mappings) => {
        if (mappings === null) {
          this._controlMapping = defaultControlMapping;
          this._controlMapping$.next(this._controlMapping);
          this.setControlMapping(this._controlMapping);
        } else {
          if (!mappings.scrollDown) {
            mappings.scrollDown = {
              keyboard: "ArrowDown",
              gamepad: "",
            };
          }
          if (!mappings.scrollUp) {
            mappings.scrollUp = {
              keyboard: "ArrowUp",
              gamepad: "",
            };
          }
          this._controlMapping = mappings!;
          this.setControlMapping(this._controlMapping);
        }
      });
  }

  getCueIndicatorOptions() {
    this._cueIndicatorNodePath = "cueIndicators/" + this._uid;
    this._dbCueInidcatorListRef = this.db.object<IScriptCueIndicatorOptions>(
      this._cueIndicatorNodePath
    );
    this._cueIndicatorOptionsSub = this._dbCueInidcatorListRef
      .valueChanges()
      .subscribe((options) => {
        if (options === null) {
          this._cueIndicatorOptions = {
            leftArrow: true,
            rightArrow: false,
            centerLine: false,
            arrowWidth: 16,
            arrowLength: 17,
            lineHeight: 12,
            layoutOpacity: 0.5,
            arrowPosition: 45,
            layoutColor: "rgb(255,255,255)",
            linePosition: 47,
          };
          this._cueIndicatorOptions$.next(this._cueIndicatorOptions);
          this.setCueIndicatorOptions(this._cueIndicatorOptions);
        } else {
          this._cueIndicatorOptions = options;
          this._cueIndicatorOptions$.next(options);
        }
      });
  }
  getGlobalOptions() {
    //retrieves IGlobalPrompterOptions from local storage
    this._globalSettings = localStorage.getItem(this._key);
    if (this._globalSettings) {
      this._globalOptions = JSON.parse(this._globalSettings!);
      this._globalOptions$.next(this._globalOptions);
    } else {
      //If no IGlobalPrompterOptions is found, save the defaultGlobalOptions
      localStorage.setItem(
        this._key,
        JSON.stringify({
          sideMargins: 0,
          prompterWidth: 0,
          prompterHeight: 0,
          spacerHeight: 0,
          keyboardControl: false,
          gamepadControl: false,
          cueIndicator: true,
          countdownTimer: true,
          countdownLength: 3,
          mirrorHorizontal: false,
          mirrorVertical: false,
          scrollMode: "continuous",
          tapToScroll: false,
          timedScrolling: false,
          remainingTimer: true,
        })
      );
      this._globalOptions$.next({
        sideMargins: 0,
        prompterWidth: 0,
        prompterHeight: 0,
        spacerHeight: 0,
        keyboardControl: false,
        gamepadControl: false,
        cueIndicator: true,
        countdownTimer: true,
        countdownLength: 3,
        showCountdown: true,
        mirrorHorizontal: false,
        mirrorVertical: false,
        scrollMode: "continuous",
        tapToScroll: false,
        timedScrolling: false,
        remainingTimer: true,
      });
    }
  }

  getKey() {
    this.subscription.add(
      this.authService.authUser$.subscribe((authUser) => {
        if (authUser) {
          this._uid = authUser.uid;
          this._key = "globalSettings_" + this._uid;
          this.getGlobalOptions();
          this.getControlMappings();
          this.getCueIndicatorOptions();
        }
      })
    );
  }

  deleteGlobalOptionsLocalStorage() {
    this.subscription.unsubscribe();
    this._controlMappingSub.unsubscribe();
  }

  ngOnDestroy(): void {
    this._controlMappingSub.unsubscribe();
    this._cueIndicatorOptionsSub.unsubscribe();
    this.subscription.unsubscribe();
  }
}
