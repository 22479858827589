import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ScriptService } from "src/app/scripts/script.service";
import { Folder } from "src/app/scripts/foldersITC";

@Component({
  selector: "app-rename-folder-dialog",
  templateUrl: "./rename-folder-dialog.component.html",
  styleUrls: ["./rename-folder-dialog.component.css"],
})
export class RenameFolderDialogComponent {
  renameFolderForm = this.fb.group({
    folderName: ["", [Validators.required]],
  });
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      folderName: string;
      folderId: string;
      arrangeOrder: number;
      createdAt: string;
      scripts: any;
      updatedAt: string;
    },
    private scriptsService: ScriptService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<RenameFolderDialogComponent>
  ) {
    this.renameFolderForm.get("folderName")?.setValue(this.data.folderName);
  }

  get folderName() {
    return this.renameFolderForm.get("folderName")?.value;
  }

  get name() {
    return this.renameFolderForm.get("folderName");
  }

  async onSubmit() {
    const fName = this.folderName.replace(/'/g, "''");

    let folder = new Folder(
      this.data.folderId,
      this.data.scripts,
      fName,
      this.data.createdAt,
      this.data.updatedAt,
      this.data.arrangeOrder
    );

    await this.scriptsService.updateFolder(folder).then(
      () => {},
      (err) => {
        console.log(err);
      }
    );
    this.dialogRef.close();
  }
}
