import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "removeCodeTags",
})
export class RemoveCodeTagsPipe implements PipeTransform {
  constructor() {}
  transform(value: string): string {
    return value.replace(/<code>(.*?)<\/code>/gs, "$1");
  }
}
