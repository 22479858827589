<h1 mat-dialog-title>What's the title of your new script?</h1>
<mat-dialog-content>
  <form [formGroup]="titleScriptForm" (ngSubmit)="onSubmit()">
    <label for="title" class="block text-sm font-medium text-gray-700"
      >Script Title</label
    >

    <div class="mt-1 flex rounded-md shadow-sm">
      <input
        type="text"
        maxlength="128"
        formControlName="scriptTitle"
        name="title"
        id="title"
        class="invalid:border-red-500 block w-full min-w-0 flex-grow rounded-md border-black focus:border-teleprompterBlue focus:ring-teleprompterBlue sm:text-sm"
      />
    </div>

    <div
      *ngIf="title?.invalid && (title?.dirty || title?.touched)"
      class="rounded-md bg-red-50 p-3 mt-2"
    >
      <div class="text-xs font-medium text-red-800 text-center">
        &uarr; Please enter a script title
      </div>
    </div>

    <section
      class="form-error rounded-md bg-red-50 p-3 mt-2"
      *ngIf="formErrorText"
    >
      <mat-error class="text-xs font-medium text-red-800 text-center">{{
        formErrorText
      }}</mat-error>
    </section>
    <div class="form-controls">
      <button
        [disabled]="!titleScriptForm.valid || formSubmitted"
        type="submit"
        class="mt-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue hover:bg-lightBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teleprompterBlue disabled:bg-gray-600 disabled:text-gray-400"
      >
        Create
      </button>
      <button
        [disabled]="formSubmitted"
        type="button"
        mat-dialog-close
        class="mt-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-TPBlue bg-TPBlue-50 hover:bg-TPBlue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teleprompterBlue disabled:bg-gray-100 disabled:text-gray-200"
      >
        Cancel
      </button>
    </div>
  </form>
</mat-dialog-content>
