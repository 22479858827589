<div class="flex-column flex-center">
  <h1 mat-dialog-title>Default Keyboard Controls</h1>
  <div class="control-description">
    <mat-icon>keyboard_return</mat-icon> Start / Stop
  </div>
  <div class="control-description">
    <mat-icon>arrow_upward</mat-icon> Scroll Up
  </div>
  <div class="control-description">
    <mat-icon>arrow_downward</mat-icon> Scroll Down
  </div>
  <div class="control-description">
    <mat-icon>arrow_forward</mat-icon> Next Page
  </div>
  <div class="control-description">
    <mat-icon>arrow_backward</mat-icon> Previous Page
  </div>
</div>
