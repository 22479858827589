import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Folder } from "src/app/scripts/foldersITC";
import { ScriptService } from "src/app/scripts/script.service";

@Component({
  selector: "app-add-to-folder-dialog",
  templateUrl: "./add-to-folder-dialog.component.html",
  styleUrls: ["./add-to-folder-dialog.component.css"],
})
export class AddToFolderDialogComponent implements OnInit {
  addToFolderForm = this.fb.group({
    folder: ["", [Validators.required]],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      scriptId: string;
      folders: Folder[];
    },
    private fb: UntypedFormBuilder,
    private scriptsService: ScriptService
  ) {}

  get folder() {
    return this.addToFolderForm.get("folder")?.value as Folder;
  }

  ngOnInit() {}

  async onAddScript() {
    let folder = this.folder;
    if (!folder.scripts) {
      folder.scripts = {};
    }

    Object.assign(folder.scripts, { [this.data.scriptId]: 1 });

    await this.scriptsService.updateFolder(folder);
  }
}
