import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ResetPasswordDialogComponent } from "../reset-password-dialog/reset-password-dialog.component";

@Component({
  selector: "app-account-locked-dialog",
  templateUrl: "./account-locked-dialog.component.html",
  styleUrls: ["./account-locked-dialog.component.scss"],
})
export class AccountLockedDialogComponent {
  constructor(private dialogService: MatDialog) {}

  goToResetPassword() {
    this.dialogService.open(ResetPasswordDialogComponent, {
      data: {},
    });
  }
}
