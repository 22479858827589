import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { filter, first, map } from "rxjs/operators";
import { UserService } from "../user/user.service";

@Injectable()
export class BetaUsersGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkBetaAccess();
  }

  checkBetaAccess(): Observable<boolean> {
    return this.userService.userProfile$.pipe(
      filter((data) => data !== null),
      first(),
      map((response) => {
        if (response?.betaUser || response?.isAdmin) {
          return true;
        } else {
          this.router.navigate(["/coming-soon"]);
          return false;
        }
      })
    );
  }
}
