import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationService } from "./navigation/navigation.service";
import { Subscription } from "rxjs";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})

/**
 * Primary entry component, ancestor of all other components.
 */
export class AppComponent implements OnInit, OnDestroy {
  private _subscription: Subscription = new Subscription();
  /** boolean, when true app-nav-bar component is displayed */
  showNavbar!: boolean;
  isOffCanvasMenu = false;
  path = "";
  constructor(
    private navigationService: NavigationService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    this._subscription.add(
      this.router.events.subscribe((val) => {
        this.path = this.router.url;

        //console.log(this.path);
      })
    );
  }
  ngOnInit() {
    //subscribe to navigationService.ShowNavbas$ Observable;  Allowing other components to set showNavbar status.
    this._subscription.add(
      this.navigationService.showNavbar$.subscribe((state: boolean) => {
        this.showNavbar = state;
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
  toggleOffCanvasMenu() {
    this.isOffCanvasMenu = !this.isOffCanvasMenu;
  }
}
