export interface IScript {
  arrangeOrder: number;
  backgroundBlue: number;
  backgroundGreen: number;
  backgroundRed: number;
  cloudPrompterID: string;
  createdAt: string;
  fontFace: string;
  fontSize: string;
  isDeleted: string;
  plainText: string;
  rtfString: string;
  htmlString: string;
  scrollSpeed: string;
  timedScrollingSeconds: number;
  title: string;
  updatedAt: string;
  fontColor: string;
  borderColor: string;
}

export interface IScriptDropDown {
  cloudPrompterID: string;
  title: string;
}

export class ScriptDropDown implements IScriptDropDown {
  constructor(public cloudPrompterID: string = "", public title: string = "") {}
}

export class Script implements IScript {
  constructor(
    public arrangeOrder: number = 0,
    public backgroundBlue: number = 0,
    public backgroundGreen: number = 0,
    public backgroundRed: number = 0,
    public cloudPrompterID: string = "",
    public createdAt: string = "",
    public fontFace: string = "Helvetica",
    public fontSize: string = "48",
    public isDeleted: string = "",
    public plainText: string = "",
    public rtfString: string = "",
    public htmlString: string = "",
    public scrollSpeed: string = "56",
    public timedScrollingSeconds: number = 10,
    public title: string = "",
    public updatedAt: string = "",
    public fontColor: string = "#FFFFFF",
    public borderColor: string = "#FFFFFF"
  ) {}
}
