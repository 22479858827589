import { Component, Inject } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ScriptService } from "src/app/scripts/script.service";
import { AppError } from "src/app/error/appError";
import { AppErrorHandler } from "src/app/error/appErrorHandler";
import { ErrorService } from "src/app/error/error.service";
import { Script } from "src/app/scripts/scriptsITC";

@Component({
  selector: "app-rename-script-dialog",
  templateUrl: "./rename-script-dialog.component.html",
  styleUrls: ["./rename-script-dialog.component.css"],
  providers: [AppErrorHandler],
})
export class RenameScriptDialogComponent {
  formSubmitted: boolean = false;
  formErrorText: string = "";

  renameScriptForm = this.fb.group({
    scriptName: ["", [Validators.required, Validators.maxLength(128)]],
  });
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      script: Script;
    },
    private scriptsService: ScriptService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<RenameScriptDialogComponent>,
    private appErrorHandler: AppErrorHandler,
    private errorService: ErrorService
  ) {
    this.renameScriptForm.get("scriptName")?.setValue(this.data.script.title);
  }

  get scriptName() {
    return this.renameScriptForm.get("scriptName")?.value;
  }

  get name() {
    return this.renameScriptForm.get("scriptName");
  }

  async onSubmit() {
    this.formErrorText = "";
    this.formSubmitted = true;
    try {
      const scriptTitle = this.scriptName.replace(/'/g, "''");

      this.data.script.title = scriptTitle;

      let updateScript: void | AppError =
        await this.scriptsService.updateScript(this.data.script);

      if (updateScript instanceof AppError) {
        this.appErrorHandler[updateScript.code](updateScript);
        return;
      }

      this.formSubmitted = false;
      this.dialogRef.close("confirm");
    } catch (err) {
      const appError = this.errorService.parseError(
        err,
        "RenameScriptDialogComponent.onSubmit",
        "crud/renameScript"
      );
      this.appErrorHandler[appError.code](appError);
    }
  }
}
