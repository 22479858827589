<nav *ngIf="userProfile != null" class="border-b border-gray-200 bg-white">
  <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
    <div class="flex h-16 justify-between">
      <div class="flex">
        <div class="flex flex-shrink-0 items-center">
          <a routerLink="">
            <img class="h-7 w-auto mr-4" src="assets/LogoSmall.svg"
              alt="CloudPrompter - The Online Teleprompter Software" />
          </a>
        </div>
        <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
          <!-- Current: "border-TPBlue-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
          <a routerLink=""
            class="border-transparent text-gray-500 hover:border-gray-300 inline-flex items-center pt-1 border-b-2 px-1 pt-1 text-sm font-medium"
            routerLinkActive="text-gray-700 border-teleprompterBlue hover:border-teleprompterBlue"
            [routerLinkActiveOptions]="{ exact: true }">
            My Scripts</a>
          <a routerLink="/broadcasts"
            class="border-transparent text-gray-500 hover:border-gray-300 inline-flex items-center pt-1 border-b-2 px-1 pt-1 text-sm font-medium"
            routerLinkActive="text-gray-700 border-teleprompterBlue hover:border-teleprompterBlue">
            Broadcasts</a>
        </div>
      </div>
      <div class="hidden sm:ml-6 sm:flex sm:items-center">
        <button routerLink="/upgrade" type="button" *ngIf="!loading && entitlements && !entitlements.cloudPrompter"
          class="mr-2 flex items-center gap-1 rounded-md bg-teleprompterBlue px-3 py-2 text-xs font-semibold text-white shadow-sm hover:bg-TPBlue-600">
          <span>Upgrade</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
          </svg>
        </button>

        <button routerLink="/profile" type="button" routerLinkActive="text-teleprompterBlue"
          [routerLinkActiveOptions]="{ exact: true }"
          class="flex gap-2 items-center rounded-full bg-white py-1 px-3 text-gray-400 hover:text-gray-500">
          <span>
            {{ userProfile.firstName ? userProfile.firstName : "Account" }}
            <!-- Fallback here to "Account" is user doesnt have value for firstName -->
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        </button>

        <a href="https://guide.cloudprompter.com/" target="_blank"
          class="flex gap-2 items-center rounded-full bg-white py-1 px-3 text-gray-400 hover:text-gray-500">
          <span> Guide </span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
          </svg>
        </a>
      </div>

      <div class="sm:hidden -mr-2">
        <div class="h-full flex items-center">
          <!-- Mobile menu button -->
          <button type="button" (click)="onOpenSidenav()"
            class="relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-TPBlue-500 focus:ring-offset-2"
            aria-controls="mobile-menu" aria-expanded="false">
            <span class="absolute -inset-0.5"></span>
            <span class="sr-only">Open main menu</span>
            <!-- Menu open: "hidden", Menu closed: "block" -->
            <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <!-- Menu open: "block", Menu closed: "hidden" -->
            <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
              aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile menu, show/hide based on menu state. -->
  <div *ngIf="isOffCanvasMenu && userProfile != null" class="sm:hidden" id="mobile-menu">
    <div class="space-y-1 pb-3 pt-2">
      <!-- Current: "border-TPBlue-500 bg-TPBlue-50 text-TPBlue-700", Default: "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800" -->

      <a (click)="onOpenSidenav()" routerLink=""
        class="border-transparent text-gray-600 hover:border-gray-300 hover:bg-TPBlue-100 hover:text-gray-800 block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
        routerLinkActive="text-gray-700 bg-TPBlue-100 border-teleprompterBlue hover:border-teleprompterBlue"
        [routerLinkActiveOptions]="{ exact: true }">My Scripts</a>
      <a (click)="onOpenSidenav()" routerLink="/broadcasts"
        class="border-transparent text-gray-600 hover:border-gray-300 hover:bg-TPBlue-100 hover:text-gray-800 block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
        routerLinkActive="text-gray-700 bg-TPBlue-100 border-teleprompterBlue hover:border-teleprompterBlue"
        [routerLinkActiveOptions]="{ exact: true }">Broadcasts</a>
    </div>
    <div class="border-t border-gray-200 pb-3 pt-2">
      <div class="mt-3 space-y-1">
        <a (click)="onOpenSidenav()" routerLink="/profile"
          class="border-transparent text-gray-600 hover:border-gray-300 hover:bg-TPBlue-100 hover:text-gray-800 block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
          routerLinkActive="text-gray-700 bg-TPBlue-100 border-teleprompterBlue hover:border-teleprompterBlue"
          [routerLinkActiveOptions]="{ exact: true }">Account Settings</a>
        <a (click)="onOpenSidenav()" href="https://guide.cloudprompter.com/" target="_blank"
          class="border-transparent text-gray-600 hover:border-gray-300 hover:bg-TPBlue-100 hover:text-gray-800 block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
          routerLinkActive="text-gray-700 bg-TPBlue-100 border-teleprompterBlue hover:border-teleprompterBlue"
          [routerLinkActiveOptions]="{ exact: true }">User Guide</a>
      </div>
    </div>
  </div>
</nav>