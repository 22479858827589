import { NgModule } from "@angular/core";
import { AccountLockedDialogComponent } from "./account-locked-dialog/account-locked-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { SharedModule } from "../shared.module";
import { SuccessDialogComponent } from "./success-dialog/success-dialog.component";
import { CreateScriptDialogComponent } from "./create-script-dialog/create-script-dialog.component";
import { ImportScriptDialogComponent } from "./import-script-dialog/import-script-dialog.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { JumpToDialogComponent } from "./jump-to-dialog/jump-to-dialog.component";
import { ResetPasswordDialogComponent } from "./reset-password-dialog/reset-password-dialog.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { HintDialogComponent } from "./hint-dialog/hint-dialog.component";
import { GamepadDialogComponent } from "./gamepad-dialog/gamepad-dialog.component";
import { KeyboardDialogComponent } from "./keyboard-dialog/keyboard-dialog.component";
import { SyncDialogComponent } from "./sync-dialog/sync-dialog.component";
import { ShareBroadcastDialogComponent } from "./share-broadcast-dialog/share-broadcast-dialog.component";
import { NgxQRCodeModule } from "@techiediaries/ngx-qrcode";
import { GoProDialogComponent } from "./go-pro-dialog/go-pro-dialog.component";
import { RouterModule } from "@angular/router";

import { ScriptOptionsDialogComponent } from "./script-options-dialog/script-options-dialog.component";
import { CreateFolderDialogComponent } from "./create-folder-dialog/create-folder-dialog.component";
import { RenameFolderDialogComponent } from "./rename-folder-dialog/rename-folder-dialog.component";
import { RenameScriptDialogComponent } from "./rename-script-dialog/rename-script-dialog.component";
import { AddToFolderDialogComponent } from "./add-to-folder-dialog/add-to-folder-dialog.component";

@NgModule({
  declarations: [
    AccountLockedDialogComponent,
    SuccessDialogComponent,
    CreateScriptDialogComponent,
    ImportScriptDialogComponent,
    ErrorDialogComponent,
    JumpToDialogComponent,
    ResetPasswordDialogComponent,
    ConfirmDialogComponent,
    HintDialogComponent,
    GamepadDialogComponent,
    KeyboardDialogComponent,
    SyncDialogComponent,
    ShareBroadcastDialogComponent,
    GoProDialogComponent,
    ScriptOptionsDialogComponent,
    CreateFolderDialogComponent,
    RenameFolderDialogComponent,
    RenameScriptDialogComponent,
    AddToFolderDialogComponent,
  ],
  imports: [SharedModule, NgxQRCodeModule, MatDialogModule, RouterModule],
})
export class DialogModule {}
