import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NavigationService {
  /**
   * boolean used so set render state of nav bar
   */
  showNavbar: boolean = false;
  private _showNavbar$ = new BehaviorSubject<boolean>(this.showNavbar);
  showNavbar$: Observable<boolean> = this._showNavbar$.asObservable();
  constructor(private router: Router) {}

  /**
   * navigates to route based on supplied fragments.
   * @fragments string[] - array of url fragments that will form route to navigate.
   */
  navigate(fragments: string[], options?: NavigationExtras) {
    this.router.navigate(fragments, options);
  }

  /**
   * Toggles {@link _showNavbar$}
   */
  toggleNavbar() {
    this.showNavbar = !this.showNavbar;
    this._showNavbar$.next(this.showNavbar);
  }
}
