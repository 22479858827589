import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AuthService } from "src/app/auth/auth.service";
import { ScriptService } from "src/app/scripts/script.service";
import { SubscriptionService } from "src/app/subscription/subscription.service";
import { UserService } from "src/app/user/user.service";

@Component({
  selector: "app-coming-soon",
  templateUrl: "./coming-soon.component.html",
  styleUrls: ["./coming-soon.component.css"],
})
export class ComingSoonComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private subService: SubscriptionService,
    private scriptService: ScriptService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(
      "Coming Soon - CloudPrompter | Online Teleprompter"
    );
    setTimeout(() => {
      this.scriptService.clearUserData();
      this.subService.clearSubData();
      this.userService.clearUserProfile();
      this.authService.logout();
    }, 5000);
  }
}
