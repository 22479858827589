import { DOCUMENT } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { BroadcastService } from "src/app/broadcast/broadcast.service";
import { IBroadcast } from "src/app/broadcast/broadcastITC";
import {
  Entitlements,
  SubscriptionService,
} from "src/app/subscription/subscription.service";
import { UserService } from "src/app/user/user.service";
import { IUser } from "src/app/user/userITC";

@Component({
  selector: "app-nav-bar",
  templateUrl: "./nav-bar.component.html",
  styleUrls: ["./nav-bar.component.scss"],
})
export class NavBarComponent implements OnInit, OnDestroy {
  window;
  loading: boolean = true;
  //used to open and close side nav
  @Input()
  isOffCanvasMenu: boolean = false;
  @Output() toggleSideNav: EventEmitter<boolean> = new EventEmitter<boolean>();
  //holds component subscriptions
  userProfile!: IUser | null;
  subscription: Subscription = new Subscription();
  userBroadcasts: IBroadcast[] = [];
  //current user entitlement status
  entitlements!: Entitlements | null;
  status: string = "";
  hasPremiumSub: boolean = false;
  hasCloudPrompterSub: boolean = false;
  constructor(
    private subscriptionService: SubscriptionService,
    private userService: UserService,
    private broadcastService: BroadcastService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.window = this.document.defaultView;
  }

  //suns on component start
  ngOnInit(): void {
    //entitlement subscription

    this.subscription.add(
      this.userService.userProfile$.subscribe((profile: IUser | null) => {
        this.userProfile = profile;
        //console.log(this.userProfile);
      })
    );

    setTimeout(() => {
      this.subscription.add(
        this.subscriptionService.entitlements$.subscribe((entitlements) => {
          this.entitlements = entitlements;

          if (entitlements?.cloudPrompter === true) {
            this.hasCloudPrompterSub = true;
            this.hasPremiumSub = true;
          } else {
            this.hasCloudPrompterSub = false;
          }

          if (entitlements?.premiumSubscription === true) {
            this.hasPremiumSub = true;
          } else if (
            entitlements?.premiumSubscription === false &&
            entitlements?.cloudPrompter === false
          ) {
            this.hasPremiumSub = false;
          }
        })
      );
    }, 1000);

    this.subscription.add(
      this.broadcastService.userBroadcasts$.subscribe(
        (broadcasts: IBroadcast[] | null) => {
          if (broadcasts) {
            this.userBroadcasts = broadcasts;
          }
        }
      )
    );
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }

  /** runs on component destruction*/
  ngOnDestroy() {
    //unsubscribe subscriptions
    this.subscriptionService.emitSubscriptionStatus();
    this.subscription.unsubscribe();
  }

  /** toggles side nav */
  onOpenSidenav() {
    this.toggleSideNav.emit(!this.isOffCanvasMenu);
  }

  onHelp() {
    this.window.open(
      "https://joeallenpro.com/support-product/teleprompter-premium/"
    );
  }
}
