import { Pipe, PipeTransform } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: "removePreCodeTags",
})
export class RemovePreCodeTagsPipe implements PipeTransform {
  constructor() {}
  transform(value: SafeHtml): string {
    return value["changingThisBreaksApplicationSecurity"].replace(
      /<pre>(.*?)<\/pre>/gs,
      "$1"
    );
  }
}
