<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
  <div class="text-base max-w-md">{{ data.message }}</div>
</mat-dialog-content>
<div class="flex justify-center gap-4 p-2">
  <button
    [mat-dialog-close]="true"
    type="submit"
    class="mt-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-TPBlue hover:bg-TPBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-TPBlue disabled:bg-gray-100 disabled:text-gray-200 mr-3"
  >
    {{ data.confirmButtonText }}
  </button>
  <button
    mat-dialog-close
    type="button"
    class="mt-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teleprompterBlue disabled:bg-gray-100 disabled:text-gray-200"
  >
    {{ data.cancelButtonText }}
  </button>
</div>
