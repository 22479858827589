import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

type JumpType = "page" | "cue";

export interface IJumpOptions {
  jumpType: JumpType;
  jumpTo: number;
}

@Component({
  selector: "app-jump-to-dialog",
  templateUrl: "./jump-to-dialog.component.html",
  styleUrls: ["./jump-to-dialog.component.scss"],
})
export class JumpToDialogComponent implements OnInit {
  totalPages: number = 0;
  totalMarkers: number = 0;
  nextPage: number = 0;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clientHeight: number;
      scrollHeight: number;
      scrollTop: number;
      totalCueMarkers: number;
      nextCuemarker?: number;
    },
    private dialogRef: MatDialogRef<JumpToDialogComponent>,
    private fb: UntypedFormBuilder
  ) {}

  jumpForm: UntypedFormGroup = this.fb.group({
    jumpType: ["page"],
    jumpTo: [1],
  });

  get jumpType(): JumpType {
    return this.jumpForm.get("jumpType")?.value;
  }

  get jumpTo(): number {
    return this.jumpForm.get("jumpTo")?.value;
  }

  ngOnInit(): void {
    this.totalPages = Math.ceil(
      this.data.scrollHeight / this.data.clientHeight
    );
    this.totalMarkers = this.data.totalCueMarkers;
    this.nextPage = Math.min(
      Math.max(1, Math.ceil(this.data.scrollTop / this.data.clientHeight)),
      this.totalPages
    );
  }

  onSubmit() {
    this.dialogRef.close(this.jumpForm.value as IJumpOptions);
  }
}
