import { Injectable, OnDestroy } from "@angular/core";
import {
  AngularFireDatabase,
  AngularFireList,
  AngularFireObject,
} from "@angular/fire/compat/database";
import { BehaviorSubject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import { AuthUser } from "../auth/authITC";
import { AppError } from "../error/appError";
import { ErrorService } from "../error/error.service";
import { IScript, Script } from "./scriptsITC";
import { IScriptPrompterOptions } from "../prompter/prompterITC";
import { Folder, IFolder } from "./foldersITC";

@Injectable({
  providedIn: "root",
})
export class ScriptService implements OnDestroy {
  /** stores value received fom subscription to authUser$ Observable on the {@link AuthService} */
  private _authUser!: AuthUser | null;
  /** path to user's scripts DB node */
  private _scriptsNodePath = "";
  private _foldersNodePath = "";
  /** reference to user's scripts node */
  private _dbScriptListRef!: AngularFireList<Script>;
  private _dbFoldersListRef!: AngularFireList<Folder>;

  /** subscription to user's scripts realtime db list */
  private _userScriptsSub = new Subscription();
  /** BehaviorSubject used to emit values to subscribers of the {@link userScripts$} Observable */
  private _userScripts$ = new BehaviorSubject<Script[] | null>(null);
  /** Observable that emits either an {@link Script[]} or null value */
  userScripts$ = this._userScripts$.asObservable();

  private _userFolders$ = new BehaviorSubject<Folder[] | null>(null);
  userFolders$ = this._userFolders$.asObservable();

  constructor(
    private errorService: ErrorService,
    private authService: AuthService,
    private db: AngularFireDatabase
  ) {
    this._userScriptsSub = this.authService.authUser$.subscribe((authUser) => {
      this._authUser = authUser;
      if (authUser) {
        this._scriptsNodePath = "scripts/" + authUser.uid;
        this._foldersNodePath = "folders/" + authUser.uid;
        this._dbScriptListRef = this.db.list<Script>(this._scriptsNodePath);
        this._dbFoldersListRef = this.db.list<Folder>(this._foldersNodePath);

        this._dbScriptListRef
          .snapshotChanges()
          .pipe(
            map((scripts) => {
              return scripts.map((snap) => {
                let script = snap.payload.val();
                return script as Script;
              });
            })
          )
          .subscribe((scripts: Script[]) => {
            let currentScripts = scripts.filter(
              (script) =>
                (script.cloudPrompterID && script.isDeleted === "NO") ||
                (script.cloudPrompterID && !script.isDeleted)
            );

            this._userScripts$.next(currentScripts);
          });

        this._dbFoldersListRef
          .snapshotChanges()
          .pipe(
            map((arr) => {
              return arr.map((snap) => {
                let folder = snap.payload.toJSON();
                folder!["folderid"] = snap.key;

                return folder as Folder;
              });
            })
          )
          .subscribe((folders: Folder[]) => {
            this._userFolders$.next(folders);
          });
      }
    });
  }

  /**
   * async method - Saves the script param to a DB node associated with the current users uid,
   * which is stored in {@link _authUser}.uid.
   * @param script: IScript script to be saved
   * @return Promise<void | {@link AppError}>
   */
  async saveScript(script: IScript): Promise<void | AppError> {
    try {
      //if user is not authenticated return an AppError
      if (!this._authUser) {
        return new AppError(
          "e404",
          "no current user",
          "UserService.createUser",
          "check/authUser"
        );
      }
      const ref = this._dbScriptListRef.push(script);
      await ref.update({ cloudPrompterID: ref.key });
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.saveScript",
        "crud/saveScript"
      );
      return appError;
    }
  }

  /**
   * async method - Instantiates a {@link Script} saves it to the bd and returns the script id.
   * @param title string - title of script.
   * @param? content string - optional initial plainText content
   * @return Promise<string | {@link AppError}>
   */
  async initScript(
    title: string,
    plainText: string = ""
  ): Promise<string | AppError> {
    try {
      const script = new Script();
      script.title = title;
      script.plainText = plainText;
      let timeStamp = new Date(Date.now());

      // Format date to "yyyy-MM-dd HH:mm:ss Z"
      const padZero = (num: number) => num.toString().padStart(2, "0");

      const year = timeStamp.getUTCFullYear();
      const month = padZero(timeStamp.getUTCMonth() + 1);
      const day = padZero(timeStamp.getUTCDate());
      const hour = padZero(timeStamp.getUTCHours());
      const minute = padZero(timeStamp.getUTCMinutes());
      const second = padZero(timeStamp.getUTCSeconds());

      const offsetMinutes = timeStamp.getTimezoneOffset();
      const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
      const offsetMinutesPart = padZero(Math.abs(offsetMinutes) % 60);
      const offsetSign = offsetMinutes <= 0 ? "+" : "-";

      const formattedTimestamp = `${year}-${month}-${day} ${hour}:${minute}:${second} ${offsetSign}${padZero(
        offsetHours
      )}${offsetMinutesPart}`;

      script.createdAt = formattedTimestamp;
      script.updatedAt = formattedTimestamp;
      console.log(formattedTimestamp);
      script.isDeleted = "NO";
      const ref = this._dbScriptListRef.push(script);
      await ref.update({ cloudPrompterID: ref.key });
      return ref.key as string;
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "scriptsService.initScript",
        "crud/createScript"
      );
      return appError;
    }
  }

  async initFolder(title: string): Promise<string | AppError> {
    try {
      const folder = new Folder();
      folder.title = title;
      let timeStamp = new Date(Date.now());

      // Format date to "yyyy-MM-dd HH:mm:ss Z"
      const padZero = (num: number) => num.toString().padStart(2, "0");

      const year = timeStamp.getUTCFullYear();
      const month = padZero(timeStamp.getUTCMonth() + 1);
      const day = padZero(timeStamp.getUTCDate());
      const hour = padZero(timeStamp.getUTCHours());
      const minute = padZero(timeStamp.getUTCMinutes());
      const second = padZero(timeStamp.getUTCSeconds());

      const offsetMinutes = timeStamp.getTimezoneOffset();
      const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
      const offsetMinutesPart = padZero(Math.abs(offsetMinutes) % 60);
      const offsetSign = offsetMinutes <= 0 ? "+" : "-";

      const formattedTimestamp = `${year}-${month}-${day} ${hour}:${minute}:${second} ${offsetSign}${padZero(
        offsetHours
      )}${offsetMinutesPart}`;

      folder.createdAt = formattedTimestamp;
      folder.updatedAt = formattedTimestamp;
      const ref = this._dbFoldersListRef.push(folder);
      await ref.update({ folderid: ref.key });
      return ref.key as string;
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "scriptsService.initFolder",
        "crud/createFolder"
      );
      return appError;
    }
  }

  /**
   * async method - takes in a .prompt file string, instantiates {@link Script} and saves it to the db and returns the script id.
   * @param title string - title of script.
   * @return Promise<string | {@link AppError}>
   */
  async importPromptFile(
    title: string,
    promptString: string = ""
  ): Promise<string | AppError> {
    try {
      let parsedPrompt = JSON.parse(promptString);
      let script = new Script();
      Object.assign(script, parsedPrompt);
      script.title = title;
      const ref = this._dbScriptListRef.push(script);
      await ref.update({ cloudPrompterID: ref.key });
      return ref.key as string;
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "scriptsService.initScript",
        "crud/createScript"
      );
      return appError;
    }
  }

  /**
   * async method - updates a script with the supplied script param.
   * @param script {@link IScript} - html string to  be parsed and stored.
   * @return Promise<null | {@link AppError}>
   */
  async updateScript(script: IScript): Promise<void | AppError> {
    try {
      if (this._dbScriptListRef) {
        let timeStamp = new Date(Date.now());

        // Format date to "yyyy-MM-dd HH:mm:ss Z"
        const padZero = (num: number) => num.toString().padStart(2, "0");

        const year = timeStamp.getUTCFullYear();
        const month = padZero(timeStamp.getUTCMonth() + 1);
        const day = padZero(timeStamp.getUTCDate());
        const hour = padZero(timeStamp.getUTCHours());
        const minute = padZero(timeStamp.getUTCMinutes());
        const second = padZero(timeStamp.getUTCSeconds());

        const offsetMinutes = timeStamp.getTimezoneOffset();
        const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
        const offsetMinutesPart = padZero(Math.abs(offsetMinutes) % 60);
        const offsetSign = offsetMinutes <= 0 ? "+" : "-";

        const formattedTimestamp = `${year}-${month}-${day} ${hour}:${minute}:${second} ${offsetSign}${padZero(
          offsetHours
        )}${offsetMinutesPart}`;

        script.updatedAt = formattedTimestamp;

        // script.plainText = htmlToText(script.htmlString);
        await this._dbScriptListRef.update(script.cloudPrompterID, script);
      } else {
        return new AppError(
          "e404",
          "update failed as no _dbScriptListRef: AngularFireList<Script>",
          "ScriptService.updateScript",
          "crud/updateScript"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.updateScript",
        "crud/updateScript"
      );
      return appError;
    }
  }

  async updateFolder(folder: IFolder): Promise<void | AppError> {
    try {
      const isFolderTitleValid: boolean | AppError =
        await this.isFolderTitleValid(folder.title, folder.folderid);
      if (!isFolderTitleValid) {
        return new AppError(
          "e409",
          "folder with supplied title already exists",
          "ScriptService.updateFolder",
          "check/isFolderTitleValid"
        );
      }
      if (this._dbFoldersListRef) {
        let timeStamp = new Date(Date.now());

        // Format date to "yyyy-MM-dd HH:mm:ss Z"
        const padZero = (num: number) => num.toString().padStart(2, "0");

        const year = timeStamp.getUTCFullYear();
        const month = padZero(timeStamp.getUTCMonth() + 1);
        const day = padZero(timeStamp.getUTCDate());
        const hour = padZero(timeStamp.getUTCHours());
        const minute = padZero(timeStamp.getUTCMinutes());
        const second = padZero(timeStamp.getUTCSeconds());

        const offsetMinutes = timeStamp.getTimezoneOffset();
        const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
        const offsetMinutesPart = padZero(Math.abs(offsetMinutes) % 60);
        const offsetSign = offsetMinutes <= 0 ? "+" : "-";

        const formattedTimestamp = `${year}-${month}-${day} ${hour}:${minute}:${second} ${offsetSign}${padZero(
          offsetHours
        )}${offsetMinutesPart}`;

        folder.updatedAt = formattedTimestamp;
        // console.log(folder.title);
        await this._dbFoldersListRef.update(folder.folderid, folder);
      } else {
        return new AppError(
          "e404",
          "update failed as no _dbFoldersListRef: AngularFireList<Folder>",
          "ScriptService.updateFolder",
          "crud/updateFolder"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.createFolder",
        "crud/createFolder"
      );
      return appError;
    }
  }

  /**
   * async method - updates a script options
   * scriptId - id of script to update
   * @param scriptOptions {@link IScriptPrompterOptions}
   * @return Promise<null | {@link AppError}>
   */
  async setScriptOptions(
    scriptId: string,
    scriptOptions: IScriptPrompterOptions
  ): Promise<void | AppError> {
    try {
      await this._dbScriptListRef.update(scriptId, scriptOptions);
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.",
        "crud/createScript"
      );
      return appError;
    }
  }
  /**
   * async method - updates a script's speed
   * scriptId - id of script to update
   * @param scriptOptions {@link IScriptPrompterOptions}
   * @return Promise<null | {@link AppError}>
   */
  async setScriptSpeed(
    scriptId: string,
    speed: string
  ): Promise<void | AppError> {
    try {
      await this._dbScriptListRef.update(scriptId, { scrollSpeed: speed });
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.setScriptSpeed",
        "crud/createScript"
      );
      return appError;
    }
  }

  /**
   * async method - deletes the script list from the db
   * @return Promise<void | {@link AppError}>
   */
  async deleteScriptList(): Promise<void | AppError> {
    try {
      if (this._dbScriptListRef) {
        await this._dbScriptListRef.remove();
      } else {
        return new AppError(
          "e404",
          "delete failed as no _dbScriptListRef: AngularFireList<Script>",
          "ScriptService.deleteScriptList",
          "crud/deleteScriptList"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "scriptsService.deleteScriptList",
        "crud/deleteScriptList"
      );
      return appError;
    }
  }

  /**
   * async method - delete the script from the db
   * @return Promise<void | {@link AppError}>
   */
  async deleteScript(scriptId: string): Promise<void | AppError> {
    try {
      if (this._dbScriptListRef) {
        await this._dbScriptListRef.remove(scriptId);
      } else {
        return new AppError(
          "e404",
          "delete failed as no _dbScriptListRef: AngularFireList<Script>",
          "ScriptService.deleteScript",
          "crud/deleteScript"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "scriptsService.deleteScript",
        "crud/deleteScript"
      );
      return appError;
    }
  }

  async deleteFolder(folderId: string): Promise<void | AppError> {
    try {
      if (this._dbFoldersListRef) {
        await this._dbFoldersListRef.remove(folderId);
      } else {
        return new AppError(
          "e404",
          "delete failed as no _dbFoldersListRef: AngularFireList<Folder>",
          "ScriptService.deleteFolder",
          "crud/deleteFolder"
        );
      }
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "scriptsService.deleteFolder",
        "crud/deleteFolder"
      );
      return appError;
    }
  }
  /**
   * checks if supplied title conflicts with other script titles in {@link _userScripts$}
   * returns true if no conflicts found, false on conflict.
   * @param title string - title to be checked
   * @return Promise<boolean | {@link AppError}>
   */
  async isTitleValid(
    title: string,
    scriptId?: string
  ): Promise<boolean | AppError> {
    try {
      let scripts = this._userScripts$.getValue();
      if (!scripts) {
        return true;
      }
      for (let script of scripts) {
        if (title === script.title) {
          if (scriptId && scriptId === script.cloudPrompterID) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.isTitleValid",
        "check/isTitleValid"
      );
      return appError;
    }
  }

  async isFolderTitleValid(
    title: string,
    folderid?: string
  ): Promise<boolean | AppError> {
    try {
      let folders = this._userFolders$.getValue();

      if (!folders) {
        return true;
      }
      for (let folder of folders) {
        if (title === folder.title) {
          if (folderid && folderid === folder.folderid) {
            return true;
          } else {
            return false;
          }
        }
      }
      return true;
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.isFolderTitleValid",
        "check/isFolderTitleValid"
      );
      return appError;
    }
  }
  /**
   * returns the current value of {@link _userScripts$} that corresponds to {@link userScripts$}
   * @return Script[] | null | {@link AppError}
   */
  getScripts(): Script[] | null | AppError {
    try {
      // console.log(this._userScripts$.getValue());
      return this._userScripts$.getValue() as Script[];
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.getScripts",
        "get/scripts"
      );
      return appError;
    }
  }

  getFolders(): Folder[] | null | AppError {
    try {
      return this._userFolders$.getValue();
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.getFolders",
        "get/folders"
      );
      return appError;
    }
  }
  /**
   * method returns script object reference
   * @param scriptId string - id of script
   * @return AngularFireObject<Script> | {@link AppError}
   */
  getScriptRef(scriptId: string): AngularFireObject<Script> | AppError {
    try {
      const ref: AngularFireObject<Script> = this.db.object(
        this._scriptsNodePath + `/${scriptId}`
      );
      return ref;
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "ScriptService.getScriptRef",
        "get/scriptRef"
      );
      return appError;
    }
  }

  clearUserData() {
    this._userFolders$.next([]);
    this._userScripts$.next([]);
    this._authUser = null;
  }

  ngOnDestroy(): void {
    this._userScriptsSub.unsubscribe();
  }
}
