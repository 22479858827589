import { Script } from "./scriptsITC";

export interface IFolder {
  folderid: string;
  scripts?: {};
  title: string;
  createdAt: string;
  updatedAt: string;
  arrangeOrder: number;
  scriptDetails?: Script[];
  isOpen?: boolean;
}

export class Folder implements IFolder {
  constructor(
    public folderid: string = "",
    public scripts: {} = {},
    public title: string = "",
    public createdAt: string = "",
    public updatedAt: string = "",
    public arrangeOrder: number = 0,
    public scriptDetails: Script[] = [],
    public isOpen: boolean = false
  ) {}
}
