<div class="dialog-container">
  <h1 mat-dialog-title class="text-center font-bold">Account Locked</h1>
  <mat-dialog-content>
    <p class="text-black">
      Due to multiple incorrect password entries your account has been
      temporarily locked.
      <br />
      Please try again later, or for immediate access, reset your password.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      (click)="goToResetPassword()"
      mat-dialog-close
      class="mt-3 mr-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue hover:bg-lightBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teleprompterBlue disabled:bg-gray-600 disabled:text-gray-400"
    >
      Reset Password
    </button>

    <button
      type="button"
      mat-dialog-close
      class="mt-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-TPBlue bg-TPBlue-50 hover:bg-TPBlue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teleprompterBlue disabled:bg-gray-100 disabled:text-gray-200"
    >
      I Understand
    </button>
  </mat-dialog-actions>
</div>
