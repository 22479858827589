import { Injectable } from '@angular/core';
import { LoggingService } from '../logging/logging.service';
import { AppError } from './appError';
import { AppErrorHandlerMap } from './errorMaps';

/**
 * class - use to define error handlers for Different {@link AppErrorCode}s
 */
@Injectable()
export class AppErrorHandler implements AppErrorHandlerMap {
  constructor(private loggingService: LoggingService) {}
  e400 = (error: AppError) => {
    this.loggingService.logGroups(
      this.loggingService.newLogGroup('e400 error', [error], true)
    );
  };
  e401 = (error: AppError) => {
    this.loggingService.logGroups(
      this.loggingService.newLogGroup('e401 error', [error], true)
    );
  };
  e403 = (error: AppError) => {
    this.loggingService.logGroups(
      this.loggingService.newLogGroup('e403 error', [error], true)
    );
  };
  e404 = (error: AppError) => {
    this.loggingService.logGroups(
      this.loggingService.newLogGroup('e404 error', [error], true)
    );
  };
  e409 = (error: AppError) => {
    this.loggingService.logGroups(
      this.loggingService.newLogGroup('e409 error', [error], true)
    );
  };
  e499 = (error: AppError) => {
    this.loggingService.logGroups(
      this.loggingService.newLogGroup('e499 error', [error], true)
    );
  };
  e500 = (error: AppError) => {
    this.loggingService.logGroups(
      this.loggingService.newLogGroup('e500 error', [error], true)
    );
  };
  logError = (error: AppError) => {
    this.loggingService.logGroups(
      this.loggingService.newLogGroup(`${error.code} error`, [error], true)
    );
  };
}
