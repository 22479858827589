import { Injectable, OnDestroy } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { ErrorService } from "src/app/error/error.service";
import { AppError } from "../error/appError";
import { AuthUser } from "./authITC";
import firebase from "firebase/compat/app";

/*
 * singleton service class that provides methods and data related to auth status and operations
 */
@Injectable({
  providedIn: "root",
})
export class AuthService implements OnDestroy {
  private _subscription: Subscription = new Subscription();
  /** minimum required password length */
  passMin: number = 6;
  /** maximum allowed password length */
  passMax: number = 128;
  /** BehaviorSubject used to emit values to subscribers of the {@link authUser$} Observable */
  private _authUser$: BehaviorSubject<AuthUser | null> =
    new BehaviorSubject<AuthUser | null>(null);
  /** Observable that emits either an {@link AuthUser} or null value */
  authUser$: Observable<AuthUser | null> = this._authUser$.asObservable();

  constructor(
    private afAuth: AngularFireAuth,
    private errorService: ErrorService
  ) {
    /** subscribe to the authState of the {@link AngularFireAuth} service.*/
    this._subscription.add(
      this.afAuth.authState.subscribe((authUser: AuthUser | null) => {
        //if no authUser or on signOut events navigate go login route
        // let url = this.router.url;
        // if (!authUser && !url.includes("/broadcast?")) {
        //   // console.log(url);
        //   // this.navigationService.navigate(["login"]);
        // }
        /** emit authUser to subscribers of {@link authUser$} */
        this._authUser$.next(authUser);
      })
    );
  }

  updateDisplayName() {}
  /**
   * async method - sends signUp request to firebase via {@link AngularFireAuth} service.
   * @param email string
   * @param password string
   * @return Promise<{@link AppError} | void>
   */
  async signUp(
    email: string,
    password: string,
    firstName: string
  ): Promise<AppError | void> {
    try {
      /** send signUp request via {@link AngularFireAuth} service.
       * On success emit new {@link AuthUser} to {@link authUser$} subscribers*/
      await this.afAuth
        .createUserWithEmailAndPassword(email, password)
        .then((userCred) => {
          userCred.user?.updateProfile({
            displayName: firstName,
          });
        });
      this._authUser$.next(await this.afAuth.currentUser);
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "AuthService.signUp",
        "auth/signUp"
      );
      return appError;
    }
  }

  /**
   * async method - sends login request to firebase via {@link AngularFireAuth} service.
   * @param email string
   * @param password string
   * @return Promise <{@link AppError} | void>
   */
  async login(email: string, password: string): Promise<void | AppError> {
    try {
      await this.afAuth.signInWithEmailAndPassword(email, password);
      this._authUser$.next(await this.afAuth.currentUser);
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "AuthService.login",
        "auth/login"
      );
      return appError;
    }
  }

  /**
   * async method - sends logout request to firebase via {@link AngularFireAuth} service.
   * @return Promise<{@link AppError} | void>
   * @throws {@link TypeError}
   */
  async logout(): Promise<AppError | void> {
    try {
      await this.afAuth.signOut();
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "UserService.logout",
        "auth/logout"
      );
      return appError;
    }
  }

  /**
   * async method - reauthenticates current user via {@link AngularFireAuth} service.
   * @param password string
   * @return Promise <void | {@link AppError}>
   */
  async reAuthenticate(password: string): Promise<void | AppError> {
    try {
      const authUser = await this.getCurrentAuth();
      if (authUser instanceof AppError) {
        const appError = authUser;
        appError.addTrace("AuthService.reAuthenticate", "auth/getCurrentAuth");
        return appError;
      }
      const credential = firebase.auth.EmailAuthProvider.credential(
        authUser.email as string,
        password
      );
      await authUser.reauthenticateWithCredential(credential);
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "AuthService.reAuthenticate",
        "auth/reAuthenticate"
      );
      return appError;
    }
  }

  /**
   * updates the email of the current {@link AuthUser}
   * @return Promise<void | {@link AppError}>
   */
  async updateEmail(email: string): Promise<void | AppError> {
    try {
      const authUser = await this.getCurrentAuth();
      if (authUser instanceof AppError) {
        const appError = authUser;
        appError.addTrace("AuthService.updateEmail", "auth/getCurrentAuth");
        return appError;
      }
      await authUser.updateEmail(email);
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "AuthService.updateEmail",
        "auth/updateEmail"
      );
      return appError;
    }
  }

  /**
   * updates the email of the current {@link AuthUser}
   * @return Promise<void | {@link AppError}>
   */
  async updatePassword(newPassword: string): Promise<void | AppError> {
    try {
      const authUser = await this.getCurrentAuth();
      if (authUser instanceof AppError) {
        const appError = authUser;
        appError.addTrace("AuthService.updatePassword", "auth/getCurrentAuth");
        return appError;
      }
      await authUser.updatePassword(newPassword);
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "AuthService.updatePassword",
        "auth/updatePassword"
      );
      return appError;
    }
  }

  /**
   * async method - returns the current value of {@link _authUser$}.
   * @return Promise<AuthUser | {@link AppError}>
   */
  async getCurrentAuth(): Promise<AuthUser | AppError> {
    try {
      const authUser = this._authUser$.getValue();
      if (!authUser) {
        return new AppError(
          "e404",
          "no current authenticated user",
          "AuthService.getCurrentAuth",
          "auth/getCurrentAuth"
        );
      }
      return authUser;
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "AuthService.getCurrentAuth",
        "auth/getCurrentAuth"
      );
      return appError;
    }
  }

  /**
   * async method - opens firebase Apple OAuthProvider popup, and awaits result.
   * @return Promise<[{@link AuthUser | null, {@link AppError | null}}]>
   */
  async signInWithApple(): Promise<[AuthUser | null, AppError | null]> {
    const provider = new firebase.auth.OAuthProvider("apple.com");
    try {
      const result = await this.afAuth.signInWithPopup(provider);
      return [result.user, null];
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "AuthService.login",
        "auth/login"
      );
      return [null, appError];
    }
  }

  /**
   * async method - sends a password reset email to to the supplied email.
   * @email string - email to send password reset email
   * @return Promise<void | appError>
   */
  async sendPasswordResetEmail(email: string): Promise<void | AppError> {
    try {
      await this.afAuth.sendPasswordResetEmail(email);
    } catch (err) {
      const appError: AppError = this.errorService.parseError(
        err,
        "AuthService.sendPasswordResetEmail",
        "auth/sendPasswordResetEmail"
      );
      return appError;
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
