import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  canActivate,
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  AngularFireAuthGuard,
} from "@angular/fire/compat/auth-guard";
import { SubscribedUsersGuard } from "./navigation/SubscribedUsersGuard.guard";
import { BetaUsersGuard } from "./navigation/BetaUsersGuard.guard";
import { ComingSoonComponent } from "./modules/views/coming-soon/coming-soon.component";
import { BroadcastGuard } from "./navigation/BroadcastGuard.guard";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);
const redirectLoggedIn = () => redirectLoggedInTo([""]);

const routes: Routes = [
  {
    path: "signup",
    ...canActivate(redirectLoggedIn),
    loadChildren: () =>
      import("./modules/views/signup/signup.module").then(
        (m) => m.SignUpModule
      ),
  },
  {
    path: "login",
    ...canActivate(redirectLoggedIn),
    loadChildren: () =>
      import("./modules/views/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "",
    pathMatch: "full",
    canActivate: [
      AngularFireAuthGuard,
      //BetaUsersGuard,
      SubscribedUsersGuard,
    ],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./modules/views/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "profile",
    canActivate: [
      AngularFireAuthGuard,
      //BetaUsersGuard
    ],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./modules/views/profile/profile.module").then(
        (m) => m.ProfileModule
      ),
  },
  {
    path: "script",
    canActivate: [
      AngularFireAuthGuard,
      //BetaUsersGuard,
      SubscribedUsersGuard,
    ],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./modules/views/script/script.module").then(
        (m) => m.ScriptModule
      ),
  },
  {
    path: "broadcast",

    canActivate: [BroadcastGuard],
    loadChildren: () =>
      import("./modules/views/broadcast/broadcast.module").then(
        (m) => m.BroadcastModule
      ),
  },
  {
    path: "broadcasts",
    canActivate: [
      AngularFireAuthGuard,

      //BetaUsersGuard
      SubscribedUsersGuard,
    ],

    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./modules/views/broadcasts/broadcasts.module").then(
        (m) => m.BroadcastsModule
      ),
  },
  {
    path: "upgrade",
    canActivate: [
      AngularFireAuthGuard,
      //BetaUsersGuard
    ],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import("./modules/views/payments/payments.module").then(
        (m) => m.PaymentsModule
      ),
  },
  {
    path: "coming-soon",
    component: ComingSoonComponent,
  },
  {
    path: "**",
    loadChildren: () =>
      import("./modules/views/f04/f04.module").then((m) => m.F04Module),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
