import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { AppError } from "src/app/error/appError";
import { AppErrorHandler } from "src/app/error/appErrorHandler";
import { ErrorService } from "src/app/error/error.service";
import { NavigationService } from "src/app/navigation/navigation.service";
import { ScriptService } from "src/app/scripts/script.service";

@Component({
  selector: "app-create-script-dialog",
  templateUrl: "./create-script-dialog.component.html",
  styleUrls: ["./create-script-dialog.component.scss"],
  providers: [AppErrorHandler],
})
export class CreateScriptDialogComponent implements OnInit {
  formSubmitted: boolean = false;
  formErrorText: string = "";

  constructor(
    private fb: UntypedFormBuilder,
    private appErrorHandler: AppErrorHandler,
    private scriptsService: ScriptService,
    private errorService: ErrorService,
    private navigationService: NavigationService,
    public dialogRef: MatDialogRef<CreateScriptDialogComponent>
  ) {}

  titleScriptForm = this.fb.group({
    scriptTitle: ["", [Validators.required, Validators.maxLength(128)]],
  });

  get scriptTitle() {
    return this.titleScriptForm.get("scriptTitle")?.value;
  }

  get title() {
    return this.titleScriptForm.get("scriptTitle");
  }

  ngOnInit(): void {}

  async onSubmit() {
    this.formErrorText = "";
    this.formSubmitted = true;
    try {
      const scriptTitle = this.scriptTitle.replace(/'/g, "''");

      const scriptId: string | AppError = await this.scriptsService.initScript(
        scriptTitle
      );
      if (scriptId instanceof AppError) {
        this.appErrorHandler[scriptId.code](scriptId);
        this.formSubmitted = false;
        return;
      }
      this.formSubmitted = false;
      this.navigationService.navigate(["script", "edit"], {
        queryParams: {
          scriptId: scriptId,
        },
      });
      this.dialogRef.close();
    } catch (err) {
      const appError = this.errorService.parseError(
        err,
        "CreateScriptDialogComponent.onSubmit",
        "crud/createScript"
      );
      this.appErrorHandler[appError.code](appError);
    }
  }
}
