<h1 mat-dialog-title align="center">Reset Password Request</h1>
<mat-dialog-content>
  <form [formGroup]="passwordResetForm" (ngSubmit)="onSubmit()">
    <label for="email" class="block text-sm font-medium text-gray-700"
      >Email</label
    >

    <div class="mt-1 flex rounded-md shadow-sm">
      <input
        type="email"
        formControlName="email"
        name="email"
        id="email"
        class="invalid:border-red-500 block w-full min-w-0 flex-grow rounded-md border-black focus:border-teleprompterBlue focus:ring-teleprompterBlue sm:text-sm"
      />
    </div>

    <div
      *ngIf="
        emailAddress?.invalid && (emailAddress?.dirty || emailAddress?.touched)
      "
      class="rounded-md bg-red-50 p-3 mt-2"
    >
      <div class="text-xs font-medium text-red-800 text-center">
        &uarr; Please enter a valid email address
      </div>
    </div>

    <p class="mt-3">
      A password reset email will be sent to the supplied address.
    </p>

    <button
      [disabled]="!passwordResetForm.valid"
      type="submit"
      class="mt-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue hover:bg-lightBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teleprompterBlue disabled:bg-gray-600 disabled:text-gray-400"
    >
      Reset Password
    </button>
  </form>
</mat-dialog-content>
