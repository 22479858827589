import { NgModule } from "@angular/core";
import { NavBarComponent } from "./nav-bar/nav-bar.component";
import { SharedModule } from "../../shared/shared.module";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [NavBarComponent],
  imports: [SharedModule, RouterModule],
  exports: [NavBarComponent],
})
export class NavModule {}
