import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth/auth.service";
import { AppError } from "../error/appError";
import { loadStripe, StripeError } from "@stripe/stripe-js";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { DOCUMENT } from "@angular/common";
import { UserService } from "../user/user.service";
import { IUser } from "../user/userITC";
import { IOffering } from "./offeringsITC";
import { ICurrentOfferings } from "./currentOfferings";
import { IPaddleSubscription, ISubscription } from "./subscriptionITC";

/**  Stripe payment methods */
export type PaymentMode = "payment" | "subscription";

/** CloudPrompter entitlements */
export type Entitlement = "premiumSubscription" | "cloudPrompter";

/** LegacyEntitlements  */
/**  prod_N9oAseUP21PHiU is cloudPrompter Standard Annual*/
/**  prod_N9oB3Lon3u0Vag is cloudPrompter Studio Monthly*/
/**  prod_N9oeEdafXs9wgV is cloudPrompter Studio Annual*/
export type LegacyEntitlement =
  | "com.joeallenpro.videoteleprompter.autocaptions"
  | "com.joeallenpro.videoteleprompter.upgrade.monthly_a"
  | "com.joeallenpro.videoteleprompter.upgrade.weekly_a"
  | "com.joeallenpro.videoteleprompter.upgrade.yearly_a"
  | "com.joeallen.teleprompter.mac.upgrade.weekly_a"
  | "com.joeallen.teleprompter.mac.upgrade.monthly_a"
  | "com.joeallen.Teleprompter.mac.upgrade.yearly_a"
  | "prod_N9oAseUP21PHiU"
  | "prod_N9oB3Lon3u0Vag"
  | "prod_N9oeEdafXs9wgV";

/** maps LegacyEntitlement to Entitlement */
export type LegacyEntitlementMap = Record<LegacyEntitlement, Entitlement>;

/** maps Entitlement to a boolean, true in user has entitlement */
export type Entitlements = Record<Entitlement, boolean>;

@Injectable({
  providedIn: "root",
})
export class SubscriptionService implements OnDestroy {
  private _subscription: Subscription = new Subscription();

  legacyEntitlementMap: LegacyEntitlementMap = {
    "com.joeallenpro.videoteleprompter.autocaptions": "premiumSubscription",
    "com.joeallen.teleprompter.mac.upgrade.weekly_a": "premiumSubscription",
    "com.joeallen.teleprompter.mac.upgrade.monthly_a": "premiumSubscription",
    "com.joeallen.Teleprompter.mac.upgrade.yearly_a": "premiumSubscription",
    "com.joeallenpro.videoteleprompter.upgrade.weekly_a": "premiumSubscription",
    "com.joeallenpro.videoteleprompter.upgrade.yearly_a": "premiumSubscription",
    "com.joeallenpro.videoteleprompter.upgrade.monthly_a":
      "premiumSubscription",
    prod_N9oAseUP21PHiU: "premiumSubscription",
    prod_N9oB3Lon3u0Vag: "cloudPrompter",
    prod_N9oeEdafXs9wgV: "cloudPrompter",
  };
  /** hash map of entitlements status */
  entitlements: Entitlements = {
    premiumSubscription: false,
    cloudPrompter: false,
  };
  offerings: IOffering[] = [];
  subsToManage: ISubscription[] = [];
  paddleSubs: IPaddleSubscription[] = [];
  /** stores value emitted from the {@link userProfile$ } Observable in the {@link UserService} */
  userProfile!: IUser | null;

  authUserId!: string;
  /** BehaviorSubject used to emit values to subscribers of the {@link authUser$} Observable */
  private _entitlements$: BehaviorSubject<Entitlements | null> =
    new BehaviorSubject<Entitlements | null>(null);
  /** Observable that emits either an {@link AuthUser} or null value */
  entitlements$: Observable<Entitlements | null> =
    this._entitlements$.asObservable();

  private _subsToManage$: BehaviorSubject<ISubscription[] | null> =
    new BehaviorSubject<ISubscription[] | null>(this.subsToManage);

  subsToManage$: Observable<ISubscription[] | null> =
    this._subsToManage$.asObservable();

  private _paddleSubs$: BehaviorSubject<IPaddleSubscription[] | null> =
    new BehaviorSubject<IPaddleSubscription[] | null>(this.paddleSubs);

  paddleSubs$: Observable<IPaddleSubscription[] | null> =
    this._paddleSubs$.asObservable();

  private _offerings$: BehaviorSubject<IOffering[]> = new BehaviorSubject<
    IOffering[]
  >(this.offerings);
  offerings$: Observable<IOffering[]> = this._offerings$.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    private afFun: AngularFireFunctions,
    private authService: AuthService,
    private userService: UserService
  ) {
    //subscribe to authUser and emit revenuecat subscription status
    this._subscription.add(
      this.authService.authUser$.subscribe((authUser) => {
        if (authUser) {
          this.authUserId = authUser.uid;
        }
      })
    );
    //subscribe to user and parse legacy subscriptions
    this._subscription.add(
      this.userService.userProfile$.subscribe(async (user) => {
        if (user) {
          this.userProfile = user;
          this.parseUserEntitlements(user);

          if (user.entitlements) {
            let now = Date.now();
            if (user.entitlements["premiumSubscription"]) {
              let expires = new Date(
                user.entitlements["premiumSubscription"]
              ).getTime();

              if (now < expires) {
                this.entitlements.premiumSubscription = true;
              }
            }

            if (user.entitlements["cloudPrompter"]) {
              let expires = new Date(
                user.entitlements["cloudPrompter"]
              ).getTime();

              if (now < expires) {
                this.entitlements.cloudPrompter = true;
              }
            }
            this._entitlements$.next(this.entitlements);

            //this.emitOfferings();
          }
        }

        this.emitPaddleSubscriptions();
        this.emitSubscriptionStatus();
        //this.emitOfferings();
      })
    );
    //add an IF statement here for any other entitlements we add (another tier)
  }

  /** parses entitlements on on the current users <@link IUser>, updates <@link entitlements> and emits to subscribers of <@link entitlements$> */
  parseUserEntitlements(user: IUser) {
    //check if subscriptions exist on user model
    if (
      user.subscription &&
      user.subscription.product &&
      user.subscription.expires
    ) {
      //check subscribed products against legacy entitlement map
      let { product, expires } = user.subscription;
      if (product in this.legacyEntitlementMap) {
        let entitlement =
          this.legacyEntitlementMap[product as LegacyEntitlement];
        //check if subscription is not out of date updates and emit entitlements
        let now = Date.now();
        if (now < expires) {
          this.entitlements[entitlement] = true;
          this._entitlements$.next(this.entitlements);
        }
      }
    }
  }

  clearSubData() {
    this.entitlements.premiumSubscription = false;
    this.entitlements.cloudPrompter = false;
    this._entitlements$.next(null);
    this.offerings = [];
    this._offerings$.next(this.offerings);
    this.paddleSubs = [];
    this._paddleSubs$.next(null);
    this._subsToManage$.next(null);
  }

  /** requests subscription status from revenuecat.  parses the response and updates <@link entitlements>, then emits <@link entitlements>
   * to subscribers of <@link entitlements$*/
  async emitSubscriptionStatus() {
    //get current auth user and use authId to check for revenuecat entitlement status
    const authUser = await this.authService.getCurrentAuth();
    if (!(authUser instanceof AppError)) {
      let apiPath = `https://api.revenuecat.com/v1/subscribers/${authUser.uid}`;
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append(
        "Authorization",
        `Bearer ${environment.revenueCatKey}`
      );
      this._subscription.add(
        this.http.get<any>(apiPath, { headers }).subscribe((response) => {
          if (response.subscriber) {
            let now = Date.now();
            this.subsToManage = response.subscriber.subscriptions;

            this._subsToManage$.next(this.subsToManage);
            //parse response, update entitlements and emit to subscribers
            if (response.subscriber.entitlements.premiumSubscription) {
              if (
                response.subscriber.entitlements.premiumSubscription
                  .expires_date
              ) {
                let expires = new Date(
                  response.subscriber.entitlements.premiumSubscription.expires_date
                ).getTime();

                if (now < expires) {
                  this.entitlements.premiumSubscription = true;
                }
              } else if (
                response.subscriber.entitlements.premiumSubscription
                  .product_identifier ===
                  "com.joeallenpro.premium.upgrade.lifetime_b" ||
                response.subscriber.entitlements.premiumSubscription
                  .product_identifier ===
                  "rc_promo_premiumSubscription_lifetime"
              ) {
                this.entitlements.premiumSubscription = true;
              }
            }
            if (response.subscriber.entitlements.cloudPrompter) {
              if (response.subscriber.entitlements.cloudPrompter.expires_date) {
                let expires = new Date(
                  response.subscriber.entitlements.cloudPrompter.expires_date
                ).getTime();

                if (now < expires) {
                  this.entitlements.cloudPrompter = true;
                }
              }
            }
            this._entitlements$.next(this.entitlements);

            //add an IF statement here for any other entitlements we add (another tier)
          }
        })
      );
    } else {
      this.entitlements.premiumSubscription = false;
      this.entitlements.cloudPrompter = false;
      this._entitlements$.next(null);
    }
  }

  async newUserInRevenueCat() {
    //get current auth user and use authId and update users name and email in revenuecat.
    const authUser = await this.authService.getCurrentAuth();
    if (!(authUser instanceof AppError)) {
      let apiPath = `https://api.revenuecat.com/v1/subscribers/${authUser.uid}/attributes`;
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append("Accept", "application/json");
      headers = headers.append(
        "Authorization",
        `Bearer ${environment.revenueCatKey}`
      );
      let body = {
        attributes: {
          $displayName: {
            value: this.userProfile?.firstName,
          },
          $email: {
            value: authUser.email,
          },
        },
      };
      this._subscription.add(
        this.http
          .post<any>(apiPath, body, { headers })
          .subscribe((response) => {
            if (response) {
              console.log(response);
            }
          })
      );
    }
  }

  async emitOfferings() {
    //get current auth user and use authId to check for revenuecat entitlement status
    const authUser = await this.authService.getCurrentAuth();
    if (!(authUser instanceof AppError)) {
      let apiPath = `https://api.revenuecat.com/v1/subscribers/${authUser.uid}/offerings`;
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append("X-Platform", "stripe");
      headers = headers.append(
        "Authorization",
        `Bearer ${environment.revenueCatKey}`
      );
      this._subscription.add(
        this.http
          .get<ICurrentOfferings>(apiPath, { headers })
          .subscribe((response) => {
            if (response.offerings) {
              response.offerings.forEach((offer) => {
                if (offer.packages.length > 0) {
                  this.offerings = response.offerings;
                  this._offerings$.next(this.offerings);

                  offer.packages.forEach((pkge) => {
                    this.retrieveStripePriceId(
                      pkge.platform_product_identifier
                    );
                  });
                }
              });
            }
          })
      );
    }
  }

  async retrieveStripePriceId(productId: string) {
    //get current auth user and use authId to check for revenuecat entitlement status
    const authUser = await this.authService.getCurrentAuth();
    if (!(authUser instanceof AppError)) {
      let apiPath = `https://api.stripe.com/v1/products/${productId}`;
      let headers = new HttpHeaders();
      headers = headers.append(
        "Authorization",
        `Bearer ${environment.stripeSecretKey}`
      );
      this._subscription.add(
        this.http.get(apiPath, { headers }).subscribe((response: any) => {
          this.offerings.forEach((offer) => {
            offer.packages.forEach((pkge) => {
              if (pkge.platform_product_identifier === productId) {
                pkge.priceId = response.default_price;
                this.retrieveStripePriceUnit(pkge.priceId!);

                if (pkge.identifier === "$rc_monthly") {
                  pkge.paddlePriceId = "pri_01hbx74q71pfztkqh4hfwfk6gn";
                } else if (pkge.identifier === "7_day_pass") {
                  pkge.paddlePriceId = "pri_01hbx75a06wede6rbpgqt4w11x";
                }
              }
            });
          });
        })
      );
    }
  }

  async retrieveStripePriceUnit(priceId: string) {
    //get current auth user and use authId to check for revenuecat entitlement status
    const authUser = await this.authService.getCurrentAuth();
    if (!(authUser instanceof AppError)) {
      let apiPath = `https://api.stripe.com/v1/prices/${priceId}`;
      let headers = new HttpHeaders();
      headers = headers.append(
        "Authorization",
        `Bearer ${environment.stripeSecretKey}`
      );
      this._subscription.add(
        this.http.get(apiPath, { headers }).subscribe((response: any) => {
          this.offerings.forEach((offer) => {
            offer.packages.forEach((pkge) => {
              if (pkge.priceId === priceId) {
                pkge.priceUnit = response.unit_amount / 100;
                pkge.productName = response.nickname;
                pkge.priceType = response.type;
              }
            });
          });
        })
      );
    }
  }
  /**
   * calls cloud function that creates a Stripe customer.  Returns the Stripe customerId
   * @return Promise<string>
   */
  getStripeCustomerId(
    stripeEmail: string,
    stripeName: string,
    referralId: string
  ): Promise<string> {
    return new Promise(async (resolve) => {
      this._subscription.add(
        this.afFun
          .httpsCallable("stripeCreateCustomer")({
            name: stripeName,
            email: stripeEmail,
            metadata: {
              referral: referralId,
            },
          })
          .subscribe((result) => {
            resolve(result.id);
          })
      );
    });
  }

  getPaddleAddressId(paddleUserId: string) {
    let apiPath = `https://api.paddle.com/customers/${paddleUserId}/addresses`;
    let headers = new HttpHeaders();
    headers = headers.append("Access-Control-Allow-Origin", "*");
    headers = headers.append(
      "Access-Control-Allow-Headers",
      "Origin, X-Requested-With, Content-Type, Accept"
    );
    headers = headers.append(
      "Authorization",
      `Bearer ${environment.paddleAPIKey}`
    );

    this._subscription.add(
      this.http.get(apiPath, { headers }).subscribe((response: any) => {
        return response;
      })
    );
  }

  emitPaddleSubscriptions(): Promise<any> {
    return new Promise((resolve) => {
      if (this.userProfile && this.userProfile.paddleCustomerID) {
        let paddleCustomerIds: string[] = [];

        Object.keys(this.userProfile.paddleCustomerID).forEach((key) => {
          if (
            this.userProfile &&
            this.userProfile.paddleCustomerID &&
            this.userProfile.paddleCustomerID[key] === true
          ) {
            paddleCustomerIds.push(key);
          }
        });

        paddleCustomerIds.forEach((key) => {
          this._subscription.add(
            this.afFun
              .httpsCallable("listPaddleSubscriptions")({
                customer_id: key,
                sandbox: false,
                //change when live mode.
              })
              .subscribe(
                (result) => {
                  result.data.forEach((data: IPaddleSubscription) => {
                    if (
                      data.custom_data &&
                      data.custom_data.userID === this.authUserId
                    ) {
                      this.paddleSubs.push(data);
                    }
                  });

                  this.paddleSubs = this.paddleSubs.sort(
                    (a, b) =>
                      new Date(b.created_at).getTime() -
                      new Date(a.created_at).getTime()
                  );

                  this.paddleSubs = this.paddleSubs?.filter(
                    (unique) => unique.custom_data.userID === this.authUserId
                  );

                  this.paddleSubs = this.paddleSubs.filter(
                    (thing, i, arr) =>
                      arr.findIndex((t) => t.id === thing.id) === i
                  );

                  this._paddleSubs$.next(this.paddleSubs);
                  resolve(result.data);
                },
                (err) => {
                  console.log(err);
                  resolve(err);
                }
              )
          );
        });
      }
    });
  }

  /**
   * calls cloud function that deletes the Stripe customer associated with the supplied Id.
   * @param customerId string - Stripe customer id of customer to be deleted
   */
  deleteStripeCustomer(customerId: string): Promise<any> {
    return new Promise((resolve) => {
      this._subscription.add(
        this.afFun
          .httpsCallable("stripeDeleteCustomer")({
            customerId: customerId,
          })
          .subscribe(
            (result) => {
              resolve(result);
            },
            (err) => {
              resolve(err);
            }
          )
      );
    });
  }

  /**
   * calls a cloud function that creates a stripe checkout session then redirects the client to this session.
   * @param customerId string - Stripe customerId of client creating session.
   * @param prideId string - Stripe priceId of product bing purchased in Checkout Session.
   * @param payment <@link PaymentMode>
   */
  openStripeCheckout(
    customerId: string,
    priceId: string,
    mode: PaymentMode,
    stripeEmail: string,
    baseURL: string,
    testModeEnable: Boolean
  ): Promise<void | { error: StripeError }> {
    return new Promise(async (resolve) => {
      const stripe = await loadStripe(environment.stripeKey);
      if (stripe) {
        this._subscription.add(
          this.afFun
            .httpsCallable("stripeCheckout")({
              customerId: customerId,
              priceId: priceId,
              mode: mode,
              email: stripeEmail,
              baseURL: baseURL,
              client_reference_id: this.authUserId,
              testMode: testModeEnable,
            })
            .subscribe(
              (result) => {
                stripe
                  .redirectToCheckout({
                    sessionId: result,
                  })
                  .then(function (result) {
                    resolve(result);
                    console.log(result.error.message);
                  });
              },
              (err) => {
                resolve(err);
              }
            )
        );
      }
    });
  }

  /**
   * calls a cloud function that creates a Stripe customer portal used to manage subscriptions.  Redirects page to portal on success
   * @param customerId string - Stipe customerId
   */
  openStripeCustomerPortal(
    customerId: string,
    returnUrl: string,
    testMode?: boolean
  ) {
    return new Promise(async (resolve) => {
      const stripe = await loadStripe(environment.stripeKey);
      if (stripe) {
        this._subscription.add(
          this.afFun
            .httpsCallable("getStripePortalUrl")({
              customerId: customerId,
              testMode: testMode,
              returnUrl: returnUrl,
            })
            .subscribe((result) => {
              this.document.location.href = result;
            })
        );
      }
    });
  }

  /**
   * updates revenuecat entitlements with successful Stripe session token and user authId.  Then emits new entitlement status.
   */
  async subscribeToPro(sessionToken: string) {
    const authUser = await this.authService.getCurrentAuth();
    if (!(authUser instanceof AppError)) {
      let apiPath = `https://api.revenuecat.com/v1/receipts`;
      let headers = new HttpHeaders();
      headers = headers.append("Content-Type", "application/json");
      headers = headers.append(
        "Authorization",
        `Bearer ${environment.revenueCatKey}`
      );
      headers = headers.append("X-Platform", "stripe");
      let reqBody = {
        app_user_id: authUser.uid,
        fetch_token: sessionToken,
      };
      this._subscription.add(
        this.http.post(apiPath, reqBody, { headers }).subscribe((response) => {
          this.emitSubscriptionStatus();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
}
