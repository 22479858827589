import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";

@Injectable({ providedIn: "root" })
export class BackgroundColorGuard implements CanActivate {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (this.document.querySelector("main")) {
      this.document.querySelector("main")!.style.backgroundColor = "white";
    }

    this.document.body.style.backgroundColor = "white";

    const metaThemeColor = this.document.querySelector(
      'meta[name="theme-color"]'
    );
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", "white");
    } else {
      const meta = this.document.createElement("meta");
      meta.name = "theme-color";
      meta.content = "white";
      this.document.head.appendChild(meta);
    }

    return true; // Allow navigation to proceed
  }
}
