import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import { SubscriptionService } from "../subscription/subscription.service";
import { filter, first, map } from "rxjs/operators";

@Injectable()
export class SubscribedUsersGuard implements CanActivate {
  constructor(
    private subService: SubscriptionService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkSub();
  }

  checkSub(): Observable<boolean> {
    return this.subService.entitlements$.pipe(
      filter((data) => data !== null),
      first(),
      map((response) => {
        if (response?.cloudPrompter || response?.premiumSubscription) {
          return true;
        } else {
          this.router.navigate(["/upgrade"]);
          return false;
        }
      })
    );
  }
}
