// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD8h2kIg49Qhu041cvB2EcranIiOPOr5AY",
    authDomain: "autoprompter.firebaseapp.com",
    databaseURL: "https://autoprompter.firebaseio.com",
    projectId: "autoprompter",
    storageBucket: "autoprompter.appspot.com",
    messagingSenderId: "363186534945",
    appId: "1:363186534945:web:cdd20a156752b391bc8160",
    measurementId: "G-E418VCW0Q1",
  },
  revenueCatKey: "GxOsYhKCgqHgbChYkoaVZLohZlqRpcWx",
  stripeKey: "pk_test_53d79nmZVSYaIHRkFM7w4dei",
  paddleAPIKey: "1d9891652ce0e3430b024c2f257a6a5de8af1c95178acc77a8",
  stripeSecretKey: "sk_test_UqHzdTsEYFUcRLjf6iwT6aoo",
  betaMode: false,
};

// Stripe Test key: pk_test_53d79nmZVSYaIHRkFM7w4dei
// Stripe Live key: pk_live_Rw3rCWduVwyq6lf8iWXWirtC

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
