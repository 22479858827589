import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { AppError } from "src/app/error/appError";
import { AppErrorHandler } from "src/app/error/appErrorHandler";
import { ErrorService } from "src/app/error/error.service";
import { ScriptService } from "src/app/scripts/script.service";
import { CreateScriptDialogComponent } from "../create-script-dialog/create-script-dialog.component";

@Component({
  selector: "app-create-folder-dialog",
  templateUrl: "./create-folder-dialog.component.html",
  styleUrls: ["./create-folder-dialog.component.scss"],
  providers: [AppErrorHandler],
})
export class CreateFolderDialogComponent implements OnInit {
  formSubmitted: boolean = false;
  formErrorText: string = "";
  constructor(
    private fb: UntypedFormBuilder,
    private appErrorHandler: AppErrorHandler,
    private scriptsService: ScriptService,
    private errorService: ErrorService,
    public dialogRef: MatDialogRef<CreateScriptDialogComponent>
  ) {}

  titleFolderForm = this.fb.group({
    folderTitle: ["", [Validators.required, Validators.maxLength(32)]],
  });

  get folderTitle() {
    return this.titleFolderForm.get("folderTitle")?.value;
  }

  get title() {
    return this.titleFolderForm.get("folderTitle");
  }

  get charCount() {
    return this.folderTitle?.length;
  }
  ngOnInit() {}

  async onSubmit() {
    this.formErrorText = "";
    this.formSubmitted = true;
    try {
      const isTitleValid = await this.scriptsService.isFolderTitleValid(
        this.folderTitle
      );
      if (!isTitleValid) {
        this.formErrorText = `A folder titled '${this.folderTitle}' already exists.`;
        this.formSubmitted = false;
        return;
      }

      const folderTitle = this.folderTitle.replace(/'/g, "''");
      const folderid: string | AppError = await this.scriptsService.initFolder(
        folderTitle
      );
      if (folderid instanceof AppError) {
        this.appErrorHandler[folderid.code](folderid);
        this.formSubmitted = false;
        return;
      }
      this.formSubmitted = false;
      this.dialogRef.close();
    } catch (err) {
      const appError = this.errorService.parseError(
        err,
        "CreateFolderDialogComponent.onSubmit",
        "crud/createFolder"
      );
      this.appErrorHandler[appError.code](appError);
    }
  }
}
