import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from "@techiediaries/ngx-qrcode";
import { ClipboardService } from "ngx-clipboard";
import { BroadcastService } from "src/app/broadcast/broadcast.service";

@Component({
  selector: "app-share-broadcast-dialog",
  templateUrl: "./share-broadcast-dialog.component.html",
  styleUrls: ["./share-broadcast-dialog.component.scss"],
})
export class ShareBroadcastDialogComponent implements OnInit {
  shareLink: string = "";
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  isPublic: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      broadcastId: string;
      broadcasterId: string;
      isPublic: boolean;
    },
    private clipboardApi: ClipboardService,
    private snackbar: MatSnackBar,
    private broadcastService: BroadcastService
  ) {
    this.shareLink = `${location.origin}/broadcast?broadcastId=${
      this.data.broadcastId
    }&broadcasterId=${btoa(this.data.broadcasterId)}`;
    this.isPublic = this.data.isPublic;
  }

  ngOnInit(): void {}

  onTogglePublic() {
    if (this.isPublic) {
      this.isPublic = false;
      this.broadcastService.setIsPublic(this.data.broadcastId, false);
    } else {
      this.isPublic = true;
      this.broadcastService.setIsPublic(this.data.broadcastId, true);
    }

    this.data.isPublic = this.isPublic;
  }

  onCopyShareLink() {
    this.clipboardApi.copyFromContent(this.shareLink);
    this.snackbar.open("Broadcast link copied.", "Ok", { duration: 2000 });
  }
}
