<form [formGroup]="addToFolderForm" (ngSubmit)="onAddScript()">
  <label for="script" class="block text-sm font-medium text-gray-900"
    >Select Folder</label
  >
  <select
    formControlName="folder"
    id="folder"
    name="folder"
    class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-teleprompterBlue focus:outline-none focus:ring-teleprompterBlue sm:text-sm"
  >
    <option *ngFor="let folder of data.folders" [ngValue]="folder">
      {{ folder.title }}
    </option>
  </select>

  <button
    [disabled]="!addToFolderForm.valid"
    mat-dialog-close="true"
    type="submit"
    class="mt-3 inline-flex items-center rounded border border-transparent bg-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-lightBlue focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2 disabled:opacity-20"
  >
    Add Script to Folder: {{ folder.title }}
  </button>
</form>
