<div class="w-full h-screen">
  <div
    class="w-full h-screen flex flex-col items-center justify-between bg-white bg-opacity-70 py-8"
  >
    <div class="flex-1 flex flex-col items-center justify-center">
      <img
        class="mb-8 h-10 w-auto"
        src="assets/LogoSmall.svg"
        alt="CloudPrompter"
      />

      <h1
        class="text-6xl lg:text-7xl xl:text-8xl text-black font-bold mt-12 text-center"
      >
        Coming Soon
      </h1>
      <div class="flex flex-col items-center space-y-4 mt-24">
        <p class="text-TPBlue-500 text-lg">You do not have beta access.</p>

        <p class="mt-3 text-TPBlue-500 text-xs">
          You will be redirected back to login...
        </p>
      </div>
    </div>
  </div>
</div>
