/**
 * const array of all {@link AppErrorCode} values
 */
const appErrorCodes = [
  'e400',
  'e401',
  'e403',
  'e404',
  'e409',
  'e499',
  'e500',
] as const;
/**
 * type alias for app error codes.  Codes are based on http codes and their meaning
 */
export type AppErrorCode = typeof appErrorCodes[number];
/**
 * interface representing an app error trace
 * @property location string
 * @property operation string
 */
export interface IErrorTrace {
  location: string;
  operation: string;
}
/**
 * AppError class, contains information and methods related to app errors
 */
export class AppError {
  constructor(
    public code: AppErrorCode,
    public message: string,
    public location: string,
    public operation: string,
    public trace: IErrorTrace[] = [],
    public detail?: any
  ) {
    if (trace.length == 0) this.trace = [{ location, operation }];
  }
  addTrace(location: string, operation: string) {
    this.trace.push({ location, operation });
  }
}

export type AppErrorHandlerMap = {
  [key in AppErrorCode]: (
    error: AppError,
    ...ags: any
  ) => any | { (...ags: any): any }[];
};
