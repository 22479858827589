import { Injectable } from "@angular/core";

// START Types && Interfaces ===
class LogGroup {
  constructor(
    public label: string,
    public data: any[],
    public stringify: boolean = false
  ) {}
}
// END Types && Interfaces

@Injectable({
  providedIn: "root",
})
export class LoggingService {
  /**
   * Takes a {@link logGroup} array and prints each group to the console
   * @param logGroups - {@link log/Groups} array of log groups to log
   */
  logGroups(...logGroups: LogGroup[]) {
    logGroups.forEach((group) => {
      console.groupCollapsed(group.label);
      group.data.forEach((arg) => {
        if (group.stringify) {
          console.log(JSON.stringify(arg, null, 2));
        } else {
          console.log({ arg });
        }
      });
      console.groupEnd();
    });
  }

  /**
   * Creates and returns {@link LogGroup}
   * @param label string - label of log group
   * @param data any[] - data to be logged
   */
  newLogGroup(
    label: string,
    data: any[],
    stringify: boolean = false
  ): LogGroup {
    return new LogGroup(label, data, stringify);
  }

  /**
   * logs data in a window alert
   * @param data any - data to be logged.
   * @param stringify? boolean defaults to false - if true json printy prints data.
   */
  logAlert(data: any, stringify: boolean = false) {
    if (stringify) alert(JSON.stringify(data, null, 2));
    else alert(data);
  }
}
