<div class="dialog-container">
  <h2 mat-dialog-title>Success</h2>
  <mat-dialog-content>
    {{ data.message }}
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button mat-dialog-close color="primary">
      {{ data.buttonText }}
    </button>
  </mat-dialog-actions>
</div>
