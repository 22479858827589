<h1 mat-dialog-title align="center">Rename {{ data.script.title }}</h1>
<mat-dialog-content>
  <form [formGroup]="renameScriptForm" (ngSubmit)="onSubmit()">
    <label for="scriptName" class="block text-sm font-medium text-gray-700"
      >Script Name</label
    >

    <div class="mt-1 flex rounded-md shadow-sm">
      <input
        type="text"
        maxLength="128"
        formControlName="scriptName"
        name="scriptName"
        id="scriptName"
        class="invalid:border-red-500 block w-full min-w-0 flex-grow rounded-md border-black focus:border-teleprompterBlue focus:ring-teleprompterBlue sm:text-sm"
      />
    </div>

    <div
      *ngIf="name?.invalid && (name?.dirty || name?.touched)"
      class="rounded-md bg-red-50 p-3 mt-2"
    >
      <div class="text-xs font-medium text-red-800 text-center">
        &uarr; Please enter a valid script name.
      </div>
    </div>
    <section
      class="form-error rounded-md bg-red-50 p-3 mt-2"
      *ngIf="formErrorText"
    >
      <mat-error class="text-xs font-medium text-red-800 text-center">{{
        formErrorText
      }}</mat-error>
    </section>
    <button
      [disabled]="!renameScriptForm.valid || formSubmitted"
      type="submit"
      class="mt-3 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-TPBlue hover:bg-TPBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teleprompterBlue disabled:bg-gray-600 disabled:text-gray-400"
    >
      Rename
    </button>
  </form>
</mat-dialog-content>
