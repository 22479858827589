import { Injectable } from "@angular/core";
import { AppError, AppErrorCode, IErrorTrace } from "./appError";
import { appErrorMap, firebaseErrorMap } from "./errorMaps";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  /**
   * public method - parses the error parameter to return an updated or new {@link AppError}
   * @param error any - caught error to be parsed
   * @param string - location of catch
   * @param string - operation where error war thrown or caught
   * @returns {@link AppError}
   */
  parseError(error: any, location: string, operation: string): AppError {
    if (error instanceof AppError) {
      error.trace.push({ location, operation });
      return error;
    } else {
      const { code, message } = appErrorMap[this.getAppErrorCode(error)];
      return new AppError(
        code,
        message,
        location,
        operation,
        [{ operation, location }],
        error
      );
    }
  }

  newErrorTrace(location: string, operation: string): IErrorTrace {
    return { location, operation };
  }

  /**
   * public method - parses error ang returns corresponding {@link AppErrorCode}
   * @param error any - error to be parsed
   * @returns {@link AppErrorCode} - defaults to 'e500'
   */
  private getAppErrorCode(error: any): AppErrorCode {
    switch (true) {
      case !error.code:
        console.log(error);
        return "e500";
      case error.code in appErrorMap:
        return error.code;
      case error.code in firebaseErrorMap:
        return firebaseErrorMap[error.code];
      default:
        return "e500";
    }
  }
}
