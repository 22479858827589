import { Component } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppError } from "src/app/error/appError";
import { AppErrorHandler } from "src/app/error/appErrorHandler";
import { NavigationService } from "src/app/navigation/navigation.service";
import { ScriptService } from "src/app/scripts/script.service";

const acceptedFileTypes = ["txt", "prompt", "markdown", "md"] as const;
type AcceptedFileTypes = (typeof acceptedFileTypes)[number];

@Component({
  selector: "app-import-script-dialog",
  templateUrl: "./import-script-dialog.component.html",
  styleUrls: ["./import-script-dialog.component.scss"],
  providers: [AppErrorHandler],
})
export class ImportScriptDialogComponent {
  fileLoaded: boolean = false;
  fileType: AcceptedFileTypes | null = null;
  fileContent: string = "";
  showFileErrorText: boolean = false;
  formSubmitted: boolean = false;
  formErrorText: string = "";
  titleScriptForm = this.fb.group({
    scriptTitle: ["", [Validators.required, Validators.maxLength(128)]],
  });

  get scriptTitle() {
    return this.titleScriptForm.get("scriptTitle")?.value;
  }

  get title() {
    return this.titleScriptForm.get("scriptTitle");
  }

  constructor(
    private snackbar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private appErrorHandler: AppErrorHandler,
    private scriptsService: ScriptService,
    private navigationService: NavigationService,
    public dialogRef: MatDialogRef<ImportScriptDialogComponent>
  ) {}

  public onFileSelected(event: Event): void {
    let target = event.target as HTMLInputElement;
    let file;
    if (target.files) {
      file = target.files[0];
    }
    if (file) {
      let [fileType, error] = this.fileTypeCheck(file.name);
      if (error) {
        this.showFileErrorText = true;
        this.snackbar.open(
          "Invalid file type, only .txt, .prompt, .md or .markdown files can be imported",
          "Ok",
          { duration: 3000 }
        );
        return;
      }
      this.showFileErrorText = false;
      let loadingFileSnackbar = this.snackbar.open("Loading file...");
      let fileReader: FileReader = new FileReader();
      fileReader.addEventListener("loadend", () => {
        this.fileContent = fileReader.result as string;
        this.fileType = fileType;
        loadingFileSnackbar.dismiss();
        this.snackbar.open("File loaded successfully.", "Ok", {
          duration: 3000,
        });
      });
      fileReader.readAsText(file);
    }
  }

  fileTypeCheck(fileName: string): [AcceptedFileTypes | null, AppError | null] {
    let split = fileName.split(".");
    let mt = split[split.length - 1];
    let type = acceptedFileTypes.find((type) => type === mt);
    if (type) {
      return [type, null];
    }
    let err = new AppError(
      "e400",
      "invalid mime type",
      "file type check",
      "fileTypeCheck"
    );

    return [null, err];
  }

  async onSubmit() {
    console.log(this.fileContent);
    let scriptId;
    if (this.fileType && this.fileContent) {
      switch (this.fileType) {
        case "txt":
        case "md":
        case "markdown":
          scriptId = await this.scriptsService.initScript(
            this.scriptTitle,
            this.fileContent
          );
          break;
        case "prompt":
          scriptId = await this.scriptsService.importPromptFile(
            this.scriptTitle,
            this.fileContent
          );
      }
      if (scriptId instanceof AppError) {
        this.appErrorHandler[scriptId.code](scriptId);
        this.formSubmitted = false;
        return;
      }
      this.formSubmitted = false;
      this.navigationService.navigate(["script", "edit"], {
        queryParams: {
          scriptId: scriptId,
        },
      });
      this.dialogRef.close();
    }
  }
}
