import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  Tiers,
  tiersDictionary,
} from "src/app/modules/views/payments/components/tier-cards/tiersITC";

@Component({
  selector: "app-go-pro-dialog",
  templateUrl: "./go-pro-dialog.component.html",
  styleUrls: ["./go-pro-dialog.component.scss"],
})
export class GoProDialogComponent implements OnInit {
  tiers!: Tiers;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tierName: string;
    }
  ) {}

  ngOnInit(): void {
    if (this.data.tierName === "Studio") {
      this.tiers = tiersDictionary[0];
    }
  }
}
