import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "./material/material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DisplayDataComponent } from "./display-data/display-data.component";
import { LoadingSpinnerComponent } from "./loading-spinner/loading-spinner.component";
import { ColorPickerModule } from "ngx-color-picker";
import { RemovePreCodeTagsPipe } from "./removePreCodeTags.pipe";
import { RemoveCodeTagsPipe } from "./removeCodeTags.pipe";

@NgModule({
  declarations: [
    DisplayDataComponent,
    LoadingSpinnerComponent,
    RemovePreCodeTagsPipe,
    RemoveCodeTagsPipe,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    ColorPickerModule,
    FormsModule,
  ],
  exports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    DisplayDataComponent,
    LoadingSpinnerComponent,
    ColorPickerModule,
    FormsModule,
    RemovePreCodeTagsPipe,
    RemoveCodeTagsPipe,
  ],
})
export class SharedModule {}
