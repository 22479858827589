<div class="flex-column flex-center max-w-sm">
  <h2 mat-dialog-title>Share Broadcast</h2>

  <div *ngIf="isPublic" class="mb-3 font-mono bg-TPBlue-400/10 border-2 border-TPBlue-400/20 uppercase text-sm flex gap-1 items-center py-1 px-2 rounded max-w-fit">
    <div class="">Public</div>
    <div class="text-TPBlue-400 animate-pulse">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6.115 5.19l.319 1.913A6 6 0 008.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 002.288-4.042 1.087 1.087 0 00-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 01-.98-.314l-.295-.295a1.125 1.125 0 010-1.591l.13-.132a1.125 1.125 0 011.3-.21l.603.302a.809.809 0 001.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 001.528-1.732l.146-.292M6.115 5.19A9 9 0 1017.18 4.64M6.115 5.19A8.965 8.965 0 0112 3c1.929 0 3.716.607 5.18 1.64" />
      </svg>              
    </div>
  </div>

  <div *ngIf="!isPublic" class="mb-3 font-mono text-zinc-500 bg-zinc-700/20 border-2 border-zinc-700/30 uppercase text-sm flex gap-1 items-center py-1 px-2 rounded max-w-fit opacity-75">
    <div class="">Private</div>
    <div class="">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
      </svg>                     
    </div>
  </div>

  <div class="bg-gray-100 rounded-lg px-4 w-full">
    <div class="relative flex items-center gap-3 py-3 text-sm">
      <div class="min-w-0 flex-1 text-sm">
        <label for="person-1" class="select-none text-gray-900">Public Broadcast</label>
        <span *ngIf="isPublic" class="block text-gray-400 text-xs">Public: Anybody with the URL can access this broadcast</span>
        <span *ngIf="!isPublic" class="block text-gray-400 text-xs">Private: Only accessible when signed in to this CloudPrompter account</span>
      </div>
      <div class="ml-3 flex h-6 items-center">

        <button (click)="onTogglePublic()" type="button"
          class="phone:mt-2 bg-teleprompterBlue relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teleprompterBlue focus:ring-offset-2"
          [ngClass]="{
              'bg-gray-200 hover:bg-gray-300': !isPublic,
              'bg-teleprompterBlue hover:bg-TPBlue-600': isPublic
            }" role="switch">
          <span class="sr-only">Use setting</span>
          <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
          <span aria-hidden="true"
            class="translate-x-5 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white ring-0 transition duration-200 ease-in-out"
            [ngClass]="{
                'translate-x-0': !isPublic,
                'translate-x-5': isPublic
              }"></span>
        </button>

      </div>
    </div>

  </div>

  <div class="my-3 bg-gray-100 border-2 border-gray-200 rounded-lg px-3 py-2 w-full flex items-center gap-2 font-mono break-all text-xs">
    <span>{{ shareLink }}</span>
    <button class="text-TPBlue hover:text-TPBlue-400" (click)="onCopyShareLink()">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.666 3.888A2.25 2.25 0 0013.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 01-.75.75H9a.75.75 0 01-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 01-2.25 2.25H6.75A2.25 2.25 0 014.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 011.927-.184" />
      </svg>      
    </button>
  </div>

  <ngx-qrcode class="w-48 h-48 mb-3" [elementType]="elementType" [errorCorrectionLevel]="correctionLevel" [value]="shareLink"></ngx-qrcode>

  <button mat-stroked-button mat-dialog-close>
    Done
  </button>
</div>